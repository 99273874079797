import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { KendoModule } from "src/app/modules/kendo.module";
import { FormsModule } from '@angular/forms';
import { SarAllocationComponent } from "../components/sar-allocation/sar-allocation.component";

@NgModule({
    declarations: [
        SarAllocationComponent,
    ],
    imports: [
        FormsModule,
        BrowserModule,
        RouterModule,
        HttpClientModule,
        KendoModule
    ],
    exports: [
        SarAllocationComponent,
    ],
    providers: [
    ],
})
export class SarAllocationModule { }