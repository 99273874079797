export class SupplierDto {
    id: number = 0;
    supplierName: string | undefined;
    supplierNumber: string | undefined;
    isActive: boolean | undefined;
    createdBy: string | undefined;
    createdDateTime: Date | undefined;
    modifiedBy: string | undefined;
    modifiedDateTime: Date | undefined;
    displayName?: string | undefined;
}