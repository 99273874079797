import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddNewItemConstant } from 'src/app/core/constants/add-new-item.constant';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { AddNewItem, AddNewItemDto } from '../models/add-new-item/add-new-item.model';
import { GetAddNewItem } from '../models/add-new-item/get-add-new-item-action.model';
import { AddNewItemAction } from '../models/add-new-action/add-new-action-item.model';

@Injectable({
  providedIn: 'root'
})
export class AddNewItemService {

  constructor(private httpUtilityService: HttpUtilityService) {
  }

  createAddNewActionItem(data: AddNewItem) {
    return this.httpUtilityService.post(AddNewItemConstant.createAddNewActionItem, data);
  }

  cloneActionItem(itemId: number) : Observable<AddNewItemAction> {
    return this.httpUtilityService.put<AddNewItemAction>(AddNewItemConstant.cloneActionItem + `/${itemId}`, null);
  }

  importAddNewItemAction(actionItems: AddNewItemDto[]) {
    return this.httpUtilityService.post(AddNewItemConstant.importAddNewItemAction, actionItems);
  }

  getAddNewItemAction(requestLineId: number): Observable<GetAddNewItem> {
    return this.httpUtilityService.get(AddNewItemConstant.getAddNewItemAction + '/' + requestLineId.toString());
  }

  updateAddNewActionItem(data: AddNewItem) {
    return this.httpUtilityService.put(AddNewItemConstant.updateAddNewActionItem, data);
  }

}
