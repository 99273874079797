import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { requestConst } from 'src/app/core/constants/request.const';
import { CommonService } from 'src/app/core/services/common.service';
import { RequestsIssueTypeCountDto } from '../../models/sar-request/requests-count/requests-issue-type-count.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { DashboardService } from '../../services/dashboard.service';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { RequestsAssignedToMeCountDto } from '../../models/sar-request/requests-count/requests-assigned-to-me-count.model';
import { RequestsCountDto } from '../../models/sar-request/requests-count/requests-created-by-me-count.model';
import { DashboardRequestDetailsDto } from '../../models/sar-request/dashboard-request-details.models';
import { requestCategoryEnum, requestIssueTypeEnum, actionTypeConst, requestStateEnum, sarSelectTypeEnum } from 'src/app/core/constants/sar-dashboard.const';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SelectDataDto } from 'src/app/core/models/select-data.models';
@Component({
  selector: 'app-sar-dashboard',
  templateUrl: './sar-dashboard.component.html',
  styleUrls: ['./sar-dashboard.component.css']
})
export class SarDashboardComponent implements OnInit {
  //states: any = { all: 0, rejected: 1, completed: 2, pendingMMD: 3, pendingStockingApproval: 4, draft: 5, cancelled: 6 };
  states: any = requestStateEnum;
  actionTypes: any = requestIssueTypeEnum;
  gridData: DashboardRequestDetailsDto[] = [];
  searchText: string = SharedConst.empty;
  _sarSelection: number = sarSelectTypeEnum.mySar;
  isMyBU: boolean = false;
  assignedToMyGroup: boolean = false;
  selectedStateId: number = this.states.pendingStockingApproval;
  selectedActionTypeId: number = this.actionTypes.all;
  isAssignedToMeStateSelected: boolean = false;
  requestsCount: RequestsCountDto = new RequestsCountDto();
  requestsAssignedToMeCount: RequestsAssignedToMeCountDto = new RequestsAssignedToMeCountDto();
  requestsIssueTypeCount: RequestsIssueTypeCountDto = new RequestsIssueTypeCountDto();
  dateFormat: string = SharedConst.dateFormat;
  sarSelectStoredItem: any;
  SelectedActionTypeIdStoredItem: any;
  SelectedStateIdStoredItem: any;
  IsAssignedToMeStateSelectedStoredItem: any;
  searchTextStoredItem: any;
  requestCategory!: number;
  userGroups: SelectDataDto[] | undefined;
  sarSelectType = sarSelectTypeEnum;
  public listItems: Array<string> = ["Small", "Medium", "Large"];
  public selectedValue = "Medium";
  @ViewChild(DataBindingDirective) directive: any;
  constructor(private router: Router, private route: ActivatedRoute, private dashboardService: DashboardService, private commonService: CommonService, private userService: SarUsersService) {
    this.sarSelectStoredItem = sessionStorage.getItem('sarSelect');
    this.SelectedActionTypeIdStoredItem = sessionStorage.getItem('selectedActionTypeId');
    this.SelectedStateIdStoredItem = sessionStorage.getItem('selectedStateId');
    this.IsAssignedToMeStateSelectedStoredItem = sessionStorage.getItem('isAssignedToMeStateSelected');
    this.searchTextStoredItem = sessionStorage.getItem('searchText');
    if (!this.commonService.isNullOrEmpty(this.sarSelectStoredItem)) {
      this._sarSelection = parseInt(this.sarSelectStoredItem);
      this.isAssignedToGroup();
      this.isMyBU = this._sarSelection === sarSelectTypeEnum.myBu ? true : false;
    }
    if (!this.commonService.isNullOrEmpty(this.SelectedActionTypeIdStoredItem)) {
      this.selectedActionTypeId = parseInt(this.SelectedActionTypeIdStoredItem);
    }
    if (!this.commonService.isNullOrEmpty(this.SelectedStateIdStoredItem)) {
      this.selectedStateId = parseInt(this.SelectedStateIdStoredItem);
    }
    if (!this.commonService.isNullOrEmpty(this.IsAssignedToMeStateSelectedStoredItem)) {
      this.isAssignedToMeStateSelected = this.IsAssignedToMeStateSelectedStoredItem === 'true' ? true : false;
    }
    if (!this.commonService.isNullOrEmpty(this.searchTextStoredItem)) {
      this.searchText = this.searchTextStoredItem;
    }
  }
  ngOnInit(): void {
    if (!this.commonService.isNullOrEmptyOrUndefined(this.commonService.getUserBUName())) {
      this.getUserGroups();
      this.requestCategory = this.getRequestCategory();
      this.getCounts();
      this.getRequestDetails();
    }
  }
  canCreateNewSAR() {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    return true;
  }
  getCounts() {
    if (this.assignedToMyGroup) {
      this.getCountOfRequestsAssignedToGroup();
    } else {
      this.getCountOfRequestsCreatedByMe();
    }
    if (!this.isMyBU) {
      this.getCountOfRequestsAssignedToMe();
    }
    this.getCountOfRequestsIssueTypes();
  }
  getUserGroups() {
    this.userService.GetAllUserDetailsByEmail(this.commonService.getUserEmailId()).subscribe(user => {
      let groups = user.groupDtos?.map<SelectDataDto>(group => {
          return { id: group.id, name: group.name }
        });
      this.userGroups = [{id: sarSelectTypeEnum.mySar, name: 'My SARs'}, {id: sarSelectTypeEnum.myBu, name: 'My BU'}, ...(groups as SelectDataDto[])];
    });
  }
  getCountOfRequestsCreatedByMe() {
    this.dashboardService.getCountOfRequestsCreatedByMe(this.isMyBU, this.searchText).subscribe((data: RequestsCountDto) => {
      this.requestsCount = data;
    });
  }
  getCountOfRequestsAssignedToGroup() {
    this.dashboardService.getCountOfRequestsAssignedToGroup(this._sarSelection, this.searchText).subscribe((data: RequestsCountDto) => {
      this.requestsCount = data;
    });
  }
  getCountOfRequestsAssignedToMe() {
    this.dashboardService.getCountOfRequestsAssignedToMe(this.searchText).subscribe((data: RequestsAssignedToMeCountDto) => {
      this.requestsAssignedToMeCount = data;
    })
  }
  getCountOfRequestsIssueTypes() {
    this.dashboardService.getCountOfRequestsIssueTypes(this.isMyBU, this.requestCategory, this.selectedStateId, this.searchText).subscribe((data: RequestsIssueTypeCountDto) => {
      this.requestsIssueTypeCount = data;
    })
  }
  getRequestDetails() {
    this.dashboardService.getRequestDetails(this.isMyBU, this.requestCategory, this.selectedStateId, this.selectedActionTypeId, this.searchText, this._sarSelection).subscribe((data: DashboardRequestDetailsDto[]) => {
      this.directive.skip = 0;
      this.gridData = data;
    })
  }

  getRequestCategory(): number {
    let requestCategory!: number;
    if (this.isMyBU) {
      requestCategory = requestCategoryEnum.all;
    } else if (this.assignedToMyGroup) {
      requestCategory = requestCategoryEnum.assignedToMyGroup;
    } else {
      requestCategory = this.isAssignedToMeStateSelected ? requestCategoryEnum.assignedToMe : requestCategoryEnum.createdByMe;
    }
    return requestCategory;
  }
  onSarSelectChange() {
      this.isMyBU = this._sarSelection == sarSelectTypeEnum.myBu ? true : false;
      sessionStorage.setItem('sarSelect', this._sarSelection.toString());
      this.isAssignedToGroup();
      this.storeSearchText();
      this.requestCategory = this.getRequestCategory();
      this.getCounts();
      this.getRequestDetails();
  }
  onStateChange(isAssignedToMeStateSelected: boolean, selectedStateId: number) {
    this.isAssignedToMeStateSelected = isAssignedToMeStateSelected;
    this.selectedStateId = selectedStateId;
    sessionStorage.setItem('isAssignedToMeStateSelected', this.isAssignedToMeStateSelected.toString());
    sessionStorage.setItem('selectedStateId', this.selectedStateId.toString());
    this.storeSearchText();
    this.requestCategory = this.getRequestCategory();
    this.getCounts();
    this.getRequestDetails();
  }
  onActionTypeChange(selectedActionTypeId: number) {
    this.selectedActionTypeId = selectedActionTypeId;
    sessionStorage.setItem('selectedActionTypeId', this.selectedActionTypeId.toString());
    this.storeSearchText();
    this.getCounts();
    this.getRequestDetails();
  }
  isAssignedToGroup() {
    this.assignedToMyGroup = this._sarSelection != sarSelectTypeEnum.myBu && this._sarSelection != sarSelectTypeEnum.mySar;
  }
  search() {
    this.storeSearchText();
    this.requestCategory = this.getRequestCategory();
    this.getCounts();
    this.getRequestDetails();
  }
  storeSearchText() {
    sessionStorage.setItem('searchText', this.searchText);
  }
  manage(isManage: boolean, isCreate: boolean, requestId: number) {
    if (!isManage) {
      const path = `${isManage}/${isCreate}/${requestId}`;
      this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
        state: { isManage: isManage, isCreate: isCreate, requestId: requestId }, relativeTo: this.route });
    }
    else {
      this.router.navigate(["./" + requestConst.manageSAR], {
        state: { isCreate: isCreate, requestId: requestId }, relativeTo: this.route
      });
    }
  }
  hasLineEditAccess(createdBy: string) {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      return true;
    }
    else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    else {
      if (this.commonService.getUserEmailId() === createdBy) {
        return true;
      }
      return false;
    }
  }

  navViewAction(rowIndex: number, actionLine: DashboardRequestDetailsDto) {
    let actionType: string = '';
    if (actionLine.action.toLowerCase().indexOf(actionTypeConst.addNewItem.toLowerCase()) !== -1) {
      actionType = actionTypeConst.addNewItem;
    }
    else if (actionLine.action.toLowerCase().indexOf(actionTypeConst.changeData.toLowerCase()) !== -1) {
      actionType = actionTypeConst.changeData;
    }
    else if (actionLine.action.toLowerCase().indexOf(actionTypeConst.extentionMRPUpdate.toLowerCase()) !== -1) {
      actionType = actionTypeConst.extentionMRPUpdate;
    }
    let actionNumber = this.getActionNumber(actionLine.action);
    if (actionLine.status === 'Draft') {
      if (this.hasLineEditAccess(actionLine.createdByEmail))
        this.router.navigate(['./' + requestConst.newAction], { state: { isNew: false, requestId: actionLine.requestId, requestNumber: actionLine.sarId, requestIssueId: actionLine.requestIssueId, actionNumber: actionNumber, changeType: actionType }, relativeTo: this.route });
      else
        this.commonService.showNotificationMessage(SharedConst.notAuthorizedActionMessage, 'error', 'center');
    }
    else {
      let changeType: string = actionType ? actionType.replace(/ /g, "_") : '';
      this.router.navigate([
        `./${requestConst.approvalDetail}/${actionLine.requestId}/${actionLine.sarId}/${actionLine.requestIssueId}/${actionNumber}/${changeType}`],
        { state: { requestId: actionLine.requestId, requestNumber: actionLine.sarId, requestIssueId: actionLine.requestIssueId, actionNumber: actionNumber, changeType: actionType }, relativeTo: this.route });
    }
  }
  getActionNumber(action: string) {
    const match = action.match(/\d+/);
    if (match) return parseInt(match[0], 10);
    else return 0;
  }

  @ViewChild(TooltipDirective)
  public tooltipDir!: TooltipDirective;

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === "TD" || element.nodeName === "TH") &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
