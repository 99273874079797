import { Component, OnInit } from '@angular/core';
import { Group } from 'src/app/features/setup/models/group/group.model';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { UserGroupsService } from 'src/app/features/setup/services/user-groups.service';
import { LineAssignmentDto } from '../../../models/common/line-assignment.model';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-approval-assignment',
  templateUrl: './approval-assignment.component.html',
  styleUrls: ['./approval-assignment.component.scss']
})
export class ApprovalAssignmentComponent implements OnInit {

  assignToGroups: Group[] = [];
  assignToUsers: UserDto[] = [];
  lineAssignment: LineAssignmentDto = new LineAssignmentDto();
  selectedUser = 0;
  enabledAssignToUser: boolean = false;
  public defaultAssignToGroup: { name: string; id: number } = {
    id: 0,
    name: "Select Group"
  };
  public defaultAssignToUser: { name: string; id: number } = {
    id: 0,
    name: "-"
  };
  isGoupSelected: boolean = true;
  constructor(private userGroupsService: UserGroupsService, private sarUsersService: SarUsersService,
    public dialog: DialogRef, public commonService: CommonService) { }

  ngOnInit(): void {
    if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment.comment)) {
      this.lineAssignment.comment = '';
    }
    this.getAssignmentGroupd();
  }

  getAssignmentGroupd() {
    this.userGroupsService.getAssignmentGroups(1).subscribe((data: any) => {
      this.assignToGroups = data;
    })
  }

  getAssignmentUsers() {
    if (this.lineAssignment.assignedGroupId) {
      this.sarUsersService.getAssignedUsers(this.lineAssignment.assignedGroupId).subscribe((data: any) => {
        this.assignToUsers = data;
      })
    }
  }

  close(actionName: string) {
    this.dialog.close({ actionType: actionName, lineItemDtail: this.lineAssignment });
  }

  selectAssignToGroup(group: Group) {
    this.lineAssignment.assignedGroupId = group.id;
    this.lineAssignment.assignedUserId = null;
    this.selectedUser = 0;
    this.getAssignmentUsers();
    this.enabledAssignToUser = group.id ? true : false;
  }

  selectAssignToUser(user: UserDto) {
    this.lineAssignment.assignedUserId = user.id;
  }
}
