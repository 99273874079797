import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SarApplicationGroupConstant } from 'src/app/core/constants/sar-application-group.constants';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { BusinessUnitDto } from '../../../models/bussinessunits/bussinessunits.model';
import { GroupType } from '../../../models/groupType/groupType.model';
import { SARApplicationGroup } from '../../../models/sarapplicationgroup/sarapplicationgroup.model';
import { SarApplicationGroupService } from '../../../services/sar-application-group.service';

@Component({
  selector: 'app-manage-sar-application-group',
  templateUrl: './manage-sar-application-group.component.html',
  styleUrls: ['./manage-sar-application-group.component.scss']
})
export class ManageSarApplicationGroupComponent implements OnInit {
  public id: number = 0;
  public businessunitId: number = 0;
  public groupTypeId: number = 0;
  public displayOrder: number = 0;
  public isCreate: boolean = false;
  public groupName: string = '';
  public isActive: boolean = true;
  public headerText: string = "Create New Group"
  public supplierNumber: string;
  public businessUnitsList: BusinessUnitDto[] = [];
  public groupTypesList: GroupType[] = [];
  public hasError: boolean = false;
  public hasgroupNameError: boolean = false;
  public hasBusinessUnitError: boolean = false;
  public hasGroupTypeError: boolean = false;
  public hasDisplayOrderError: boolean = false;
  sarApplicationGroupForm = new FormGroup({
    groupName: new FormControl('', Validators.required),
    businessUnit: new FormControl('', Validators.required),
    groupType: new FormControl('', Validators.required),
    displayOrder: new FormControl(0, [Validators.required, Validators.pattern("^[0-9]*$")]),
    isActive: new FormControl(true),
  });
  public selectedBusinessUnit: BusinessUnitDto = new BusinessUnitDto();
  public selectedGroupType: GroupType = new GroupType();
  public businessunitName: string;
  public groupTypeName: string

  constructor(public router: Router,
    private route: ActivatedRoute,
    private sarApplicationGroupService: SarApplicationGroupService,
    public commonService: CommonService
  ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.businessunitId = stateData['businessunitId'];
    this.isCreate = stateData['isCreate'];
    this.groupName = stateData['name'];
    this.isActive = stateData['isActive'];
    this.supplierNumber = stateData['supplierNumber'];
    this.groupTypeId = stateData['groupTypeId'];
    this.displayOrder = +stateData['displayOrder'];
    this.businessUnitsList = stateData['businessUnitList'];
    this.groupTypesList = stateData['groupTypesList'];
    this.businessunitName = stateData['businessunitName']
    this.groupTypeName = stateData['groupTypeName']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.groupName;
      this.sarApplicationGroupForm.setValue({
        groupName: this.groupName,
        businessUnit: this.businessunitName,
        groupType: this.groupTypeName,
        displayOrder: +this.displayOrder,
        isActive: this.isActive
      })
    }
  }

  cleareError() {
    this.hasgroupNameError = false;
    this.hasBusinessUnitError = false;
    this.hasGroupTypeError = false;
    this.hasDisplayOrderError = false;
    this.hasError = false;
  }

  selectBusinessUnit(val: BusinessUnitDto) {
    this.selectedBusinessUnit = val;
    this.businessunitId = this.selectedBusinessUnit.id;
    this.businessunitName = <string>this.selectedBusinessUnit.name;
  }

  selectGroupType(val: GroupType) {
    this.selectedGroupType = val;
    this.groupTypeId = this.selectedGroupType.id;
    this.groupTypeName = <string>this.selectedGroupType.name;
  }

  save() {
    this.cleareError();
    const group = new SARApplicationGroup();
    group.isActive = <boolean>this.sarApplicationGroupForm.get('isActive')?.value;
    group.id = this.id;
    group.name = this.sarApplicationGroupForm.get('groupName')?.value?.toString();
    group.description = '';
    group.businessunitId =  this.businessunitId;
    group.groupTypeId = this.groupTypeId;
    group.displayOrder = <number>this.sarApplicationGroupForm.get('displayOrder')?.value;
    group.businessunitName = this.businessunitName;
    group.groupTypeName = this.groupTypeName;
    group.createdBy = this.commonService.getUserEmailId();
    group.modifiedBy = this.commonService.getUserEmailId();;

    if (this.sarApplicationGroupForm.status == 'INVALID') {
      if (this.sarApplicationGroupForm.get('groupName')?.invalid) {
        this.sarApplicationGroupForm.get('groupName')?.markAsTouched();
        this.hasgroupNameError = true;
        this.hasError = true;
      }
      if (this.sarApplicationGroupForm.get('businessUnit')?.invalid) {
        this.sarApplicationGroupForm.get('businessUnit')?.markAsTouched();
        this.hasBusinessUnitError = true;
        this.hasError = true;
      }
      if (this.sarApplicationGroupForm.get('groupType')?.invalid) {
        this.sarApplicationGroupForm.get('groupType')?.markAsTouched();
        this.hasGroupTypeError = true;
        this.hasError = true;
      }
      if (this.sarApplicationGroupForm.get('displayOrder')?.invalid) {
        this.sarApplicationGroupForm.get('displayOrder')?.markAsTouched()
        this.hasDisplayOrderError = true;
        this.hasError = true;
      }

      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');

    }
    else {
      if (!this.isCreate) {
        this.sarApplicationGroupService.updateGroup(group).subscribe((response: SARApplicationGroup) => {
          this.router.navigate([SarApplicationGroupConstant.SARGroupRoute], { relativeTo: this.route.parent });
          this.commonService.showNotificationMessage('Group has been updated successfully', 'success', 'right');
        });
      }
      else {
        this.sarApplicationGroupService.saveGroup(group).subscribe((response: SARApplicationGroup) => {
          this.router.navigate([SarApplicationGroupConstant.SARGroupRoute], { relativeTo: this.route.parent });
          this.commonService.showNotificationMessage('Group has been added successfully', 'success', 'right')
        });
      }
    }
  }

  cancel() {
    this.router.navigate(['./SARApplicationGroup'], { relativeTo: this.route.parent });
  }
  
}

