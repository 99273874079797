import { Component, OnDestroy, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CellClickEvent, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';
import { forkJoin } from 'rxjs';
import { ChangeDataConst, ChangeDataEnum } from 'src/app/core/constants/change-data.const';
import { CommonService } from 'src/app/core/services/common.service';
import { MaterialInfoService } from 'src/app/features/setup/services/material-info.service';
import { BranchPlantDetails, ChangeDataGenerateExcelItem } from '../../../models/change-sar/change-data-generate-excel-item.model';
import { BranchPlant, BranchPlantDto, ChangeDataMatNumber, ChangeTypeDetailsDto, MatChangeTypeDetailsDto, MatNumberChangeDetails, MatNumChangeTypeDto } from '../../../models/change-sar/change-data-new-item-detail.model';
import { ChangeTypeDto } from '../../../models/change-sar/change-type.model';
import { ChangeDataService } from '../../../services/change-data.service';
import { saveAs } from 'file-saver';
import * as Excel from "exceljs";
import * as JSZip from 'jszip';
import { ChangeDataExcelColumns } from 'src/app/core/constants/excel-columns-const';
import { TempActionService } from '../../../services/temp-action.service';
import { BlobURlsDetails } from 'src/app/core/constants/commonConst/blobUrl.cons';
@Component({
  selector: 'app-change-data-excel-template',
  templateUrl: './change-data-excel-template.component.html',
  styleUrls: ['./change-data-excel-template.component.scss']
})
export class ChangeDataExcelTemplateComponent implements OnInit, OnDestroy {
  requestNumber: string = '';
  isNew: boolean = true;
  navData: any;
  materialList: [] = [];
  enteredmaterialNumbers: string = '';
  validMaterialNumbers: string[] = [];
  materialNumberlist: Array<ChangeDataMatNumber> = [];
  selectedMaterialNumbersList: Array<ChangeDataMatNumber> = [];
  buName: string = '';
  excelFileName: string = 'ChangeSARExcelTemplate';
  excelFilePath: string = 'ChangeSARExcelTemplate';
  public isOpen: boolean = false;
  matNumberchangeTypes: ChangeTypeDto[] = [];
  selectedValues: ChangeTypeDto[] = [];
  changedataInfo: ChangeTypeDetailsDto[] = [];
  removingItem: any;
  removingPlant: any;
  public mySelection: number[] = [1];
  public readonly changeDataEnum: typeof ChangeDataEnum = ChangeDataEnum;
  @ViewChild("multiselectChangeType", { static: true }) public multiselectChangeType: any;
  @ViewChild("multiselectPlantList", { static: true }) public multiselectPlantList: any;
  @ViewChild('myGrid', { static: true }) myGrid!: GridComponent;
  @ViewChild('otherTextArea') kendoTextArea!: ElementRef<TextAreaComponent>;
  @ViewChild('headerCheckbox') headerCheckbox!: ElementRef<boolean>
  isShowTypeOfChangeDataList: boolean = false;
  plantList: BranchPlantDetails[] = [];
  plantDefaultvalue: BranchPlantDetails = { branchPlantCode: '-- Please Select a Branch Plant --', branchPlantCurrentValue: 'default' };
  selectedPlants: BranchPlantDetails[] = [];
  existingData: { materialNumber: string | undefined, changeType: string | undefined, status: string | undefined, plantOtherChangeType: string }[] = [];
  currentChangeDataInfo: ChangeTypeDetailsDto[] = [];
  selectedMaterialNumber: string = '';
  isShowPlantsDropdown: boolean = false;
  isShowOther: boolean = false;
  otherValue: string = '';
  oldOtherValue: string = '';
  excelFileData: ChangeDataGenerateExcelItem[] = [];
  selectedChangeTypeId?: number;
  changeDataExcelColumns: any = ChangeDataExcelColumns;
  ChangeDataConst: any = ChangeDataConst;
  rowSelectedIndex?: number;
  selectedMaterialNumbers: String[] = [];
  ischecked: boolean = false;
  selectedItems: any = [];
  note: string[] = [];
  toasterMsg?: string | undefined = '';
  matNumChangeTypeDetails: MatNumberChangeDetails[] = [];
  isNoteVisible: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private materialInfoService: MaterialInfoService, private renderer: Renderer2, private el: ElementRef,
    private changeDataService: ChangeDataService, private tempActionService: TempActionService) {
    this.buName = this.commonService.getUserBUName();
    this.navData = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(this.navData)) {
      this.existingData = this.navData['existingData'];
      this.requestNumber = this.navData['requestNumber'];
      this.isNew = this.navData['isNew'];
      sessionStorage.setItem('sarnewaction', JSON.stringify(this.navData));
    }
    else {
      const sessionStorageData = JSON.parse(sessionStorage.getItem('sarnewaction')!);
      if (!this.commonService.isNullOrEmptyOrUndefined(sessionStorageData)) {
        this.existingData = sessionStorageData.existingData;
        this.requestNumber = sessionStorageData.requestNumber;
        this.isNew = sessionStorageData.isNew
        this.navData = sessionStorageData;
      }
    }
  }

  ngOnInit(): void {
    this.excelFilePath = this.excelFilePath + this.commonService.getUserBUName();
    this.getMaterialList();
    this.getMatNumberchangeTypes();
  }

  getMaterialList() {
    this.materialInfoService.getAllMaterialNumbers<[]>().subscribe((outputDtata: []) => {
      this.materialList = outputDtata;
    });
  }
  navBack() {
    this.router.navigate(['./newaction'], { state: { isNew: this.isNew, requestId: this.navData.requestId, requestNumber: this.navData.requestNumber, requestIssueId: this.navData.requestIssueId, actionNumber: this.navData.actionNumber, changeType: this.navData.changeType }, relativeTo: this.route.parent });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('sarnewaction');
  }
  onValidate() {
    this.note = [];
    let originalData: string[] = this.enteredmaterialNumbers.split(/[\n\t\s,]+/).filter(num => num !== '');
    let data: string[] = this.removeduplicates(originalData);
    let correctMaterialNumbers: string[] = [];
    let wrongMaterialNumbers: string[] = [];
    let cummonMaterialNumbersLength: number = 0;
    let notificationMessage: string = '';
    let messageType: 'success' | 'warning' | 'error' = 'success';
    for (let num of data) {
      if (this.materialList.findIndex(e => e === num) !== -1) {
        if (this.validMaterialNumbers.findIndex(e => e === num) !== -1)
          cummonMaterialNumbersLength++;

        else
          correctMaterialNumbers.push(num);
      }
      else
        wrongMaterialNumbers.push(num);
    }
    this.validMaterialNumbers = this.validMaterialNumbers.concat(correctMaterialNumbers);
    this.setMaterialList();

    if (correctMaterialNumbers.length > 0) {
      let itemFoundLength = correctMaterialNumbers.length;
      if (cummonMaterialNumbersLength > 0)
        itemFoundLength += cummonMaterialNumbersLength;
      notificationMessage = itemFoundLength + ' items found';
      if (wrongMaterialNumbers.length > 0) {
        let wrongEntries = wrongMaterialNumbers.join(',');
        notificationMessage += `, but ${wrongEntries}
          ${(wrongMaterialNumbers.length > 1) ? " entries could not be found and have been ignored" : " entry could not be found and has been ignored"}`;
        messageType = 'warning';
      }
    }
    else {
      if (cummonMaterialNumbersLength > 0)
        notificationMessage = 'Material number\'s has been validated already';
      else {
        notificationMessage = 'No item numbers were found out of ' + data.length + ' entries';
      }
      messageType = 'error';
    }
    this.commonService.showNotificationMessage(notificationMessage, messageType, 'center');
    this.enteredmaterialNumbers = '';
  }

  setMaterialList() {
    this.materialNumberlist = [];
    for (var i = 0; i < this.validMaterialNumbers.length; i++) {
      let matNumber = new ChangeDataMatNumber();
      matNumber.id = i;
      matNumber.materialNumber = this.validMaterialNumbers[i];
      this.materialNumberlist.push(matNumber);
    }
  }

  removeduplicates(table: any[]) {
    const uniqueArray = table.reduce((acc, current) => {
      if (!acc.includes(current))
        acc.push(current);
      return acc
    }, []);
    return uniqueArray;
  }
  clearValidMatNumbers() {
    this.note = [];
    this.toasterMsg = '';
    this.validMaterialNumbers = [];
    this.excelFileData = [];
    this.clearAndHideFieldsData();
    this.selectedItems = [];
    this.materialNumberlist = [];
    this.selectedMaterialNumbersList = [];
  }

  selectAll() {
    this.clearAndHideFieldsData();
    this.selectedMaterialNumbersList = [];
    this.headerCheckbox = this.el.nativeElement.querySelector("#headerCheckbox").checked;
    if (this.headerCheckbox) {
      this.materialNumberlist.forEach((item: any) => {
        this.selectedMaterialNumbersList.push(item);
      })
      if (this.selectedMaterialNumbersList.length > 0) {
        this.isShowTypeOfChangeDataList = true;
        if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Lead Time').length > 0) {
          this.getBranchPlants();
        }
      }
    }
  }


  onCheckMaterialNumber(item: ChangeDataMatNumber) {
    let recordExist = this.selectedMaterialNumbersList.filter((y: ChangeDataMatNumber) => y.materialNumber === item.materialNumber)[0];
    if (recordExist) {
      this.selectedMaterialNumbersList = this.selectedMaterialNumbersList.filter((y: ChangeDataMatNumber) => y.materialNumber !== item.materialNumber);
    }
    else {
      this.selectedMaterialNumbersList.push(item);
    }
    this.clearAndHideFieldsData();
    if (this.selectedMaterialNumbersList.length > 0) {
      this.isShowTypeOfChangeDataList = true;
      if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Lead Time').length > 0) {
        this.getBranchPlants();
      }
    }
  }
  onMaterialNumberClick({ rowIndex }: CellClickEvent) {
    this.clearAndHideFieldsData();
    this.selectedMaterialNumbersList = [];
    this.selectedMaterialNumbersList.push(this.materialNumberlist[rowIndex]);
    this.isShowTypeOfChangeDataList = true;
  }
  removeRow(dataItem: any) {
    this.note = [];
    this.toasterMsg = '';
    let recordIndex = this.excelFileData.findIndex((item: ChangeDataGenerateExcelItem) => (item.materialNumber == dataItem.materialNumber && item.changeType == dataItem.changeType));
    if (recordIndex > -1) {
      this.excelFileData.splice(recordIndex, 1);
    }
  }

  clearAndHideFieldsData() {
    this.changedataInfo = [];
    this.isShowTypeOfChangeDataList = false;
    this.selectedValues = [];
    this.removingItem = null;
    this.isShowOther = false;
    this.otherValue = '';
    this.oldOtherValue = '';
    this.isShowPlantsDropdown = false;
    this.selectedPlants = [];
    this.plantList = [];
    this.removingPlant = null;
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.index == this.rowSelectedIndex) {
      return { background: true };
    } else {
      return { background: false };
    }
  }

  getMatNumberchangeTypes() {
    let getChangeTypesAPI = this.changeDataService.getChangeTypes();
    forkJoin([getChangeTypesAPI]).subscribe(([getChangeTypesData]) => {
      this.selectedValues = [];
      this.matNumberchangeTypes = getChangeTypesData;
    });
  }
  setPlant(plantCode: string): BranchPlantDetails {
    let plant: BranchPlantDetails = new BranchPlantDetails();
    plant.branchPlantCode = plantCode;
    plant.branchPlantCurrentValue = '';
    plant.status = true;
    return plant;
  }
  removeChangeType(event: any) {
    if (event.dataItem.id === this.changeDataEnum.leadTime) {
      this.isShowPlantsDropdown = false;
      this.selectedPlants = [];
    }
    if (event.dataItem.id === this.changeDataEnum.other) {
      this.isShowOther = false;
      this.oldOtherValue = '';
      this.otherValue = '';
    }
    this.removingItem = event.dataItem;
  }
  selectChangeType(event: any) {
    if (event[event.length - 1].id === this.changeDataEnum.leadTime) {
      this.isShowPlantsDropdown = true;
    }
    if (event[event.length - 1].id === this.changeDataEnum.other)
      this.isShowOther = true;

    if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Lead Time').length > 0) {
      this.getBranchPlants();
    }

  }

  selectPlantChange(event: any) {
    this.selectedPlants = event;
    console.log(event);
  }

  setChangeDataGenerateExcelItem(type: MatChangeTypeDetailsDto, materialNum?: string, plantOtherChangeType?: string) {
    let changeDataGenerateExcelItem: ChangeDataGenerateExcelItem = new ChangeDataGenerateExcelItem();
    changeDataGenerateExcelItem.materialNumber = materialNum;
    changeDataGenerateExcelItem.changeType = type.changeType;
    changeDataGenerateExcelItem.plantOtherChangeType = plantOtherChangeType ? plantOtherChangeType : '';
    changeDataGenerateExcelItem.oldData = type.oldData ? type.oldData : '';
    return changeDataGenerateExcelItem
  }

  changeChangeTypesStatus(selectedValues: any) {
    this.matNumberchangeTypes.forEach((element: any) => {
      if (selectedValues.findIndex((x: any) => x.id === element.id) !== -1) {
        element.status = true;
      }
      else {
        element.status = false;
      }
    });
  }

  removePlant(event: any) {
    this.removingPlant = event.dataItem;
  }
  plantChangeStatus(selectedValues: any) {
    this.plantList.forEach((element: any) => {
      if (selectedValues.findIndex((x: any) => x.branchPlantCode === element.branchPlantCode) !== -1) {
        element.status = true;
      }
      else {
        element.status = false;
      }
    });
  }
  onOpenChangeType(event: any) {
    let multiselectChangeType;
    multiselectChangeType = this.el.nativeElement.querySelector("#multiselectChangeType");
    this.renderer.addClass(multiselectChangeType, 'opened');
  }
  onCloseChangeType(event: any) {
    let multiselectChangeType;
    multiselectChangeType = this.el.nativeElement.querySelector("#multiselectChangeType");
    this.isOpen = false;
    this.renderer.removeClass(multiselectChangeType, 'opened');
  }
  toggleChangeType() {
    this.isOpen = !this.isOpen;
    this.multiselectChangeType.toggle(this.isOpen);
    let multiselectChangeType;
    multiselectChangeType = this.el.nativeElement.querySelector("#multiselectChangeType");
    if (this.isOpen) {
      this.renderer.addClass(multiselectChangeType, 'opened')
    }
    else {
      this.renderer.removeClass(multiselectChangeType, 'opened');
    }
  }
  onOpenPlant(event: any) {
    let multiselectPlantList;
    multiselectPlantList = this.el.nativeElement.querySelector("#multiselectPlantList");
    this.renderer.addClass(multiselectPlantList, 'opened');
  }
  onClosePlant(event: any) {
    let multiselectPlantList;
    multiselectPlantList = this.el.nativeElement.querySelector("#multiselectPlantList");
    this.isOpen = false;
    this.renderer.removeClass(multiselectPlantList, 'opened');
  }
  togglePlant() {
    this.isOpen = !this.isOpen;
    this.multiselectPlantList.toggle(this.isOpen);
    let multiselectPlantList;
    multiselectPlantList = this.el.nativeElement.querySelector("#multiselectPlantList");
    if (this.isOpen) {
      this.renderer.addClass(multiselectPlantList, 'opened')
    }
    else {
      this.renderer.removeClass(multiselectPlantList, 'opened');
    }
  }
  public downloadExcelTemplate(): void {
    if (this.validMaterialNumbers.length === 0) {
      this.commonService.showNotificationMessage('Please add the validated material numbers', 'error', 'center');
    }
    else if (this.excelFileData.length === 0) {
      this.commonService.showNotificationMessage('Please add change types for the validated material numbers by selecting them', 'error', 'center');
    }
    else {
      this.downloadRawAndTemplateFiles();
    }
  }
  downloadRawAndTemplateFiles(): void {
    const zip = new JSZip();
    zip.file("ChangeSAR_Raw_Data.xlsx", this.commonService.exportAsExcelFile(this.exportToExcel(), this.changeDataExcelColumns,
      'ChangeSAR_Raw_Data', true));
    this.tempActionService.fetchBlobUrl(this.excelFilePath).subscribe((responseUrl: string) => {

      this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
        const blobdownload = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
        let date = this.commonService.formatDateTo_ddmmyyyy(new Date());
        const fileName = this.requestNumber + '-' + this.excelFileName + '-' + date + '.xlsx';
        zip.file(fileName, blobdownload);
        this.tempActionService.fetchBlobUrl(BlobURlsDetails.changeSARExcelTemplateUserGuide).subscribe((responseUrl: string) => {
          this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
            const blobdownload = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
            zip.file(BlobURlsDetails.changeSARExcelTemplateUserGuide + '.docx', blobdownload);
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, `${this.requestNumber}-ChangeSar-${date}.zip`);
              this.navBack();
            });
          })
        })
      })
    });
  }

  getMatNumChangeTypeDetails() {
    let matNumChangeTypeDto = new MatNumChangeTypeDto();
    let matnumbers: Array<string> = [];
    let changeTypes: Array<string> = [];
    this.selectedMaterialNumbersList.forEach((x: ChangeDataMatNumber) => {
      matnumbers.push(x.materialNumber!);
    })
    this.selectedValues.forEach((x: ChangeTypeDto) => {
      changeTypes.push(x.label!);
    });
    matNumChangeTypeDto.MaterialNumbers = matnumbers;
    matNumChangeTypeDto.ChangeTypes = changeTypes;
    matNumChangeTypeDto.BuName = this.buName;
    if (this.isPlantorOtherIncluded()) {
      this.changeDataService.getMatNumChangeTypeDetails(matNumChangeTypeDto).subscribe((outputData: MatNumberChangeDetails[]) => {
        this.matNumChangeTypeDetails = outputData;
        this.validateChangeTypes();
      });
    }
  }

  isPlantorOtherIncluded() {
    let neededNotification: number = 0;
    let message: string = '';
    if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Lead Time').length > 0 && this.selectedPlants.length === 0) {
      neededNotification++;
      message = "Please select plants before proceeding";
    }
    if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Other').length > 0 && this.otherValue === '') {
      neededNotification++;
      message = "Please provide other value before proceeding";
    }
    if (neededNotification > 0) {
      message = neededNotification === 2 ? 'Please select plants and provide other value before proceeding' : message;
      this.commonService.showNotificationMessage(message, 'error', 'center');
    }
    return neededNotification > 0 ? false : true;
  }
  getBranchPlants() {
    let branchDto = new BranchPlantDto();
    let matnumbers: Array<string> = [];
    this.selectedMaterialNumbersList.forEach((x: ChangeDataMatNumber) => {
      matnumbers.push(x.materialNumber!);
    })
    branchDto.MaterialNumbers = matnumbers;
    branchDto.BuName = this.buName;
    this.changeDataService.getBranchPlantDetail(branchDto).subscribe((outputData: any) => {
      this.plantList = outputData;
    });
  }

  showAndHideNote() {
    this.isNoteVisible = !this.isNoteVisible;
  }

  onAddClick() {
    this.note = [];
    this.isNoteVisible = false;
    this.getMatNumChangeTypeDetails();
  }

  validateChangeTypes() {

    this.toasterMsg = '';
    this.selectedMaterialNumbersList.forEach((item: ChangeDataMatNumber) => {
      let existingChangeTypes: string[] = [];
      this.matNumChangeTypeDetails.filter((data: MatNumberChangeDetails) => data.materialNumber === item.materialNumber)[0].changeTypeValues?.forEach((type: MatChangeTypeDetailsDto) => {
        if (type.changeType === 'Lead Time') {
          let _unavailablePlants: string[] = [];
          let _existingPlants: string[] = this.existingData.filter((z: any) => z.materialNumber == item.materialNumber && z.changeType == type.changeType && z.status !== 'Cancelled')
            .map((x) => { return x.plantOtherChangeType });
          let _addedPlants: string[] = this.excelFileData.filter((y: any) => y.changeType == type.changeType && y.materialNumber === item.materialNumber)
            .map((plant: ChangeDataGenerateExcelItem) => { return plant.plantOtherChangeType; });
          let toasterPlants: string[] = [];
          let notePlants: string[] = [];
          this.selectedPlants?.forEach((plantDet: BranchPlantDetails) => {
            if (_existingPlants.filter(x => x === plantDet.branchPlantCode).length > 0) {
              notePlants.push(plantDet.branchPlantCode!);
            }
            else if (_addedPlants.filter(x => x === plantDet.branchPlantCode).length > 0) {
              toasterPlants.push(plantDet.branchPlantCode!);
            }
            else if (type.branchPlants!.filter((det: BranchPlant) => det.branchPlantCode == plantDet.branchPlantCode).length === 0) {
              _unavailablePlants.push(plantDet.branchPlantCode!);
            }
            else {
              this.addItemToTable(type, item.materialNumber, plantDet.branchPlantCode);
            }
          });
          if (notePlants.length) {
            let _lastPlant: string = notePlants.splice(notePlants.length - 1, 1)[0];
            this.note.push("**" + item.materialNumber + ": Data change type " + type.changeType + " with " + (notePlants.length === 0 ? `plant ${_lastPlant}` : `plants ${notePlants.join(', ')} and ${_lastPlant}`) + " already exists.");
          }
          if (toasterPlants.length) {
            this.toasterMsg += "**" + (toasterPlants.length === 1 ? `plant ${toasterPlants[0]} is` : `plants ${toasterPlants.join(',')} are`) + " already present for this lead time in below table";

          }
          // if (_unavailablePlants.length > 0) {
          //   this.toasterMsg += "**" + (_unavailablePlants.length === 1 ? `plant ${_unavailablePlants[0]} is` : `plants ${_unavailablePlants.join(',')} are`) + ` not available for the material number ${item.materialNumber}.`;
          // }
        }
        else if (type.changeType === 'Other') {
          this.addItemToTable(type, item.materialNumber, this.otherValue);
        }
        else {
          let changeTypeDet = this.existingData.filter((x: any) => x.materialNumber == item.materialNumber && x.changeType == type.changeType && x.status !== 'Cancelled')[0];
          if (changeTypeDet) {
            existingChangeTypes.push(type.changeType!);
            return;
          }
          let excelfileItem: ChangeDataGenerateExcelItem = this.excelFileData.filter((y: any) => y.changeType == type.changeType && y.materialNumber === item.materialNumber)[0];
          if (excelfileItem) {
            this.toasterMsg += "**" + "Data with " + item.materialNumber + " and data change type " + type.changeType + " already exists in below table";
            return;
          }
          this.addItemToTable(type, item.materialNumber, '');
        }
      });
      if (existingChangeTypes.length > 0) {
        let _lastChangeType: string = existingChangeTypes.splice(existingChangeTypes.length - 1, 1)[0];
        this.note.push("**" + item.materialNumber + ": Data change " + (existingChangeTypes.length === 0 ? `type ${_lastChangeType} already exists.` : `types ${existingChangeTypes.join(', ')} and ${_lastChangeType} already exist.`));
      }
    })

    if (this.toasterMsg) {
      this.commonService.showNotificationMessage(this.ChangeDataConst.generateExcelErrorMessage, 'error', 'center');
    }
    this.selectedValues = [];
    this.isShowPlantsDropdown = false;
    this.isShowOther = false;
    this.selectedItems = [];
    this.selectedPlants = [];
    this.otherValue = '';
    this.selectedMaterialNumbersList = [];
    this.isShowTypeOfChangeDataList = false;
  }

  addItemToTable(type: MatChangeTypeDetailsDto, materialNumber: any, plantOtherChangeType?: string) {
    let changeDataGenerateExcelItem: ChangeDataGenerateExcelItem = this.setChangeDataGenerateExcelItem(type, materialNumber, plantOtherChangeType);
    this.excelFileData.push(changeDataGenerateExcelItem);
  }

  public exportToExcel(): any {
    let convertedArr = [];
    for (let item of this.excelFileData) {
      let obj = {
        mmsKey: '',
        lineNumber: '',
        materialItemNumber: item.materialNumber,
        changeType: item.changeType,
        plant: item.changeType === 'Lead Time' ? item.plantOtherChangeType : '',
        otherChangeType: item.changeType === 'Other' ? item.plantOtherChangeType : '',
        oldData: item.changeType === 'Lead Time' ? (item.oldData ? item.oldData : 0) : item.oldData,
        newData: '',
        requesterNotes: '',
        description: ''
      }
      convertedArr.push(obj)
    }
    return convertedArr;
  }

  modifyExcelAndDownload(): void {

    // this.commonService.fetchStaticFile(this.excelFilePath).subscribe((data: any) => {
    //   const reader: FileReader = new FileReader(),
    //     itemInventorySheetName: string = 'Item Inventory',
    //     instructionsInventorySheetName: string = 'Instructions',
    //     referenceInventorySheetName: string = 'Reference';

    //   reader.onload = (e: any) => {
    //     const data = new Uint8Array(e.target.result);
    //     const templateWorkbook = new Excel.Workbook();
    //     templateWorkbook.xlsx.load(data).then(() => {
    //       const instructionsInventorySheet = templateWorkbook.getWorksheet(instructionsInventorySheetName);
    //       const referenceInventorySheet = templateWorkbook.getWorksheet(referenceInventorySheetName);

    //       let workbook = new Excel.Workbook();

    //       let itemInventoryWorksheet = workbook.addWorksheet('Item Inventory');
    //       const header = ["MMS Key (System Use Only)", "Line Number", "Material Number*", 'Change Type *', "Plant", "Other Change Type", "Old Data", "New Data *", "Requester Notes", "Description"]
    //       itemInventoryWorksheet.addRow(header);
    //       itemInventoryWorksheet.model.properties.defaultColWidth = 25;
    //       const newRow = itemInventoryWorksheet.getRow(1)
    //       newRow.height = 60;
    //       itemInventoryWorksheet.eachRow((row: Excel.Row, rowNumber: number) => {
    //         row.eachCell((cell: Excel.Cell, colNumber: number) => {
    //           if (colNumber == 1) {
    //             cell.fill = {
    //               type: "pattern",
    //               pattern: "solid",
    //               fgColor: { argb: "C5C5C5" },
    //             };
    //           }
    //           if (rowNumber == 1) {
    //             cell.font = {
    //               color: { argb: "000000" },
    //               bold: true,
    //             };
    //             cell.border = {
    //               top: { style: "thin" },
    //               left: { style: "thin" },
    //               bottom: { style: "thin" },
    //               right: { style: "thin" },
    //             };
    //             if (cell.address == 'A1' || cell.address == 'B1' || cell.address == 'C1' || cell.address == 'D1' || cell.address == 'E1' || cell.address == 'G1' || cell.address == 'J1') {
    //               cell.fill = {
    //                 type: "pattern",
    //                 pattern: "solid",
    //                 fgColor: { argb: "C5C5C5" },
    //               };
    //             }
    //             else {
    //               cell.fill = {
    //                 type: "pattern",
    //                 pattern: "solid",
    //                 fgColor: { argb: "BCD3EE" },
    //               };
    //             }
    //           }
    //         });
    //       });

    //       let count: number = 2;
    //       for (let data of this.excelFileData) {
    //         const materialNumberCell = itemInventoryWorksheet.getCell(`C${count}`);
    //         materialNumberCell.value = data.materialNumber;
    //         const changeTypeCell = itemInventoryWorksheet.getCell(`D${count}`);
    //         changeTypeCell.value = data.changeType;
    //         if (data.changeTypeId === this.changeDataEnum.leadTime) {
    //           const plantCell = itemInventoryWorksheet.getCell(`E${count}`);
    //           plantCell.value = data.plantOtherChangeType;
    //         }
    //         if (data.changeTypeId === this.changeDataEnum.other) {
    //           const otherChangeTypeCell = itemInventoryWorksheet.getCell(`F${count}`);
    //           otherChangeTypeCell.value = data.plantOtherChangeType;
    //         }
    //         const oldDataCell = itemInventoryWorksheet.getCell(`G${count}`);
    //         oldDataCell.value = data.oldData;
    //         count++;
    //       }
    //       const newWorksheet2 = workbook.addWorksheet('Instructions');
    //       newWorksheet2.model.properties.defaultColWidth = 25;
    //       instructionsInventorySheet.eachRow((row: Excel.Row, rowNumber: number) => {
    //         const newRow = newWorksheet2.getRow(rowNumber);
    //         row.eachCell((cell: Excel.Cell, colNumber: number) => {
    //           const newCell = newRow.getCell(colNumber);
    //           newCell.value = cell.value;
    //           newCell.style = cell.style;
    //           newCell.alignment = cell.alignment;
    //           newCell.fill = cell.fill;
    //         });
    //       });
    //       const newWorksheet1 = workbook.addWorksheet('Reference');
    //       newWorksheet1.model.properties.defaultColWidth = 35;
    //       referenceInventorySheet.eachRow((row: Excel.Row, rowNumber: number) => {
    //         const newRow = newWorksheet1.getRow(rowNumber);
    //         row.eachCell((cell: Excel.Cell, colNumber: number) => {
    //           const newCell = newRow.getCell(colNumber);
    //           newCell.value = cell.value;
    //           newCell.style = cell.style;
    //           newCell.alignment = cell.alignment;
    //           newCell.fill = cell.fill;
    //         });
    //       });
    //       workbook.xlsx.writeBuffer().then((data1) => {
    //         let date = this.commonService.formatDateTo_ddmmyyyy(new Date());
    //         const fileName = this.requestNumber + '-' + this.excelFileName + '-' + date + '.xlsx';
    //         const blob1 = new Blob([data1], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         saveAs(blob1, fileName);
    //         this.navBack();
    //       });
    //     });
    //   };
    //   reader.readAsArrayBuffer(data);
    // });
  }
}
