import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitOfMeasure } from '../../models/unitofmeasure/unitofmeasure.model';
import { UnitOfMeasureService } from '../../services/unit-of-measure.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { UnitOfMeasureConstant } from 'src/app/core/constants/unit-of-measure.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';
@Component({
  selector: 'app-unitofmeasure',
  templateUrl: './unit-of-measure.component.html',
  styleUrls: ['./unit-of-measure.component.scss']
})
export class UnitOfMeasureComponent implements OnInit {
  constructor( private unitOfMeasureService: UnitOfMeasureService,
               private route: ActivatedRoute,
               public router: Router, private dialogService: DialogService,
               public commonService: CommonService
    ) {}

  public gridData: UnitOfMeasure[] = [];
  public globalGridData: UnitOfMeasure[] = [];
  public checked: true = true;
  public searchText: string = '';
  public showInactive = false;

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getUnitOfMeasure();
  }

  getUnitOfMeasure() {
    this.unitOfMeasureService.getUnitOfMeasure().subscribe((response: UnitOfMeasure[]) => {
      this.globalGridData = Object.assign([], response);
      this.updateGrid();
    });
  }

  updateGrid() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData, this.showInactive, UnitOfMeasureConstant.searchFieldName, this.searchText, UnitOfMeasureConstant.code);
  }


  cancelSearch() {
    this.searchText = '';
    this.updateGrid();
  }

  activeChanged(value: boolean) {
    this.showInactive = value;
    this.updateGrid();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  manage(isCreate: boolean, isActive: boolean, name: string, id: number, code: string) {
    this.router.navigate(["../manageUOM"], {
      state: { 'isCreate': isCreate, 'id': id, 'name': name, 'isActive': isActive, 'code': code },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let storageConditions: UnitOfMeasure[] = arr.map((item: any) => {
          return {
            id: 0,
            name: item['Unit Of Measure'].toString(), code: item['UOM Code'].toString(), isActive: item['Is Active'],
            createdBy: item['Created By'], modifiedBy: item['Modified By'],
            createdDateTime: undefined, modifiedDateTime: undefined
          };
        });
        this.unitOfMeasureService.importExcelData(storageConditions).subscribe((response: UnitOfMeasure) => {
          this.commonService.showNotificationMessage(UnitOfMeasureConstant.bulkInsertMessage, 'success', 'right')
          this.getUnitOfMeasure();
        });
      }
    });
  }
}
