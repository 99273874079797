import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { SPKConst } from 'src/app/core/constants/spk.const';
import { CommonService } from 'src/app/core/services/common.service';
import { SPKDto } from '../../models/spk/spk.model';
import { SPKService } from '../../services/spk.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-spk',
  templateUrl: './spk.component.html',
  styleUrls: ['./spk.component.scss']
})
export class SpkComponent implements OnInit {
  globalGridData: SPKDto[] = [];
  searchText: string = SharedConst.empty;
  gridData: SPKDto[] = [];
  isActive = false;
  constructor(private spkService: SPKService, public router: Router,
    private route: ActivatedRoute, public commonService: CommonService, private dialogService: DialogService) {
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getSPKList();
  }

  getSPKList() {
    this.spkService.getSPKAll().subscribe((outputDtata: SPKDto[]) => {
      this.gridData = outputDtata;
      this.globalGridData = Object.assign([], outputDtata);
      this.search();
    }
    );
  }

  search() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData,
      this.isActive, SPKConst.searchFieldName, this.searchText, SPKConst.code)
  }

  clear() {
    this.searchText = SharedConst.empty;
    this.gridData = Object.assign([], this.globalGridData);
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  
  manage(isCreate: boolean, isActive: boolean, name: string, code: string, id: number) {
    this.router.navigate(["../" + SPKConst.manageSPKPath], {
      state: { isCreate: isCreate, id: id, name: name, code: code, isActive: isActive },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let spk: SPKDto[] = arr.map((item: any) => {
          return {
            id: 0,
            name: item['Name'].toString(), code: item['Code'].toString(), isActive: item['Is Active'],
            createdBy: item['Created By'], modifiedBy: item['Modified By'],
            createdDateTime: undefined, modifiedDateTime: undefined
          };
        });
        this.spkService.importExcelData(spk).subscribe((response: SPKDto) => {
          this.commonService.showNotificationMessage(SPKConst.bulkInsertMessage, 'success', 'right')
          this.getSPKList();
        });
      }
    });
  }
}
