import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialType } from '../../../models/materialtype/materialtype.model';
import { MaterialTypeService } from '../../../services/material-type.service';
import { CommonService } from 'src/app/core/services/common.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-manage-material-type',
  templateUrl: './manage-material-type.component.html',
  styleUrls: ['./manage-material-type.component.css']
})
export class ManageMaterialTypeComponent implements OnInit {

  public id: number = 0;
  public isCreate: boolean = false;
  public name: string = '';
  public isActive: boolean = false;
  public headerText: string = "Create New Material Type"
  public code: string = ''

  constructor(public router: Router,
    private route: ActivatedRoute,
    private materialTypeService: MaterialTypeService, public commonService: CommonService,
  ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.isCreate = stateData['isCreate']
    this.name = stateData['name'];
    this.isActive = stateData['isActive']
    this.code = stateData['code']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.name;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }

  save() {
    const materialType = new MaterialType();
    materialType.id = this.id;
    materialType.name = this.name;
    materialType.isActive = this.isActive;
    materialType.code = this.code;
    materialType.createdBy = this.commonService.getUserEmailId();
    materialType.modifiedBy = this.commonService.getUserEmailId();
    if (!this.isCreate) {
      this.materialTypeService.updateMaterialType(materialType).subscribe((response: MaterialType) => {
        this.router.navigate(["materialType"], { relativeTo: this.route.parent });
      });
    }
    else {
      this.materialTypeService.saveMaterialType(materialType).subscribe((response: MaterialType) => {
        this.router.navigate(["materialType"], { relativeTo: this.route.parent });
      });
    }
  }

  cancel() {
    this.router.navigate(["materialType"], { relativeTo: this.route.parent });
  }


}
