import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { requestIssueConst } from "src/app/core/constants/request-Issue.const";
import { AddNewItemActionResponseDto } from "../models/add-new-action/add-new-item-action-list.model";
import { HttpUtilityService } from "src/app/core/services/http.utility.service";
import { QACodeDto } from "../../setup/models/qacode/qacode.model";
import { QACodeConst } from "src/app/core/constants/qacode.const";
import { GroupConstant } from "src/app/core/constants/group.constants";
import { GroupDto } from "../../setup/models/userandrole/group.model";
import { UserDto } from "../../setup/models/userandrole/user.model";
import { ConsultNewActionDto } from "../models/add-new-action/consult-new-action.model";
import { AddNewItemConstant } from "src/app/core/constants/add-new-item.constant";

@Injectable({
    providedIn: 'root'
})
export class AddNewActionService {
    constructor(private httpUtilityService: HttpUtilityService) {
    }
    
    getAddNewItemActionDetails(requestId: number, requestIssueId: number): Observable<AddNewItemActionResponseDto> {
        return this.httpUtilityService.get(requestIssueConst.apiName + requestIssueConst.getAddNewItemActionDetails + '/' + requestId + '&' + requestIssueId);
    }

    getGroups() : Observable<GroupDto[]> {
        return this.httpUtilityService.get(GroupConstant.Group);
    }

    getUserGroups(groupId: number) : Observable<UserDto[]> {
        var url = `${GroupConstant.userGroupsAPI}/user-group?groupId=${groupId}`;
        return this.httpUtilityService.get(url);
    }

    getAllQACodes() : Observable<QACodeDto[]> {
        return this.httpUtilityService.get(QACodeConst.QAApiName);
    }

    updateOnConsultAction(item: ConsultNewActionDto) : Observable<ConsultNewActionDto>  {
        return this.httpUtilityService.put(AddNewItemConstant.UpdateNewItemOnConsultAction, item);
    }
}