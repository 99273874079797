<app-header *ngIf="isAuthenticated" (loadContent)="loadApplication($event)"></app-header>
<!-- Body -->
<section *ngIf="isAuthenticated" class="m-100">
    <!-- left nav -->
    <nav class="sideNav">
        <div class="sItem" [ngStyle]="{'background-color': activeTab === 'sar' ? '#D2E6FA' : '' }">
            <a (click)="activeTab = 'sar'" routerLink="/sar/">
                <div class="sideIcon">
                    <img src="./../assets/icons/NCR.svg" alt="">
                </div>
                <p>SAR</p>
            </a>
        </div>

        <div class="sItem" [ngStyle]="{'background-color': activeTab === 'allsar' ? '#D2E6FA' : '' }">
            <a (click)="activeTab = 'allsar'" routerLink="/allsar/">
                <div class="sideIcon">
                    <img src="./../assets/icons/AllNCRs.svg" alt="">
                </div>
                <p>ALL SARs</p>
            </a>
        </div>
        
        <div class="sItem" [ngStyle]="{'background-color': activeTab === 'audit' ? '#D2E6FA' : '' }">
            <a (click)="activeTab = 'audit'" routerLink="/audit/">
                <div class="sideIcon">
                    <img src="./../assets/icons/Audit.svg" alt="">
                </div>
                <p>Audit</p>
            </a>
        </div>

        <div class="sItem" [ngStyle]="{'background-color': activeTab === 'sar-allocation' ? '#D2E6FA' : '' }">
            <a (click)="activeTab = 'sar-allocation'" routerLink="/sar-allocation/">
                <div class="sideIcon">
                    <img src="./../assets/icons/SAR_Allocation.svg" alt="">
                </div>
                <p>SAR allocation</p>
            </a>
        </div>

        <div class="sidenav-bottom">
            <div class="sItem" [ngStyle]="{'background-color': activeTab === 'userguide' ? '#D2E6FA' : '' }">
                <a (click)="activeTab = 'userguide'" routerLink="/userguide/">
                    <div class="sideIcon">
                        <img src="./../assets/icons/AllNCRs.svg" alt="">
                    </div>
                    <p>User Guide</p>
                </a>
            </div>
            <div *ngIf="isAdmin()"  class="sItem c-mb-30" [ngStyle]="{'background-color': activeTab === 'setup' ? '#D2E6FA' : '' }">
                <a (click)="activeTab = 'setup'" routerLink="/setup/">
                    <div class="sideIcon">
                        <img src="./../assets/icons/MMS.svg" alt="">
                    </div>
                    <p class="GothamBook">Setup</p>
                </a>
            </div>
        </div>
    </nav>
    <!-- Right content -->
    <div class="main-content-box" *ngIf="isAuthenticated">
        <router-outlet></router-outlet>
    </div>

</section>

<app-invalid-permissions *ngIf="!isAuthenticated"></app-invalid-permissions>