import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupConstant } from 'src/app/core/constants/group.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Group } from '../models/group/group.model';

@Injectable({
    providedIn: 'root'
})
export class UserGroupsService extends HttpAbstractService<Group> {
    buName: string = '';
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService, private commonService: CommonService) {
        super(http);
        this.buName = this.commonService.getUserBUName();
    }
    getAssignmentGroups(groupType: number) {
        return this.getAll(GroupConstant.userGroupsAPI + GroupConstant.getAssignmentGroups + `/${groupType}/${this.buName}`);
    }
}
