<div class="MainPopup">
    <div class="text-right" style="cursor: pointer;"><a (click)="close('no')"><i class="fa fa-times"></i></a> 
    </div>
    <h2> <b>Confirm {{headerOption}}</b></h2>
    <br />
    <span>{{confirmMessage}}</span>
    <br />
    <br />
    <div class="mt-10 mx-5 w-100">
        <button kendoButton class="primary-button ml-5 f-size-150 noButton" (click)="close('no')"> No
        </button>
        <button kendoButton class="primary-button ml-5 f-size-150" (click)="close('yes')">
            Yes
        </button>
    </div>
    <br />
    <div *ngIf="isRejectedCancel" class="note  mx-5 d-inline-block">
        Note:
        <br />
        Cancel is an irreversible action which result in non-actionable items.
    </div>
</div>