
export const ValuationTypeConst = {
    VTApiName:'api/ValuationType',
    VTPath: 'VT',
    manageVTPath: 'manageValuationType',
    createText:'Create New Valuation Type',
    insertMessage:'Valuation type has been added successfully',
    updateMessage:'Valuation type has been updated successfully',
    nameError:'Name is required',
    searchFieldName: 'name',
    bulkInsertMessage:'Valuation types are successfully importd'
    
  };