
export const SPKConst = {
    SPKApiName: 'api/SPK',
    SPKPath: 'SPK',
    manageSPKPath: 'manageSPK',
    createText: 'New SPK',
    insertMessage: 'SPK has been added successfully',
    updateMessage: 'SPK has been updated successfully',
    nameError: 'Name is required',
    searchFieldName: 'name',
    code: 'code',
    bulkInsertMessage:'SPK codes are successfully importd',

};