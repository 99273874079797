import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule } from '@angular/forms';
import { OrdersComponent } from "../components/orders/orders.component";
import { KendoModule } from "src/app/modules/kendo.module";

@NgModule({
    declarations: [
        OrdersComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        HttpClientModule,
        KendoModule
    ],
    exports: [
        OrdersComponent
    ],
    providers: [
    ],
})
export class AllSARModule { }
