import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialInfoService } from '../../../services/material-info.service';
import { MaterialDataDto } from '../../../models/materialinfo/materialData.model';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-material-details',
  templateUrl: './material-details.component.html',
  styleUrls: ['./material-details.component.scss']
})
export class MaterialDetailsComponent implements OnInit {
  matNumber: string = '';
  plant: string = '';
  materialDataDto: MaterialDataDto = {
    materialDetails: undefined,
    materialPlants: [],
    materialMRPs: [],
    materialItemNotes: [],
    materialTaxs: []
  };

  constructor(public router: Router,
    private route: ActivatedRoute, private materialInfoService: MaterialInfoService, private commonService: CommonService) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;

    this.matNumber = stateData['matNumber'];
    this.plant = stateData['plant']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getInfoDetail();
  }
  getInfoDetail() {
    this.materialInfoService.getMaterialInfo<MaterialDataDto>(this.matNumber, this.plant).subscribe((outputDtata: MaterialDataDto) => {
      this.materialDataDto = outputDtata;
    }
    );
  }

}
