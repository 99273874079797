export class RequestDto {
    id: number = 0;
    requestStateId: number | undefined;
    ownerId: number | undefined;
    assetId?: number;
    tittle: string ='';
    priority: number | undefined;
    justification: string ='';
    segmentId: number | undefined;
    buId: number | undefined;
    requestTypeId: number | undefined;
    createdBy: string | undefined;
    modifiedBy: string | undefined;
}