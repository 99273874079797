import { AddNewItemActionDetails } from "./add-new-item-action-details.model";

export class AddNewItemAction {
    id?: number; // we need to check for id here, as mandatory
    lineNumber: number = 0;
    requestLineId: number = 0;
    description: string | undefined;
    manufactureName: string | undefined;
    manufacturePartNumber: string | undefined;
    unitOfMeasureName: string | undefined;
    materialTypeName: string | undefined;
    materialGroupCodeName: string | undefined;
    segmentName: string | undefined;
    status: string | undefined;
    comment?: string;
    addNewItemActionDetails!: AddNewItemActionDetails;
    enrichedDescription?: string;
    materialNumber?: string | undefined;
    shortDescription?: string | undefined;
    isCommentError?: boolean = false;
    isMatValid?: boolean = true;
    isRowEdit?: boolean = false;
    createdBy: string = ''
    mmsKey?:string[];
    unitOfMeasureId?: number | undefined;
    materialTypeId?: number | undefined;
    materialGroupCodeId?: number | undefined;
    materialStatusId?: number | undefined;
    storageConditionId?: number | undefined;
    segmentId?: number | undefined;
    recommendedSupplierId?: number | undefined;
    requesterNotes?: string | undefined;
    equipmentCriticality?: string | undefined;
}