
export class ChangeDataDto {
    requesterNotes?: string;
    itemNumber?: string;
    createdBy?: string;
    modifiedBy?: string;
    lineNumber?: number;
    id?: number;
    requestId?: number;
    requestIssueId?: number;
    requestIssueTypeId?: number;
    materialNumber?: string;
    changeTypeDetails?: ChangeTypeDetailsDto[];
    operation?: number;
    item?: any
}

export class ChangeTypeDetailsDto {
    id: number | undefined;
    requestId?: number;
    lineNumber?: number;
    changeTypeId?: number | '';
    changeType?: string | '';
    order?: number = 0;
    changeTypeValues?: ChangeTypeValueDto[] = [];
    operation?: number;
}
export class ChangeTypeValueDto {
    id?: number;
    branchPlantOrOtherChangePlant?: any;
    isBranchPlantApplicable?: boolean;
    oldData?: string;
    newData?: any;
    changeType?: string;
    operation?: boolean;
    branchPlants?: BranchPlant[];
    isShowAdd?: boolean;
    isShowDelete?: boolean;
    hasError?: boolean = false;
    hasPlanterror?: boolean = false;
    hasPlantDuplicateError?: boolean = false;
    status: string = '';
    newDataLength?: number = 0;
}
export class BranchPlant {
    branchPlantCode?: string;
    branchPlantCurrentValue?: string;
}
export class BranchPlantDto {
  MaterialNumbers?: Array<string>;
  BuName?: string;
}
export class ChangeDataMatNumber {
  id?: number;
  materialNumber?: string;
}
export class MatNumChangeTypeDto {
  MaterialNumbers?: Array<string>;
  ChangeTypes?: Array<string>;
  BuName?: string;
}
export class MatChangeTypeDetailsDto {
  changeType?: string;
  newData?: string;
  oldData?: string;
  isAlreadyPresent?: boolean;
  branchPlants?: BranchPlantDetail[];
}
export class BranchPlantDetail {
  branchPlantCode?: string;
  branchPlantCurrentValue?: string;
  status?: boolean;
}
export class MatNumberChangeDetails {
  materialNumber?: string;
  changeTypeValues?: MatChangeTypeDetailsDto[];
}
