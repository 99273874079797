import { Component, OnInit } from '@angular/core';
import { ApprovalDetailsConstants } from '../../constants/approval-details.constants';
import { CommonService } from '../../services/common.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-bulk-cancel',
  templateUrl: './bulk-cancel.component.html',
  styleUrls: ['./bulk-cancel.component.scss']
})
export class BulkCancelComponent {
  comment: string = '';
  isCancel: boolean = false;
  constructor(private commonService: CommonService, public dialog: DialogRef, private dialogService: DialogService,) { }

  cancel(actionName: string) {
    if (actionName === 'Close') {
      this.dialog.close({ action: actionName, comment: this.comment });
      return false;
    }
    if (actionName === 'Cancel') {
      this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
        .subscribe((res: any) => {
          if (res.action === 'yes') {
            this.dialog.close({ action: actionName, comment: this.comment });
          }
        });
    }
    if (actionName === 'Delete') {
      this.commonService.showConfirmation('Delete', ApprovalDetailsConstants.deleteMessage)
        .subscribe((res: any) => {
          if (res.action === 'yes') {
            this.dialog.close({ action: actionName, comment: this.comment });
          }
        });
    }
  }
}
