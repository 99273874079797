import { Injectable } from "@angular/core";
import { HttpUtilityService } from "src/app/core/services/http.utility.service";
import { Observable } from 'rxjs';
import { SelectDataDto } from "../../../core/models/select-data.models";
import { SarAllocationDataDto } from "../models/sar-allocation.models";
import { AllocateRequestDto } from "../models/allocate.models";

@Injectable({
    providedIn: 'root'
})
export class SarAllocationService {
    constructor(public httpUtilityService: HttpUtilityService) {
    }

    getSarAllocation(stateId: number, actionTypeId?: number, assetId?: number): Observable<any[]> {
        let url: string;
        if(actionTypeId !== undefined && assetId != undefined) {
            url = `api/SarAllocation?stateId=${stateId}&actionTypeId=${actionTypeId}&assetId=${assetId}`;
        } 

        else if (actionTypeId !== undefined) {
            url = `api/SarAllocation?stateId=${stateId}&actionTypeId=${actionTypeId}`;
        }
        
        else {
            url = `api/SarAllocation?stateId=${stateId}`;
        }
        return this.httpUtilityService.get<SarAllocationDataDto[]>(url);
    }

    getActionTypes(): Observable<SelectDataDto[]> {
        return this.httpUtilityService.get<SelectDataDto[]>('api/SarAllocation/action');
    }

    getStates(): Observable<SelectDataDto[]> {
        return this.httpUtilityService.get<SelectDataDto[]>('api/SarAllocation/state');
    }

    getAssets(): Observable<SelectDataDto[]> {
        return this.httpUtilityService.get<SelectDataDto[]>('api/SarAllocation/asset');
    }

    getGroups(): Observable<SelectDataDto[]> {
        return this.httpUtilityService.get<SelectDataDto[]>('api/SarAllocation/group');
    }

    getGroupUsers(groupId?: number): Observable<SelectDataDto[]> {
        var url = `api/SarAllocation/group-users?groupId=${groupId}`;
        return this.httpUtilityService.get<SelectDataDto[]>(url);
    }

    allocateUserGroup(data: AllocateRequestDto[]): Observable<any> {
        const url = `api/SarAllocation/allocate`;
        return this.httpUtilityService.put(url, data);
    }
}
