import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Observable } from 'rxjs';
import { TempActionRequestConst } from 'src/app/core/constants/temp-action.constant';

@Injectable({
    providedIn: 'root'
})
export class TempActionService {

    constructor(public httpUtilityService: HttpUtilityService) {
    }
    createTempAction(data: FormData): Observable<any> {
        return this.httpUtilityService.post(TempActionRequestConst.tempActionAPI + TempActionRequestConst.createTempAction, data);
    }
    deleteTempAction(requestIssueId: number, attachmentId: number): Observable<any> {
        return this.httpUtilityService.delete(TempActionRequestConst.tempActionAPI + TempActionRequestConst.deleteTempAction + `/${requestIssueId}&${attachmentId}`);
    }
    downLoadAttachment(blobURI: string): Observable<any> {
        return this.httpUtilityService.get(TempActionRequestConst.tempActionAPI + TempActionRequestConst.downloadTempAction + `/${blobURI}`, { responseType: 'Blob' });
    }

    fetchBlobUrl(fileName: string): Observable<any> {
        return this.httpUtilityService.get(TempActionRequestConst.getBlobFileDetail + '/' + fileName);
    }

}
