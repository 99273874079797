import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { MaterialStatus } from '../models/materialstatus/materialstatus.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { MaterialInfoConst } from 'src/app/core/constants/material-info.const';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class MaterialInfoService {

    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    }

    getAllMaterialNumbers<T>(): Observable<T> {
        return this.httpUtilityService.getAll<T>(MaterialInfoConst.apiName + MaterialInfoConst.getAllMaterialNumbers)
    }

     isValidMaterialNumber<T>(buName: string ,matNumber: string): Observable<T> {
       return this.httpUtilityService.getAll<T>(MaterialInfoConst.apiName + MaterialInfoConst.isValidMaterialNumber + '/' +buName +'/'+matNumber)
    }
    
    getAllMaterialPlants<T>(): Observable<T> {
        return this.httpUtilityService.getAll<T>(MaterialInfoConst.apiName + MaterialInfoConst.getAllMaterialPlants)
    }
    getAllPlantsByMaterialNumber<T>(matNumber: string): Observable<T> {
        return this.httpUtilityService.getAll<T>(MaterialInfoConst.apiName + MaterialInfoConst.getAllPlantsByMaterialNumber +'/'+matNumber)
    }
    
    searchMaterialInfo<T>(matNumber: string, plantName: string): Observable<T> {
        let url = MaterialInfoConst.apiName +
        MaterialInfoConst.searchMaterialInformation + '/' + matNumber
        if (plantName) {
            url = MaterialInfoConst.apiName +
            MaterialInfoConst.searchMaterialInformation + '/' + matNumber + '?plant=' + plantName;
        }
        return this.httpUtilityService.getAll<T>(url)
    }
    
    getMaterialInfo<T>(matNumber: string, plantName: string): Observable<T> {
        let url = MaterialInfoConst.apiName +
        MaterialInfoConst.getMaterialInfo + '/' + matNumber + '/' + plantName;
        return this.httpUtilityService.getAll<T>(url);
    }
    postOnDemandMaterialInfo<T>(syncMaterialDataOnDemand: T): Observable<T> {
        return this.httpUtilityService.post<T>(MaterialInfoConst.onDemandApiName+MaterialInfoConst.syncMaterialData, syncMaterialDataOnDemand);
    }
  
     getMaterialDataOnDemand<T>( plantId: string, materialNumber: string, ): Observable<T> {
        let url = MaterialInfoConst.onDemandApiName +
        MaterialInfoConst.getMaterialDataOnDemand + '?plantId='+plantId+'&materialNumber='+materialNumber;
        return this.httpUtilityService.getAll<T>(url)
    }
    

} 
