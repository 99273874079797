
export class AddNewActionItemsAttachmentDetails {
    attachmentId?: number;
    fileName?: string;
    blobUrI?: string;
    operation!: number;
}
export class AddNewActionItemsAttachmentDetailsDisplay {
    attachmentId?: number;
    file?: File;
    fileName?: string;
    blobUrI?: string;
    operation!: number;
}