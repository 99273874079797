import { Component, OnInit, ViewChild } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent, MultipleSortSettings, PageChangeEvent, SelectAllCheckboxState, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { SarAllocationService } from '../../services/sar-allocation-service';
import { SelectDataDto } from '../../../../core/models/select-data.models';
import { AllocateRequestDto } from '../../models/allocate.models';
import { SarAllocationDataDto } from '../../models/sar-allocation.models';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-sar-allocation',
  templateUrl: './sar-allocation.component.html',
  styleUrls: ['./sar-allocation.component.scss']
})
export class SarAllocationComponent implements OnInit {
  @ViewChild(GridComponent)
  grid: GridComponent;

  public filter: any = { logic: 'and', filters: [] };
  public sort: SortDescriptor[] = [{ field: 'sarId', dir: 'asc' }];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };
  public totalRecords = 0;
  public pageSize = 30;
  public loading = false;
  public allocateClicked = false;
  public submitAllocationErrorDialog = false;
  public states: SelectDataDto[] = [];
  public assets: SelectDataDto[] = [];
  public actionTypes: SelectDataDto[] = [];
  public groups: SelectDataDto[] = [];
  public groupUsers: SelectDataDto[] = [];  
  public gridData: SarAllocationDataDto[] = [];
  public itemSelection: Array<string> = [];
  public gridSelection: Array<SarAllocationDataDto> = [];  
  public selectAllState: SelectAllCheckboxState = "unchecked";

  public allocateButtonDisabled = true;
  public searchButtonDisabled = true;
  public submitButtonDisabled = true;
  public actionTypeSelectDisabled = true;
  public assetSelectDisabled = true;
  public userGroupSelectDisabled = true;

  public selectedState: SelectDataDto;
  public selectedActionType?: SelectDataDto;
  public selectedAsset?: SelectDataDto;
  public selectedGroup?: SelectDataDto;
  public selectedUserGroup: SelectDataDto = { id: -1, name: " - "};
  
  public stateSelectPlaceHolder: SelectDataDto = {
    name: "Select state...",
    id: undefined,
  };

  public actionSelectPlaceHolder: SelectDataDto = {
    name: "Select action type...",
    id: undefined,
  };

  public assetSelectPlaceHolder: SelectDataDto = {
    name: "Select asset...",
    id: undefined,
  };

  public groupSelectPlaceHolder: SelectDataDto = {
    name: "Select group...",
    id: undefined,
  };

  public userGroupSelectPlaceHolder: SelectDataDto = {
    name: "Select user group...",
    id: undefined,
  };

  public gridSelectableSettings = {
    enabled: true,
    mode: 'multiple',
  };

  constructor(private sarAllocationService: SarAllocationService) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    this.loadData();
  }

  public onClearFilterClick() {
    this.filter = { logic: 'and', filters: [] };
  }

  public onClearSortingClick() {
    this.sort = [];
  }

  public onSearchClick() {
    this.loading = true;
    this.loadDataToGrid();
  }

  loadDataToGrid(): void {
    this.gridData = [];
    this.sarAllocationService
      .getSarAllocation(this.selectedState.id!, this.selectedActionType?.id, this.selectedAsset?.id)
      .subscribe((result) => {
        this.gridData = result;
        this.loading = false;
      })
  }

  public onResetClick() {
    this.selectedActionType = this.actionSelectPlaceHolder;
    this.selectedState = this.stateSelectPlaceHolder;
  }

  public onAllocateClick() {
    this.sarAllocationService
      .getGroups()
      .subscribe((result) => {
        this.groups = result;
        this.allocateClicked = true;
      })
  }

  public submitAllocationClick(groupId?: number, userId?: number): void {
    if (!this.validateAllocation(groupId, userId))
      return;

    const result: AllocateRequestDto[] = [];
    for (let i = 0; i < this.gridSelection.length; i++) {
      if (this.gridSelection[i].requestIssueId != undefined) {
        const item: AllocateRequestDto = {
          requestIssueId: this.gridSelection[i].requestIssueId,
          requestLineId: this.gridSelection[i].requestLineId,
          requestStateId: this.gridSelection[i].requestStateId,
          assignedUserId: userId || undefined,
          assignedGroupId: groupId || undefined,
        };
        result.push(item);
      }
    }

    this.sarAllocationService.allocateUserGroup(result)
      .subscribe(() => {
        this.close();
        this.refresh();
      });
      
  }

  public validateAllocation(groupId?: number, userId?: number): boolean {
    if (groupId == undefined) {
      if (userId == undefined && userId != -1) {
        this.submitAllocationErrorDialog = true;
        return false;
      }
    }
    return true;
  }

  public refresh() {
    this.gridData = [];
    this.gridSelection = [];
    this.itemSelection = [];
    this.loading = true;
    this.sarAllocationService
      .getSarAllocation(this.selectedState.id!, this.selectedActionType?.id, this.selectedAsset?.id)
      .subscribe((result) => {
        this.gridData = result;
        this.loading = false;
      })
  }

  public closeError() {
    this.submitAllocationErrorDialog = false;
  }  

  public close(): void {
    this.allocateClicked = false;
    this.allocateButtonDisabled = true;
    this.itemSelection = [];
    this.gridSelection = [];
    this.selectAllState = "unchecked";
  }

  loadData(): void {
    forkJoin({
      actionTypes: this.sarAllocationService.getActionTypes(),
      states: this.sarAllocationService.getStates(),
      assets: this.sarAllocationService.getAssets()
    }).subscribe(({ actionTypes, states, assets }) => {
      this.actionTypes = actionTypes;
      this.states = states;
      this.assets = assets;
    });
  }

  public stateSelectionChange(value: SelectDataDto): void {
    if (value.id != undefined) {
      this.selectedState = value;
      this.actionTypeSelectDisabled = false;
      this.searchButtonDisabled = false;
      this.assetSelectDisabled = false;
    }
    else {
      this.actionTypeSelectDisabled = true;
      this.assetSelectDisabled = true;
      this.searchButtonDisabled = true;
    }
  }

  public actionTypeSelectionChange(value: SelectDataDto): void {
    if (value != undefined) {
      this.selectedActionType = value;
    }
  }

  public assetSelectionChange(value: SelectDataDto): void {
    if (value != undefined) {
      this.selectedAsset = value;
    }
  }

  public groupSelectionChange(value: SelectDataDto): void {
    if (value != undefined) {
      this.selectedGroup = value;

      this.sarAllocationService.getGroupUsers(value.id).subscribe(
        (result) => {
          this.groupUsers = result;
          this.userGroupSelectDisabled = false;
          this.submitButtonDisabled = false;
        }
      );
    }
  }

  public userGroupSelectionChange(value: SelectDataDto): void {
    if (value != undefined) {
      this.selectedUserGroup = value;
      this.submitButtonDisabled = false;
    }
  }

  public onSelectedKeysChange(event: SelectionEvent): void {
    if (event.selectedRows?.length !== 0 || undefined) {
      const selectedKeys = event.selectedRows as any[];

      if (typeof selectedKeys[0].index === 'number' && selectedKeys[0].index >= 0 && selectedKeys[0].dataItem.id != undefined) {
        this.itemSelection.push(selectedKeys[0].dataItem.id);
        var item = this.gridData.find(item => item.id === selectedKeys[0].dataItem.id);
        const data: SarAllocationDataDto = {
          id: item!.id,
          sarId: item!.sarId,
          sarType: item!.sarType,
          title: item!.title,
          actionNumber: item!.actionNumber,
          actionType: item!.actionType,
          supplier: item!.supplier,
          supplierNumber: item!.supplierNumber,
          requestIssueId: item!.requestIssueId,
          requestLineId: item!.requestLineId,
          requestStateId: item!.requestStateId,
        };

        this.gridSelection.push(data);
      }
    }

    if (event.deselectedRows?.length !== 0 || undefined) {
      const deselectedKeys = event.deselectedRows as any[];
      const itemToRemoveId = deselectedKeys[0].dataItem.id;
      const indexToRemove = this.gridSelection.findIndex(item => item.id === itemToRemoveId);

      if (indexToRemove !== -1) {
        this.gridSelection.splice(indexToRemove, 1);
      }
    }

    if (this.gridSelection.length === 0) {
      this.selectAllState = "unchecked";
    } else if (this.gridSelection.length > 0 && this.gridSelection.length < this.gridData.length) {
      this.selectAllState = "indeterminate";
    } else {
      this.selectAllState = "checked";
    }

    if (this.gridSelection.length > 0) {
      this.allocateButtonDisabled = false;
    }

    if (this.gridSelection.length == 0) {
      this.allocateButtonDisabled = true;
    }
  }

  public pageChange(event: PageChangeEvent): void { }

  public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
    if (checkedState === "checked") {
      this.itemSelection = this.gridData.map((item) => item.id);
      this.gridSelection = this.gridData;
      console.log('Grid selection count: ' + this.gridSelection.length)  
      this.selectAllState = "checked";
      this.allocateButtonDisabled = false;
    } else {
      this.selectAllState = "unchecked";
      this.itemSelection = [];
      this.gridSelection = []; 
      this.allocateButtonDisabled = true;
    }
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: [{ field: "sarId", dir: "asc" }],
      }).data,
    };
    return result;
  }
}
