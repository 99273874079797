<div class="MainPopup">
    <div *ngIf="isLoader" style="padding-left: 45%;"><kendo-loader [type]="loader.type" [themeColor]="loader.themeColor"
            [size]="loader.size">
        </kendo-loader>
    </div>
    <div *ngIf="!isLoader">
        <div class="text-right" style="cursor: pointer;"><a (click)="close('Close')"> <i class="fa fa-times"></i></a>
        </div>
        <h1> Confirmation</h1>
        <div *ngIf="isChangeData" class="pop-box">
            <div class="d-flex j-c-s color-lite-blue">
                <p>Select Line Items</p>
                <a href="javascript:void();" (click)="selectAll()">Select All</a>
            </div>
            <kendo-multiselect [data]="rejectedLineItems" style="border-color: black;" [filterable]="true"
                [(ngModel)]="selecteLineItems" (valueChange)="getAssignmentDetail()" textField="lineName"
                valueField="id"></kendo-multiselect>
            <br />
            <span *ngIf="!lineItemSelected" style="color:red"> Please select Line Items!</span>
        </div>
        <div class="pop-box">

            <h2 class="color-lite-blue">Previous assigment details:</h2>
            <div class="d-flex ">
                <div>
                    <h3>Assigned group</h3>
                    <h4 class="color-lite-blue h4">{{assignmentDetailsDto?.assignedGroup}}</h4>
                </div>
                <div class="ml-20">
                    <h3>Assigned User</h3>
                    <h4 class="color-lite-blue h4">{{assignmentDetailsDto?.assignedUser}}</h4>
                </div>
            </div>

        </div>
        <div *ngIf="isShowReAssign">
            <p>Do you want to re-submit the items to the previous assignment details? </p>
            <div class="d-flex a-center  mb-15">
                <h4 class="m-0">NO</h4>
                <label class="switch ml-10 mr-10">
                    <input type="checkbox" (change)="reSubmit()" #showInactiveCheck id="showInactiveCheck">
                    <span class="slider round"></span>
                </label>
                <h4 class="m-0">YES</h4>
            </div>
        </div>

        <div class="d-flex">

            <div class="mt-10 mr-10 w-50">
                <kendo-label text="Assigned To group">
                    <kendo-dropdownlist class="mt-5"[(ngModel)]="assignedGroup"  [data]="assignToGroups" textField="name" valueField="id"
                        (selectionChange)="selectAssignToGroup($event)" [disabled]="!newAssignment"
                        [defaultItem]="defaultAssignToGroup" [valuePrimitive]="true">
                    </kendo-dropdownlist>
                </kendo-label>
                <span *ngIf="!isGoupSelected" class="noValue">
                    Required field!
                    Please select group
                </span>
            </div>
            <div class="mt-10 ml-10  w-50">
                <kendo-label text="Assigned To user">
                    <kendo-dropdownlist [(ngModel)]="selectedUser" [disabled]="!enabledAssignToUser" class="mt-5"
                        [data]="assignToUsers" [defaultItem]="defaultAssignToUser" textField="name" valueField="id"
                        (selectionChange)="selectAssignToUser($event)" [disabled]="!newAssignment"
                        [valuePrimitive]="true">
                    </kendo-dropdownlist>
                </kendo-label>

            </div>
        </div>
        <div class="text-center mt-20">
            <button kendoButton class="primary-button" (click)="close('Submit')">Submit</button>
        </div>
    </div>
</div>