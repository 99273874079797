import { Attachments } from "./attachments.model";

export class TempActionRequestDto {
    requestId?: number;
    requestIssueId?: number;
    requestIssueTypeId?: number;
    comment?: string = '';
    attachments?: File[];
    createdBy !: string;
    modifiedBy?: string;
}