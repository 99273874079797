import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { CommonService } from 'src/app/core/services/common.service';
import { AddNewActionService } from '../../../services/add-new-action.service';
import { AddNewItemActionResponseDto } from '../../../models/add-new-action/add-new-item-action-list.model';
import { ApprovalMMDActionDetail, ApprovalSAActionDetail } from '../../../models/approval-details/action-detail.model';
import { AddNewItemAction } from '../../../models/add-new-action/add-new-action-item.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { BulkActionComponent } from 'src/app/core/components/bulk-action/bulk-action.component';
import { ExcelExportEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { ApprovalAssignmentComponent } from '../approval-assignment/approval-assignment.component';
import { ChangeDataConst } from 'src/app/core/constants/change-data.const';
import { AddItemsExcelColumnGOMBU, AddNewItemApprovalExcelColumn } from 'src/app/core/constants/excel-columns-const';
import { DialogUploadComponent } from '../../../../../core/components/dialog-upload/dialog-upload.component';
import { AddNewItemConstant } from '../../../../../core/constants/add-new-item.constant';
import { QACodeDto } from 'src/app/features/setup/models/qacode/qacode.model';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { GroupDto } from 'src/app/features/setup/models/userandrole/group.model';
import { ConsultNewActionDto } from '../../../models/add-new-action/consult-new-action.model';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-add-new-item-approval',
  templateUrl: './add-new-item-approval.component.html',
  styleUrls: ['./add-new-item-approval.component.scss']
})
export class AddNewItemApprovalComponent {
  @Input() public requiredParams: NewActionInputParam = {};
  @Input() public currentActionName: string = '';
  @Input() public dummyInput: number = 0;
  buName: string = '';
  requestIssueId: number = 0;
  selectedLines: any = [];
  @Output() saActionEvent = new EventEmitter<ApprovalSAActionDetail>();
  @Output() mmdActionEvent = new EventEmitter<ApprovalMMDActionDetail>();
  @Output() mmdBulkActionEvent = new EventEmitter<ApprovalMMDActionDetail[]>();
  @Output() saBulkActionEvent = new EventEmitter<ApprovalSAActionDetail[]>();
  excelEmit = new EventEmitter<ExcelExportEvent>();
  mySelection: number[] = [];

  requestAddNewActionItemDetails: AddNewItemActionResponseDto = new AddNewItemActionResponseDto();
  isBulk: boolean = true;
  tempDataIndex: number = 0;
  tempDataItem: AddNewItemAction = new AddNewItemAction();

  addNewItemApprovalExcelColumn: any = AddNewItemApprovalExcelColumn;
  addItemsExcelColumnGOBU: any = AddItemsExcelColumnGOMBU;
  isUploadExcelVisible: boolean = false;
  uploadErrors: string = '';
  countErrors: number = 0;
  consultClicked: boolean = false;
  userGroupSelectDisabled: boolean = true;
  consultBtnDisabled = true;
  consultErrorDialog = false;
  public consultAction: ConsultNewActionDto = { GroupId: -1};
  public selectedGroup?: GroupDto;
  public selectedUserGroup?: UserDto;
  public selectedQaCode?: QACodeDto;
  public groups: GroupDto[] = [];
  public groupUsers: UserDto[] = [];  
  public qaCodes: QACodeDto[] = [];
  public groupSelectPlaceHolder: GroupDto = {
    name: "Select group...",
    id: -1,
    description: undefined,
    businessunitId: undefined,
    groupTypeId: undefined,
    displayOrder: undefined,
    businessunitName: undefined,
    groupTypeName: undefined,
    isActive: undefined,
    createdBy: undefined,
    createdDateTime: undefined,
    modifiedBy: undefined,
    modifiedDateTime: undefined
  };

  public userGroupSelectPlaceHolder: UserDto = {
    name: "Select user group...",
    id: -1,
    provisionId: undefined,
    email: undefined,
    cai: undefined,
    userName: undefined,
    externalId: undefined,
    businessUnitId: undefined,
    groupIds: undefined,
    roleIds: undefined,
    roleDtos: undefined,
    groupDtos: undefined,
    businessUnitname: undefined
  };

  public qaCodeSelectPlaceHolder: QACodeDto = {
    name: "Select QA code",
    id: -1,
  };

  constructor(private commonService: CommonService, 
    private addNewActionService: AddNewActionService, 
    private dialogService: DialogService) { }

  ngOnChanges() {
    this.buName = this.commonService.getUserBUName();
    this.requestIssueId = this.requiredParams.requestIssueId!;
    this.getItems();
  }

  openPopup() {
    let isComplete = false;
    if (this.currentActionName === 'mmd-action') {
      for (let index of this.mySelection) {
        if (this.commonService.isNullOrEmptyOrUndefined(this.requestAddNewActionItemDetails.items[index].shortDescription)
          || this.commonService.isNullOrEmptyOrUndefined(this.requestAddNewActionItemDetails.items[index].enrichedDescription)
          || (this.commonService.isNullOrEmptyOrUndefined(this.requestAddNewActionItemDetails.items[index].materialNumber) || !Number(this.requestAddNewActionItemDetails.items[index].materialNumber))) {
          isComplete = true;
          break;
        }
      }
    }
    const dialog: DialogRef = this.dialogService.open({
      content: BulkActionComponent
    });
    const inc = dialog.content.instance as BulkActionComponent;
    inc.isMMD = this.currentActionName === 'mmd-action' ? true : false;
    inc.isCompleteDisable = isComplete;
    inc.changeType = this.requiredParams.changeType!;
    dialog.result.subscribe((r: any) => {
      if (this.commonService.isNullOrEmpty(r.action) || r.action === 'Close') {
        return false;
      }
      this.bulkAction(r.action, r.comment, r.lineItemDtail);
    });
  }
  fieldValidation(dataItem: AddNewItemAction) {
    if (this.commonService.isNullOrEmptyOrUndefined(dataItem.shortDescription) ||
      this.commonService.isNullOrEmptyOrUndefined(dataItem.enrichedDescription) ||
      (this.commonService.isNullOrEmptyOrUndefined(dataItem.materialNumber) || !Number(dataItem.materialNumber))
    ) {
      return true;
    }
    return false;
  }
  bulkAction(actionType: string, comment: string, lineItemDtail: any) {
    if (this.currentActionName === 'mmd-action') {
      let approvalMMDActionDetail: ApprovalMMDActionDetail[] = [];
      for (let selectedValue of this.mySelection) {
        let dataItem = this.requestAddNewActionItemDetails!.items[selectedValue];
        if (dataItem.status !== 'Pending MMD') {
          continue;
        }
        dataItem.comment = comment;
        let approvalActionDetail = this.getmmdValues(dataItem);
        if (actionType === 'Cancel') {
          approvalActionDetail.mmdAction = 3;
        }
        if (actionType === 'Reject') {
          approvalActionDetail.mmdAction = 1;
        }
        if (actionType === 'Complete') {
          approvalActionDetail.mmdAction = 2;
        }
        approvalMMDActionDetail.push(approvalActionDetail);
      }
      this.mmdBulkActionEvent.emit(approvalMMDActionDetail);
    }
    if (this.currentActionName === 'sa-action') {
      let approvalSAActionDetail: ApprovalSAActionDetail[] = [];
      for (let selectedValue of this.mySelection) {
        let dataItem = this.requestAddNewActionItemDetails!.items[selectedValue];
        if (dataItem.status !== 'Pending Stocking Approval') {
          continue;
        }
        dataItem.comment = comment;
        let approvalActionDetail = this.getsaValues(dataItem);
        if (actionType === 'Cancel') {
          approvalActionDetail.stockApproverAction = 3;
          approvalActionDetail.assignedGroupId = null;
          approvalActionDetail.assignedUserId = null;
        }
        if (actionType === 'Reject') {
          approvalActionDetail.stockApproverAction = 1;
          approvalActionDetail.assignedGroupId = null;
          approvalActionDetail.assignedUserId = null;
        }
        if (actionType === 'Complete') {
          approvalActionDetail.stockApproverAction = 2;
          approvalActionDetail.assignedGroupId = lineItemDtail.assignedGroupId;
          approvalActionDetail.assignedUserId = lineItemDtail.assignedUserId;
        }
        approvalSAActionDetail.push(approvalActionDetail);
      }
      this.saBulkActionEvent.emit(approvalSAActionDetail);
    }
  }

  getSAButtonText(): string {
    if (this.currentActionName === 'sa-action') {
      return 'Approve';
    }
    else {
      return 'Complete';
    }
  }
  matNumberChange(dataItem: AddNewItemAction) {
    dataItem.isMatValid = true;
    if (!Number(dataItem.materialNumber)) {
      dataItem.isMatValid = false;
    }
  }
  getItems() {
    this.addNewActionService.getAddNewItemActionDetails(this.requiredParams.requestId!,
      this.requiredParams.requestIssueId!).subscribe((response: AddNewItemActionResponseDto) => {
        response.items = response.items.filter(x => x.status !== 'Draft');
        this.requestAddNewActionItemDetails = response
        this.mySelection = [];
        let mmdItems: AddNewItemAction[] = [];
        this.requestAddNewActionItemDetails = response;
        if (this.currentActionName === 'mmd-action') {
          this.isUploadExcelVisible = true;
          for (let item of this.requestAddNewActionItemDetails.items) {
            if (item.status === 'Completed' || item.addNewItemActionDetails.storageLocations!.reduce((sum, current) => sum + current.maximum!, 0) == 0) {
              mmdItems.push(item);
            }
            else {
              if (item.status === 'Pending MMD') {                
                mmdItems.push(item);                
              }
            }
          }
          this.requestAddNewActionItemDetails!.items = mmdItems;
          this.requestAddNewActionItemDetails!.items = this.requestAddNewActionItemDetails.items.
            filter(x => x.status !== 'Rejected' && x.status != 'Cancelled');
        }
        if (this.currentActionName === 'sa-action') {
          this.isUploadExcelVisible = false;
          for (let item of this.requestAddNewActionItemDetails.items) {
            if (item.addNewItemActionDetails.storageLocations!.reduce((sum, current) => sum + current.maximum!, 0) > 0
              && item.status !== 'Pending MMD') {
              mmdItems.push(item);
            }
          }
          this.requestAddNewActionItemDetails!.items = mmdItems;
          this.requestAddNewActionItemDetails!.items = this.requestAddNewActionItemDetails.items.
            filter(x => x.status !== 'Rejected' && x.status != 'Cancelled' && x.status !== 'Completed');
        }
      });
  }

  getConsultItems(){
      const getAllQACodes$ = this.addNewActionService.getAllQACodes();
      const getGroups$ = this.addNewActionService.getGroups();

      forkJoin([getAllQACodes$, getGroups$]).subscribe(([qaCodes, groups]) => {
          this.qaCodes = qaCodes;
          this.groups = groups;
          this.userGroupSelectDisabled = true;
      });
  }

  clickCheckBox(e: SelectionEvent) {
    this.isBulk = true;
    for (let selected of this.mySelection) {
      if (this.requestAddNewActionItemDetails!.items[selected].status === 'Pending MMD' ||
        this.requestAddNewActionItemDetails!.items[selected].status === 'Pending Stocking Approval'
      ) {
        this.isBulk = false;
        break;
      }
    }
  }

  assignEmpty(dataItem: any) {
    dataItem.comment = dataItem.comment == undefined ? '' : dataItem.comment
  }

  editRow(dataItem: AddNewItemAction, isEdit: boolean, index: number) {
    if (isEdit) {
      this.tempDataIndex = index;
      this.tempDataItem = JSON.parse(JSON.stringify(dataItem))
    }
    else {
      this.requestAddNewActionItemDetails.items[this.tempDataIndex] = this.tempDataItem;
    }
    this.requestAddNewActionItemDetails.items[this.tempDataIndex].isRowEdit = isEdit;
  }

  cancel(dataItem: AddNewItemAction) {
    dataItem.isCommentError = false;
    if (this.commonService.isNullOrEmpty(dataItem.comment!)) {
      dataItem.isCommentError = true;
      this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          if (this.currentActionName === 'mmd-action') {
            let approvalActionDetail = this.getmmdValues(dataItem);
            approvalActionDetail.mmdAction = 3;
            this.mmdActionEvent.emit(approvalActionDetail);
          }

          if (this.currentActionName === 'sa-action') {
            let approvalActionDetail = this.getsaValues(dataItem);
            approvalActionDetail.assignedGroupId = null;
            approvalActionDetail.assignedUserId = null;
            approvalActionDetail.stockApproverAction = 3;
            this.saActionEvent.emit(approvalActionDetail);
          }
        }
      });
  }

  consult(dataItem: AddNewItemAction) {
    if (this.commonService.isNullOrEmptyOrUndefined(dataItem.comment)) {
      this.commonService.showNotificationMessage("Please insert the comment to assign it to the consult", "error", "center")
      return;
    }
    this.consultAction.Id = dataItem.id    
    this.consultAction.RequestLineId = dataItem.requestLineId;
    this.consultAction.Status = dataItem.status;
    this.consultAction.Comment = dataItem.comment;
    this.getConsultItems();
    this.consultClicked = true;
  }

  consultActionClick() : void {
    if (this.selectedUserGroup == undefined){
      this.consultErrorDialog = true;
      return;
    }

    this.consultAction.GroupId = this.selectedGroup!.id;
    this.consultAction.UserGroupId = this.selectedUserGroup?.id;

    this.addNewActionService
      .updateOnConsultAction(this.consultAction)
      .subscribe(() => {
        this.consultClicked = false;
      })    
  }

  close(): void {
    this.consultClicked = false;
  }

  closeDialog(): void {
    this.consultClicked = false;
    this.consultBtnDisabled = true;
  }

  reject(dataItem: AddNewItemAction) {
    dataItem.isCommentError = false;
    if (this.commonService.isNullOrEmpty(dataItem.comment!)) {
      dataItem.isCommentError = true;
      this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    this.commonService.showConfirmation('Rejection', ApprovalDetailsConstants.rejectMessage)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          if (this.currentActionName === 'mmd-action') {
            let approvalActionDetail = this.getmmdValues(dataItem);
            approvalActionDetail.mmdAction = 1;
            this.mmdActionEvent.emit(approvalActionDetail);
          }

          if (this.currentActionName === 'sa-action') {
            let approvalActionDetail = this.getsaValues(dataItem);
            approvalActionDetail.assignedGroupId = null;
            approvalActionDetail.assignedUserId = null;
            approvalActionDetail.stockApproverAction = 1;
            this.saActionEvent.emit(approvalActionDetail);
          }
        }
      });
  }

  complete(dataItem: AddNewItemAction, isUpdate: boolean) {

    if (this.currentActionName === 'mmd-action') {
      if (isUpdate === true) {
        let approvalActionDetail = this.getmmdValues(dataItem);
        approvalActionDetail.mmdAction = 2;
        approvalActionDetail.isEditLineItem = true;
        this.mmdActionEvent.emit(approvalActionDetail);
        dataItem.isRowEdit = false;
      }
      else {
        this.commonService.showConfirmation('Complete', ApprovalDetailsConstants.completeMessage)
          .subscribe((res: any) => {
            if (res.action === 'yes') {
              let approvalActionDetail = this.getmmdValues(dataItem);
              approvalActionDetail.mmdAction = 2;
              this.mmdActionEvent.emit(approvalActionDetail);
            }
          });
      }
    }

    if (this.currentActionName === 'sa-action') {
      let approvalActionDetail = this.getsaValues(dataItem);
      const dialog: DialogRef = this.dialogService.open({
        content: ApprovalAssignmentComponent
      });
      dialog.result.subscribe((r: any) => {
        if (r.actionType == 's') {
          approvalActionDetail.assignedGroupId = r.lineItemDtail.assignedGroupId;
          approvalActionDetail.assignedUserId = this.commonService.isNullOrEmptyOrUndefined(
            r.lineItemDtail.assignedUserId) ? null : r.lineItemDtail.assignedUserId;
          approvalActionDetail.stockApproverAction = 2;
          this.saActionEvent.emit(approvalActionDetail);
        }
      });
    }
  }

  getmmdValues(dataItem: AddNewItemAction): ApprovalMMDActionDetail {
    let approvalMMDActionDetail: ApprovalMMDActionDetail = new ApprovalMMDActionDetail()
    approvalMMDActionDetail.comment = dataItem.comment;
    approvalMMDActionDetail.createdBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.enrichedDescription = dataItem.enrichedDescription!;
    approvalMMDActionDetail.materialNumber = dataItem.materialNumber;
    approvalMMDActionDetail.shortDescription = dataItem.shortDescription;
    approvalMMDActionDetail.modifiedBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.requestIssueId = this.requiredParams.requestIssueId!;
    approvalMMDActionDetail.requestIssueTypeId = 3;
    approvalMMDActionDetail.requestLineId = dataItem.requestLineId;
    approvalMMDActionDetail.requestId = this.requiredParams.requestId!;
    return approvalMMDActionDetail;
  }

  getsaValues(dataItem: AddNewItemAction): ApprovalSAActionDetail {
    let approvalMMDActionDetail: ApprovalSAActionDetail = new ApprovalSAActionDetail()
    approvalMMDActionDetail.comment = dataItem.comment;
    approvalMMDActionDetail.createdBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.modifiedBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.requestIssueId = this.requiredParams.requestIssueId!;
    approvalMMDActionDetail.requestIssueTypeId = 3;
    approvalMMDActionDetail.requestLineId = dataItem.requestLineId;
    approvalMMDActionDetail.requestId = this.requiredParams.requestId!;
    return approvalMMDActionDetail;
  }

  public exportToExcel() {
    let finalAddNewItemList: AddNewItemAction[] = [];
    let tempAddNewItem: AddNewItemAction[] = JSON.parse(JSON.stringify(this.requestAddNewActionItemDetails!.items));
    for (let tempDataObj of tempAddNewItem) {
    let tempSLObj = JSON.parse(JSON.stringify(tempDataObj));
      if (tempDataObj.addNewItemActionDetails && tempDataObj.addNewItemActionDetails.storageLocations && tempDataObj.addNewItemActionDetails.storageLocations?.length > 0) {
          tempDataObj.addNewItemActionDetails.storageLocations?.forEach((slValue: any) => {
          tempSLObj.addNewItemActionDetails = JSON.parse(JSON.stringify(tempSLObj.addNewItemActionDetails));
          tempSLObj.addNewItemActionDetails.storageLocations = JSON.parse(JSON.stringify(tempSLObj.addNewItemActionDetails.storageLocations))
          tempSLObj.addNewItemActionDetails.storageLocations = [];
          tempSLObj.addNewItemActionDetails.storageLocations.push(slValue);
          finalAddNewItemList.push(JSON.parse(JSON.stringify(tempSLObj)));
        })
      }
      if (tempDataObj.addNewItemActionDetails && tempDataObj.addNewItemActionDetails.storageLocations!.length <= 0) {
        tempSLObj.addNewItemActionDetails = JSON.parse(JSON.stringify(tempSLObj.addNewItemActionDetails));
        finalAddNewItemList.push(JSON.parse(JSON.stringify(tempSLObj)));
      }
    }
    let convertedArr = [];
    let index = 0;
    for (let itemList of finalAddNewItemList) {
      index = itemList.addNewItemActionDetails.storageLocations!.filter((x: any) => x.lineNumber === itemList.lineNumber).length;
      index = index > 0 ? index : 0;
      let obj = {
        mmsKey: '',
        lineNumber: itemList.lineNumber,
        description: itemList.description,
        manufactureName: itemList.manufactureName,
        manufacturePartNumber: itemList.manufacturePartNumber,
        materialTypeName: itemList.materialTypeName,
        unitOfMeasureName: itemList.unitOfMeasureName,
        materialGroupCodeName: itemList.materialGroupCodeName,
        segmentName: itemList.segmentName,
        grossWeight: itemList.addNewItemActionDetails.grossWeight,
        dimensions: itemList.addNewItemActionDetails.dimensions,
        materialStatusName: itemList.addNewItemActionDetails.materialStatusName,
        storageConditioName: itemList.addNewItemActionDetails.storageConditioName,
        isHazardous: itemList.addNewItemActionDetails.isHazardous === true ? 'Yes' : 'No',
        isCritical: itemList.addNewItemActionDetails.isCritical === true ? 'Yes' : 'No',
        isMRp: itemList.addNewItemActionDetails.isMRp === true ? 'Yes' : 'No',
        unitPrice: itemList.addNewItemActionDetails.unitPrice,
        leadTime: itemList.addNewItemActionDetails.leadTime,
        qaCode: itemList.addNewItemActionDetails.qaCodes!.length > 0 ? itemList.addNewItemActionDetails.qaCodes?.map((x: any) => { return x.qaCode }).toString() : '',
        supplierPurchasing: itemList.addNewItemActionDetails.supplierPurchasing,
        supplierPartNumber: itemList.addNewItemActionDetails.supplierPartNumber,
        plantName: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].plantName : '',
        storageLocatinName: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].storageLocatinName : '',
        minimum: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].minimum : '',
        maximum: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].maximum : '',
        roundingValue: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].roundingValue : '',
        estimatedInventoryValue: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].estimatedInventoryValue : '',
        specialProcurementKey: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].specialProcurementKey : '',
        comment: itemList.comment,
        materialNumber: itemList.materialNumber == null ? '' : itemList.materialNumber, 
        shortDescription: itemList.shortDescription == null ? '' : itemList.shortDescription,
        enrichedDescription: itemList.enrichedDescription == null ? '' : itemList.enrichedDescription,
      }
      convertedArr.push(obj)
    }
    let date = new Date().toISOString();
    date = date.substring(0, date.lastIndexOf(':')).replace('T', '-').replace(':', '-');
    let fileName = this.requiredParams.requestNumber + '-Action-' + this.requiredParams.actionNumber + '-' + 'Item-Export-' + date;
    this.commonService.exportAsExcelFile(convertedArr, this.addItemsExcelColumnGOBU, fileName)
  }

  public import(): void {   
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        this.updateExcelData(r);
      }
    });
  }

  updateExcelData(r: any) { 
    if (r.action === "Save") {
      this.countErrors = 0;
      this.uploadErrors = '';
      let arr = JSON.parse(r.value)['data'] as Array<any>;
      let index = -1;
      for (let item of arr) {
        index++;
        if (!this.commonService.isNullOrEmptyOrUndefined(item['Material Number *'])) {
          if (this.invalidMaterialNumber(item['Material Number *'] ? item['Material Number *'].toString() : '')) {
            this.updateUploadError(index, AddNewItemConstant.excelvalidations.invalidMaterialNumber);
          }
        }
      }
      if (arr.length === 0) {
        this.commonService.showNotificationMessage(AddNewItemConstant.excelvalidations.noRows, 'warning', 'center');
      }
      else {       
        if (this.countErrors !== 0) {
          let errormessage: string = '';
          if (this.countErrors > 8) errormessage = `There are ${this.countErrors} errors in the excel file and the first 8 are:<br/>`;
          else errormessage = (this.countErrors === 1 ? 'There is 1 error' : `There are ${this.countErrors} errors`) + ' in the Excel spreadsheet:<br/>';
          errormessage += this.uploadErrors;
          this.commonService.showNotificationMessage(errormessage, 'error', 'center', 5000);
        }
        else {
          for (let item of this.requestAddNewActionItemDetails!.items) {
            for (let excelItem of arr) {
              if (excelItem['Line Number'] === item.lineNumber) {
                if (!this.commonService.isNullOrEmptyOrUndefined(excelItem['Material Number *']) || !this.commonService.isNullOrEmptyOrUndefined(excelItem['Short Description *']) || !this.commonService.isNullOrEmptyOrUndefined(excelItem['Enriched Description *'])) {
                  item.materialNumber = !this.commonService.isNullOrEmptyOrUndefined(excelItem['Material Number *']) ? excelItem['Material Number *'].toString() : '';
                  item.enrichedDescription = !this.commonService.isNullOrEmptyOrUndefined(excelItem['Enriched Description *']) ? excelItem['Enriched Description *'].toString() : '';
                  item.shortDescription = !this.commonService.isNullOrEmptyOrUndefined(excelItem['Short Description *']) ? excelItem['Short Description *'].toString() : '';                  
                }                
              }
            }
          }
        }
      }
    }
  }

  invalidMaterialNumber(matNum: string) {
    if (this.commonService.isNAN(matNum)) {
      return true;
    }    
    return false;
  }

  updateUploadError(rowIndex: number, message: string) {
    this.countErrors++;
    if (this.countErrors <= 8) {
      this.uploadErrors += `Row ${rowIndex + 2}: ${message}<br/>`
    }
  }

  closeConsultError(){
    this.consultErrorDialog = false;
  }

  public groupSelectionChange(value: GroupDto): void {
    if (value != undefined && value.id != -1) {
      this.selectedGroup = value;
      this.addNewActionService.getUserGroups(value.id).subscribe(
        (result) => {
          this.groupUsers = result;
          this.userGroupSelectDisabled = false;
          this.consultBtnDisabled = false;
        }
      );
    }
  }

  public userGroupSelectionChange(value: UserDto): void {
    if (value != undefined) {
      this.selectedUserGroup = value;      
    }
  }

  public qaCodeSelectionChange(value: QACodeDto): void {
    if (value != undefined) {
      this.selectedQaCode = value;      
    }
  }
}
