
export class Group {
    id: number = 0;
    code: string | undefined;
    name: string | undefined;
    isActive: boolean | undefined;
    createdBy: string | undefined;
    createdDateTime: Date | undefined;
    modifiedBy: string | undefined;
    modifiedDateTime: Date | undefined;
    description: string | undefined;
    businessunitName:string | undefined;
    groupTypeName:string | undefined;
}

