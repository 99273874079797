import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { BussinessunitsComponent } from "../components/bussinessunits/bussinessunits.component";
import { QacodeComponent } from "../components/qacode/qacode.component";
import { SegmentComponent } from "../components/segment/segment.component";
import { SpkComponent } from "../components/spk/spk.component";
import { StorageconditionComponent } from "../components/storagecondition/storagecondition.component";
import { SupplierComponent } from "../components/supplier/supplier.component";
import { UnitOfMeasureComponent } from "../components/unit-of-measure/unit-of-measure.component";
import { ValuationtypeComponent } from "../components/valuationtype/valuationtype.component";
import { KendoModule } from "src/app/modules/kendo.module";
import { SegmentService } from "../services/segment.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SegmentManageComponent } from "../components/segment/segment-manage/segment-manage.component";
import { UnitOfMeasureService } from "../services/unit-of-measure.service";
import { ManageUnitOfMeasureComponent } from "../components/unit-of-measure/manage-unit-of-measure/manage-unit-of-measure.component";
import { MaterialTypeComponent } from "../components/material-type/material-type.component";
import { ManageBusinessUnitComponent } from "../components/bussinessunits/manage-businessunit/manage-bussinessunits.component";
import { ManageSupplierComponent } from "../components/supplier/manage-supplier/manage-supplier.component";
import { ManageValuationTypeComponent } from "../components/valuationtype/manage-valuation-type/manage-valuation-type.component";
import { ManageSpkComponent } from "../components/spk/manage-spk/manage-spk.component";
import { ValuationTypeService } from "../services/valuationtype.service";
import { SPKService } from "../services/spk.service";
import { MaterialGroupCodeComponent } from "../components/material-group-code/material-group-code.component";
import { MaterialStatusComponent } from "../components/material-status/material-status.component";
import { ManageMaterialGroupCodeComponent } from "../components/material-group-code/manage-material-group-code/manage-material-group-code.component";
import { ManageMaterialStatusComponent } from "../components/material-status/manage-material-status/manage-material-status.component";
import { ManageMaterialTypeComponent } from "../components/material-type/manage-material-type/manage-material-type.component";
import { ManageStorageConditionComponent } from "../components/storagecondition/manage-storage-condition/manage-storage-condition.component";
import { ManageQacodeComponent } from "../components/qacode/manage-qacode/manage-qacode.component";
import { SarApplicationGroupComponent } from "../components/sar-application-group/sar-application-group.component";
import { ManageSarApplicationGroupComponent } from "../components/sar-application-group/manage-sar-application-group/manage-sar-application-group.component";
import { MaterialInfoComponent } from "../components/material-info/material-info.component";
import { MaterialDetailsComponent } from "../components/material-info/material-details/material-details.component";
import { MaterialInfoService } from "../services/material-info.service";
import { SarUsersComponent } from "../components/sar-users/sar-users.component";
import { ManageSarUsersComponent } from "../components/sar-users/manage-sar-users/manage-sar-users.component";
import { OndemandMaterialDataComponent } from "../components/ondemand-material-data/ondemand-material-data.component";
import { StockingLocationsComponent } from "../components/stocking-locations/stocking-locations.component";
import { ManageStockingLocationsComponent } from "../components/stocking-locations/manage-stocking-locations/manage-stocking-locations.component";
import { StockingLocationsService } from "../services/stocking-locations.service";

@NgModule({
    declarations: [
        SarApplicationGroupComponent,
        ManageSarApplicationGroupComponent,
        BussinessunitsComponent,
        UnitOfMeasureComponent,
        SegmentComponent,
        SupplierComponent,
        MaterialTypeComponent,
        MaterialGroupCodeComponent,
        MaterialStatusComponent,
        QacodeComponent,
        StorageconditionComponent,
        ValuationtypeComponent,
        SpkComponent,
        SegmentManageComponent,
        ManageUnitOfMeasureComponent,
        ManageBusinessUnitComponent,
        ManageSupplierComponent,
        SegmentManageComponent,
        ManageValuationTypeComponent,
        ManageSpkComponent,
        MaterialTypeComponent,
        ManageMaterialGroupCodeComponent,
        ManageMaterialStatusComponent,
        ManageMaterialTypeComponent,
        ManageStorageConditionComponent,
        ManageQacodeComponent,
        MaterialInfoComponent,
        MaterialDetailsComponent,
        SarUsersComponent,
        ManageSarUsersComponent,
        OndemandMaterialDataComponent,
        StockingLocationsComponent,
        ManageStockingLocationsComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        KendoModule
    ],
    exports: [
        SarApplicationGroupComponent,
        ManageSarApplicationGroupComponent,
        BussinessunitsComponent,
        UnitOfMeasureComponent,
        SegmentComponent,
        SupplierComponent,
        MaterialTypeComponent,
        MaterialStatusComponent,
        MaterialGroupCodeComponent,
        QacodeComponent,
        StorageconditionComponent,
        ValuationtypeComponent,
        SpkComponent,
        ManageBusinessUnitComponent,
        ManageSupplierComponent,
        ManageUnitOfMeasureComponent,
        ManageValuationTypeComponent,
        ManageSpkComponent,
        MaterialTypeComponent,
        ManageMaterialGroupCodeComponent,
        ManageMaterialStatusComponent,
        ManageMaterialTypeComponent,
        ManageStorageConditionComponent,
        ManageQacodeComponent,
        MaterialInfoComponent,
        SarUsersComponent,
        ManageSarUsersComponent,
        OndemandMaterialDataComponent
        
    ],
    providers: [
        SegmentService,
        UnitOfMeasureService,
        ValuationTypeService,
        SPKService,
        MaterialInfoService,
        StockingLocationsService,
    ],

})
export class SetupModule { }
