<!-- Create component -->
<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1><a class="c-left" href=""><span class="fa fa-fw fa-angle-left"></span></a>
            Material {{matNumber}}
        </h1>
    </div>

      <!-- section 1-->
      <section class="grid-container">
        <div class="d-flex grid-list b-bottom">
            <div>
                <label class="f-bold" for="">MaterialId</label>
                {{materialDataDto?.materialDetails?.number}}
               
            </div>
            <div>
                <label class="f-bold" for="">Number</label>
                {{materialDataDto?.materialDetails?.number}}
            </div>
            <div>
                <label class="f-bold" for="">Group</label>
                {{materialDataDto?.materialDetails?.group}}
              
            </div>
            <div>
                <label class="f-bold" for="">Description Full</label>
                {{materialDataDto?.materialDetails?.descriptionFull}}
               
            </div>
            <div>
                <label class="f-bold" for="">Qa Code</label>
                {{materialDataDto?.materialDetails?.qaCode}}
                
            </div>
            <div>
                <label class="f-bold" for="">Base Unit Of Measure</label>
                {{materialDataDto?.materialDetails?.baseUnitOfMeasure}}
                
            </div>
        </div>
        <div class="d-flex grid-list b-bottom">
            <div>
                <label class="f-bold" for="">Po Unit Of Measure</label>
                {{materialDataDto?.materialDetails?.poUnitOfMeasure}}
                
            </div>
            <div>
                <label class="f-bold" for="">Manufacturer Part Number</label>
                {{materialDataDto?.materialDetails?.manufactureERPartNumber}}
               
            </div>
            <div>
                <label class="f-bold" for="">Status</label>
                {{materialDataDto?.materialDetails?.status}}
               
            </div>
            <div>
                <label class="f-bold" for="">Po Text</label>
                {{materialDataDto?.materialDetails?.poText}}
                
            </div>
            <div>
                <label class="f-bold" for="">Mrp Type</label>
                {{materialDataDto?.materialDetails?.mrpType}}
                
            </div>
            <div>
                <label class="f-bold" for="">from Date</label>
                {{materialDataDto?.materialDetails?.fromDate}}
                
            </div>
        </div>
        <div class="d-flex grid-list">
            <div>
                <label class="f-bold" for="">from Time</label>
                {{materialDataDto?.materialDetails?.fromTime}}
                
            </div>
            <div>
                <label class="f-bold" for="">Batch Requirement Indicator</label>
                {{materialDataDto?.materialDetails?.batchrequirementIndicator}}
                
            </div>
            <div>
                <label class="f-bold" for="">Storage Conditions</label>
                {{materialDataDto?.materialDetails?.storageConditions}}
               
            </div>
            <div>
                <label class="f-bold" for="">Industry Std Description</label>
                {{materialDataDto?.materialDetails?.industrySTDDescription}}
            </div>

        </div>
    </section>
    <!-- section 2-->
    <div class="k-row ">
        <div class="k-col mr-5">
            <section class="grid-container fixed-height">
                <h3  id="materialMRP" class="m-0 mb-5">Material MRP</h3>
                <table aria-describedby="materialMRP">
                    <thead>
                        <tr>
                            <th>BUName</th>
                            <th>PlantId</th>
                            <th>Mrp Area</th>
                            <th>Reorder Point</th>
                            <th>Maximum Stock Level</th>
                            <th>MaterialMRPId</th>
                            <th>Materials Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-materialMRP let-i="index" [ngForOf]="materialDataDto?.materialMRPs">
                            <tr>
                                <td>{{materialMRP?.materialMRPId}}</td>
                                <td>{{materialMRP?.buName}}</td>
                                <td>{{materialMRP?.plantId}}</td>
                                <td>{{materialMRP?.mrpArea}}</td>
                                <td>{{materialMRP?.reOrderPoint}}</td>
                                <td>{{materialMRP?.maximumStockLevel}}</td>
                                <td>{{materialMRP?.materialNumber}}</td>
                            </tr>
                          </ng-template>
                       
                    </tbody>
                </table>
            </section>
        </div>
        <div class="k-col ml-5">
            <section class="grid-container fixed-height">
                <h3  id="materialItemNote" class="m-0 mb-5">Material Item Note</h3>
                <table aria-describedby="materialItemNote">
                    <thead>
                        <tr>
                            <th>MaterialItemNoteId</th>
                            <th>Item Notes</th>
                            <th>Material Results</th>
                         
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-materialItemNote let-i="index" [ngForOf]="materialDataDto?.materialItemNotes">
                            <tr>
                                <td>{{materialItemNote?.materialItemNoteId}}</td>
                                <td>{{materialItemNote?.itemNotes}}</td>
                                <td>{{materialItemNote?.materialNumber}}</td>
                            </tr>
                          </ng-template>
                       
                    </tbody>
                </table>
            </section>
        </div>
    </div> 
    <!-- section 3-->
    <section class="grid-container fixed-height">
        <h3  id="materialTax" class="m-0 mb-5">Material Tax</h3>
        <table aria-describedby="materialTax">
            <thead>
                <tr>
                    <th>MaterialTax</th>
                    <th>Material Type</th>
                    <th>Tax Classification</th>
                    <th>Taxable YN 1</th>
                    <th>Materials Result</th> 
                 
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-materialTax let-i="index" [ngForOf]="materialDataDto?.materialTaxs">
                    <tr>
                        <td>{{materialTax?.materialTaxId}}</td>
                        <td>{{materialTax?.materialType}}</td>
                        <td>{{materialTax?.taxClassification}}</td>
                        <td>{{materialTax?.taxableYN1}}</td>
                        <td>{{materialTax?.materialNumber}}</td>
                     </tr>
                  </ng-template>
               
            </tbody>
        </table>
    </section>
    <!-- section 4-->
    <section class="grid-container fixed-height">
        <h3  id="materialPlant" class="m-0 mb-5">Material Plant</h3>
        <table aria-describedby="materialPlant">
            <thead>
                <tr>
                     <th>MaterialPlant Id</th>
                    <th>BUName</th>
                    <th>PlantID</th>
                    <th>Status</th>
                    <th>Storage Location</th>
                    <th>Storage Bin</th>
                    <th>Serial Number</th>
                    <th>Indicator CriticalPart</th>
                    <th>Reorder Point</th>
                    <th>Maximum StockLevel</th>
                    <th>Moving Price</th>
                    <th>Price Unit</th>
                    <th>Status StorgeLoc</th>
                    <th>Lead Time</th>
                    <th>Materials ResultId</th>
                   
                 
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-materialPlant let-i="index" [ngForOf]="materialDataDto?.materialPlants">
                    <tr>
                        <td>{{materialPlant?.materialNumber}}</td>
                        <td>{{materialPlant?.buName}}</td>
                        <td>{{materialPlant?.plantId}}</td>
                        <td>{{materialPlant?.status}}</td>
                        <td>{{materialPlant?.storageLocation}}</td>
                        <td>{{materialPlant?.storageBIN}}</td>
                        <td>{{materialPlant?.serialNumber}}</td>
                        <td>{{materialPlant?.indicatorCriticalPart}}</td>
                        <td>{{materialPlant?.reorderPoint}}</td>
                        <td>{{materialPlant?.maximumDtockLevel}}</td>
                        <td>{{materialPlant?.movingPrice}}</td>
                        <td>{{materialPlant?.priceUnit}}</td>
                        <td>{{materialPlant?.statusStorageLOC}}</td>
                        <td>{{materialPlant?.leadTime}}</td>
                        <td>{{materialPlant?.materialNumber}}</td>
                     </tr>
                  </ng-template>
               
            </tbody>
        </table>
    </section>
</div>