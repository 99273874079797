import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { QACodeService } from '../../../services/qacode.service';
import { QACodeDto } from '../../../models/qacode/qacode.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { QACodeConst } from 'src/app/core/constants/qacode.const';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-manage-qacode',
  templateUrl: './manage-qacode.component.html',
  styleUrls: ['./manage-qacode.component.css']
})
export class ManageQacodeComponent implements OnInit {
  public id: number = 0;
  public isCreate: boolean = false;
  public name: string = '';
  public isActive: boolean = false;
  public headerText: string = QACodeConst.createText;
  public code: string = '';
  hasNameError = false;
  hasCodeError = false;
  hasError = false;

  @ViewChild('buForm') myForm!: NgForm;
  constructor(public router: Router,
    private route: ActivatedRoute,
    private qaCodeService: QACodeService, public commonService: CommonService
  ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.isCreate = stateData['isCreate']
    this.name = stateData['name'];
    this.isActive = stateData['isActive']
    this.code = stateData['code']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.name;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }
  cleareError() {
    this.hasNameError = false;
    this.hasCodeError = false;
    this.hasError = false;
  }

  save() {
    this.cleareError();
    const qaCode = new QACodeDto();
    qaCode.id = this.id;
    qaCode.name = this.name;
    qaCode.isActive = this.isActive;
    qaCode.code = this.code;
    qaCode.createdBy = this.commonService.getUserEmailId();
    qaCode.modifiedBy = this.commonService.getUserEmailId();
    if (!this.name) {
      this.myForm.form.get('fQAName')?.markAllAsTouched();
      this.hasNameError = true;
      this.hasError = true;
    }
    if (!this.code) {
      this.myForm.form.get('fQACode')?.markAllAsTouched();
      this.hasCodeError = true;
      this.hasError = true;
    }
    if (this.hasError) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'right');
      return false;
    }
    if (!this.isCreate) {
      this.qaCodeService.UpdateQA(qaCode).subscribe((response: QACodeDto) => {
        this.router.navigate([QACodeConst.QAPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(QACodeConst.updateMessage, 'success', 'right');
      });
    }
    else {
      this.qaCodeService.saveQA(qaCode).subscribe((response: QACodeDto) => {
        this.router.navigate([QACodeConst.QAPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(QACodeConst.insertMessage, 'success', 'right')
      });
    }
  }

  cancel() {
    this.router.navigate([QACodeConst.QAPath], { relativeTo: this.route.parent });
  }

}
