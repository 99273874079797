
export const SegmentConst = {
    segment: 'api/Segment',
    segmentPath: 'segment',
    manageSegementPath: 'segmentManage',
    searchFieldName: 'name',
    createText:'Create New Segment',
    insertMessage:'Segment has been added successfully',
    updateMessage:'Segment has been updated successfully',
    nameError:'Name is equired',
    bulkInsertMessage:'Segments are successfully importd'
};