export const requestConst = {
    apiName: 'api/Request',
    createSAR: '/CreateSAR',
    updateSAR: '/UpdateSAR',
    getPriorityList: '/GetPriorityList',
    GetSARDetailsById: '/GetSARDetailsById',
    insertMessage: 'created successfully',
    updateMessage: 'updated successfully',
    viewSAR: 'viewsar',
    manageSAR: 'managesar',
    newAction: 'newaction',
    approvalDetail: 'approvalDetail',
    createText: 'Create New Stock Authorization Request',
    editText: 'Edit Stock Authorisation Request',
    createSARAction: 'Create SAR',
    updateSARAction: 'Update SAR',
    searchFieldName: 'SARId',
}