import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialGroupCode } from '../../models/materialgroupcode/materialgroupcode.model';
import { MaterialGroupCodeService } from '../../services/material-group-code.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { MaterialGroupCodeConstant } from 'src/app/core/constants/material-group-code.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-material-group-code',
  templateUrl: './material-group-code.component.html',
  styleUrls: ['./material-group-code.component.css']
})
export class MaterialGroupCodeComponent implements OnInit {
  constructor(private materialGroupCodeService: MaterialGroupCodeService,
    private route: ActivatedRoute,
    public router: Router, private dialogService: DialogService, public commonService: CommonService
  ) { }

  public gridData: MaterialGroupCode[] = [];
  public globalGridData: MaterialGroupCode[] = [];
  public checked: true = true;
  public searchText: string = '';
  public showInactive = false;

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getMaterialGroupCode();
  }

  getMaterialGroupCode() {
    this.materialGroupCodeService.getMaterialGroupCode().subscribe((response: MaterialGroupCode[]) => {
      this.globalGridData = Object.assign([], response);
      this.updateGrid();
    }
    );
  }

  updateGrid() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData, this.showInactive, MaterialGroupCodeConstant.searchFieldName, this.searchText, MaterialGroupCodeConstant.code);
  }

  cancelSearch() {
    this.searchText = '';
    this.updateGrid();
  }

  activeChanged(value: boolean) {
    this.showInactive = value;
    this.updateGrid();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  manage(isCreate: boolean, isActive: boolean, name: string, id: number, code: string) {
    this.router.navigate(["../manageMaterialGroupCode"], {
      state: { 'isCreate': isCreate, 'id': id, 'name': name, 'isActive': isActive, 'code': code },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let mgc: MaterialGroupCode[] = arr.map((item: any) => {
          return {
            id: 0,
            name: item['Material Group Code'].toString(), code: item['UOM Code'].toString(), isActive: item['Is Active'],
            createdBy: item['Created By'], modifiedBy: item['Modified By'],
            createdDateTime: undefined, modifiedDateTime: undefined
          };
        });
        this.materialGroupCodeService.importExcelData(mgc).subscribe((response: MaterialGroupCode) => {
          this.commonService.showNotificationMessage(MaterialGroupCodeConstant.bulkInsertMessage, 'success', 'right')
          this.getMaterialGroupCode();
        });
      }
    });
  }
}

