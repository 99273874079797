<div class="MainPopup">
    <div class="text-right" style="cursor: pointer;"><a (click)="close('Close')"><i class="fa fa-times"></i></a> 
   
    </div>
    <h2> Bulk Action</h2>
    Comments
    <br />
    <kendo-textarea [(ngModel)]="comment" class="mt-5" [rows]="4" [cols]="50" resizable="both"></kendo-textarea>
    <br />
    <label for="">  Character Count: {{comment.length}} </label>
    <span *ngIf="isCommentError" style="color: red;">Please enter Comment</span>
    <br />
    <br />
    <div class="text-center mt-20">
    <button kendoButton class="border-button mr-10 f-size-150 cancelButton" (click)="close('Cancel')"> Cancel
    </button>
    <button kendoButton class="primary-button ml-5 f-size-150 rejectButton" (click)="close('Reject')"> Reject
    </button>
    <button kendoButton class="primary-button ml-10 f-size-150 successButton" [disabled]="isCompleteDisable"
        (click)="close('Complete')">
        {{getButtonText()}}
    </button>
    </div>
    <br />
    <div class="note  mx-5 d-inline-block" *ngIf="isMMD === true && changeType! === 'Add New Item'">
        Note:
        <br />
        Bulk complete will be enabled when mandatory fields of all the selected items are filled.
    </div>
</div>
