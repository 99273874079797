export const auditColumnConfig: any = [{
    field: "sarId",
    title: "SAR ID",
    filterable: true,
    filter: "text",
    width: 120,
    hidden: false,
},
{
    field: "sarType",
    title: "SAR Type",
    filterable: true,
    filter: "text",
    width: 150,
    hidden: false,
},
{
    field: "actionNumber",
    title: "Action number",
    filterable: true,
    filter: "text",
    width: 100,
    hidden: false,
},
{
    field: "priority",
    title: "Priority",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "actionType",
    title: "Action Type",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "auditType",
    title: "Audit Type",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "lineNumber",
    title: "Line number",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "itemNumber",
    title: "Item number",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "asset",
    title: "Asset",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "branch",
    title: "Branch/Plant",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "entityName",
    title: "Entity Name",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "fieldName",
    title: "Field Name",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "oldValue",
    title: "Old Value",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "newValue",
    title: "New Value",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "lineStatus",
    title: "Line Status",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "userName",
    title: "User Name",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "created",
    title: "Created",
    filter: "text",
    width: 120,
    filterable: true,
    hidden: false,
},
{
    field: "sarOwner",
    title: "SAR Owner",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "actionCreatedBy",
    title: "Action Created By",
    filter: "text",
    width: 150,
    filterable: true,
    hidden: false,
},
{
    field: "actionCreatedDate",
    title: "Action Created Date",
    filter: "date",
    format: "{0:d}",
    width: 150,
    filterable: true,
    hidden: false,
}]