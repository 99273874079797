<div class="sar-contentBox">
    <h2 class="heading content-top">SAR Audit Data</h2>
    <section class="audit-component">
      <ng-template #template let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
      </ng-template> 
      <br><br>       
      <kendo-grid
        #grid
        [kendoGridBinding]="gridData"
        [data]="gridSettings.gridData"
        [loading]="loading" 
        [pageable]="true"
        [pageSize]="30"
        [height]="850"
        [filterable]="true"
        [(filter)]="gridSettings.state.filter!"
        [sortable]="sortSettings"
        [sort]="gridSettings.state.sort!"
        [groupable]="true"
        [group]="gridSettings.state.group!"
        (dataStateChange)="dataStateChange($event)"      
        >   
            <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel">
                  Export to Excel
                </button>
                <div class="button-container">
                  <button kendoButton (click)='onSaveConfigClick(grid)'>Save config</button>
                  <button kendoButton (click)='onResetConfigClick()'>Reset config</button>
                  <button kendoButton (click)='onClearFilterClick()'>Clear Filters</button>
                  <button kendoButton (click)='onClearSortingClick()'>Clear Sorting</button>
                </div>
                <button kendoButton *ngIf="savedStateExists" (click)="gridSettings = mapGridSettings(persistingService.get('gridSettings'))">
                  Load saved state
                </button>
            </ng-template>      
            <kendo-grid-column field="sarId" title="SAR ID" [width]="120"> 
              <ng-template kendoGridCellTemplate let-dataItem>
                <a href="javascriipt:void();" (click)="manage(dataItem.id)">{{ dataItem.sarId }}</a>
            </ng-template>
            </kendo-grid-column> 
            <kendo-grid-column field="sarType" title="SAR Type" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="actionNumber" title="Action number" [width]="100"></kendo-grid-column> 
            <kendo-grid-column field="priority" title="Priority" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="actionType" title="Action Type" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="auditType" title="Audit Type" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="lineNumber" title="Line Number" [width]="90"> </kendo-grid-column> 
            <kendo-grid-column field="itemNumber" title="Item Number" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="asset" title="Asset" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="branch" title="Branch/Plant" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="entityName" title="Entity Name" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="fieldName" title="Field Name" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="oldValue" title="Old Value" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="newValue" title="New Value" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="lineStatus" title="Line Status" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="userName" title="User Name" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="created" title="Created" [width]="120"> </kendo-grid-column> 
            <kendo-grid-column field="sarOwner" title="Sar Owner" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="actionCreatedBy" title="Action Created" [width]="150"> </kendo-grid-column> 
            <kendo-grid-column field="actionCreatedDate" title="Action Created Date"  filter="date" [width]="160"> </kendo-grid-column>
            <kendo-grid-excel fileName="AuditData.xlsx" [fetchData]="allData"></kendo-grid-excel>
      </kendo-grid>
    </section>
</div>


