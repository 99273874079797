import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialStatus } from '../../../models/materialstatus/materialstatus.model';
import { MaterialStatusService } from '../../../services/material-status.service';
import { CommonService } from 'src/app/core/services/common.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-manage-material-status',
  templateUrl: './manage-material-status.component.html',
  styleUrls: ['./manage-material-status.component.css']
})
export class ManageMaterialStatusComponent implements OnInit {

  public id: number = 0;
  public isCreate: boolean = false;
  public name: string = '';
  public isActive: boolean = false;
  public headerText: string = "Create New Unit Of Measure"
  public code: string = ''

  constructor(public router: Router,
    private route: ActivatedRoute,
    private materialStatusService: MaterialStatusService,
    public commonService: CommonService
  ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.isCreate = stateData['isCreate']
    this.name = stateData['name'];
    this.isActive = stateData['isActive']
    this.code = stateData['code']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.name;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }

  save() {
    const materialStatus = new MaterialStatus();
    materialStatus.id = this.id;
    materialStatus.name = this.name;
    materialStatus.isActive = this.isActive;
    materialStatus.code = this.code;
    materialStatus.createdBy = this.commonService.getUserEmailId();
    materialStatus.modifiedBy = this.commonService.getUserEmailId();
    if (!this.isCreate) {
      this.materialStatusService.updateMaterialStatus(materialStatus).subscribe((response: MaterialStatus) => {
        this.router.navigate(["materialStatus"], { relativeTo: this.route.parent });
      });
    }
    else {
      this.materialStatusService.saveMaterialStatus(materialStatus).subscribe((response: MaterialStatus) => {
        this.router.navigate(["materialStatus"], { relativeTo: this.route.parent });
      });
    }
  }

  cancel() {
    this.router.navigate(["materialStatus"], { relativeTo: this.route.parent });
  }


}
