<div class="contentBox">
    <h1 class="content-top"><a class="c-left" routerLink="/setup">
        <span class="fa fa-fw fa-angle-left"></span></a>Material Info
    </h1>
    <div class="search mt-20">
        <kendo-autocomplete [data]="materialDtata" class="autocomplete-box ml-20" [virtual]="virtual"
            [filterable]="true" (filterChange)="filter($event)"
            [(ngModel)]="selectedMaterial"  (valueChange)="materialChange($event)" placeholder="Enter atleast 5 characters">
        </kendo-autocomplete>
        <kendo-dropdownlist [valuePrimitive]="true" [data]="plantList"
            [(ngModel)]="selectedPlant" class="dropwdown-box ml-20" [disabled]="!matValidError">
        </kendo-dropdownlist>
        <button kendoButton class="primary-button ml-20" (click)="search()"> Search </button>
        <button kendoButton class="border-button ml-20" (click)="clear()"> Reset </button>
    </div>
    <kendo-grid class="mt-20" [kendoGridBinding]="gridData" #grid="kendoGrid" [pageSize]="20" [resizable]="true"
        [pageable]="true">
        <kendo-grid-column title="Sr.No" [width]="140">
            <ng-template kendoGridCellTemplate let-dataItem let-index="rowIndex">
                {{index + 1}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="materialNumber" title="Number" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="javascriipt:void();"
                    (click)="view(dataItem.materialNumber,dataItem.plant)">{{dataItem.materialNumber}}
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="businessUnit" title="BU Name" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="plant" title="Plant" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="storageLocation" title="Storage Location" [width]="120"></kendo-grid-column>

    </kendo-grid>
</div>