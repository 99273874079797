import { NgModule } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ICON_SETTINGS, IconSettingsService, IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { UploadModule } from '@progress/kendo-angular-upload';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MyIconService } from './my-icon.service';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { PopoverModule, TooltipModule } from '@progress/kendo-angular-tooltip';
@NgModule({
    exports: [
        LayoutModule,
        IndicatorsModule,
        IconsModule,
        NavigationModule,
        InputsModule,
        LabelModule,
        ButtonsModule,
        GridModule,
        PopupModule,
        NotificationModule,
        ExcelModule,
        DropDownsModule,
        DialogsModule,
        UploadModule,
        ProgressBarModule,
        TooltipModule,
        PopoverModule,
        IconsModule
    ],
    providers: [
        { provide: ICON_SETTINGS, useValue: { type: 'font' } },
        { provide: IconSettingsService, useClass: MyIconService },
    ],
})
export class KendoModule { }
