import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ProfileType } from '../../models/graph.models';
import { GlobalAuthService } from '../../services/global-auth.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ConfigRoleComponent } from '../config-role/config-role.component';
import { CommonService } from '../../services/common.service';
import { LoadingService } from '../../services/loading-progress-bar.service';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { Router } from '@angular/router';
import { UserInfo } from '../../constants/userandrole.const';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @ViewChild("anchor", { read: ElementRef }) public anchor: ElementRef | undefined;
  @ViewChild("popup", { read: ElementRef }) public popup: ElementRef | undefined;
  profile !: ProfileType | null;
  setLoginDisplay !: boolean | null;
  testdisplay: string | undefined;
  userRole: string | undefined;
  @Output() loadContent = new EventEmitter<boolean>();
  public data = [
    {
      text: "My Profile",
    },

    {
      text: "Log Out",
    },
  ];

  public value = 0;
  public running!: any;
  public profileShortName = "";
  userBUName: string | undefined;
  public show = false;
  public labelSettings: LabelSettings = {
    visible: false
  };

  public progressStyles: { [key: string]: string } = {
    color: "white",
    background: "#596EE4",
  };

  constructor(private globalAuthService: GlobalAuthService, private dialogService: DialogService,
    public commonService: CommonService, public loadingService: LoadingService, public router: Router) {
    this.loadingService.isLoading$.subscribe((value) => {
      if (value) {
        this.startProgress();
      }
      else {
        this.resetProgress();
      }
    })


  }

  ngOnInit(): void {

    this.globalAuthService.profile.subscribe(profile => {
      this.profile = profile;
      sessionStorage.setItem('userPrincipalName', JSON.stringify(profile?.userPrincipalName));
      sessionStorage.setItem('userId', JSON.stringify(profile?.id));
      if (this.commonService.isNullOrEmptyOrUndefined(this.commonService.getUserRoleName())) {
        this.assignRole();
      }
      else {
        this.userRole = this.commonService.getUserRoleName();
        this.userBUName = this.commonService.getUserBUName();
        this.loadContent.emit(true);
      }
      if (this.profile?.givenName && this.profile?.surname) {
        this.profileShortName = this.profile?.givenName.toUpperCase().toString().charAt(0) +
          this.profile?.surname.toUpperCase().toString().charAt(0);
      }
    }
    );
    this.globalAuthService.loginDisplay.subscribe(setLoginDisplay => this.setLoginDisplay = setLoginDisplay);

  }

  showUserInfo(): void {
    this.router.navigate(["../" + UserInfo.routePath]);
  }

  logout(): void {
    this.globalAuthService.logout();
    this.commonService.clearSession();
  }
  // Nav pop box


  public onToggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
  }

  public assignRole(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: ConfigRoleComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.value.roleName === '') {
        this.loadContent.emit(false);
        return false;
      }
      this.userRole = r.value.roleName;
      this.userBUName = r.value.BUName;
      sessionStorage.setItem('roleName', r.value.roleName)
      sessionStorage.setItem('userBUName', r.value.BUName)
      this.loadContent.emit(true);
      this.reload();
    });
  }
  reload() {
    window.location.reload();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate(['sar'], { relativeTo: this.route });
  }
  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.onToggle(false);
    }
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.onToggle(false);
    }
  }
  private contains(target: EventTarget | null): boolean {
    return (
      this.anchor!.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }


  public startProgress(): void {

    this.running = setInterval(() => {
      if (this.loadingService.isLoading$) {
        this.value++;
        if (this.value == 99) {
          this.value = 0;
        }
      } else {
        this.resetProgress();
      }
    }, 500);
  }

  public stopProgress(): void {
    if (this.running) {
      clearInterval(this.running);
      this.running = null;
    }
  }

  public resetProgress(): void {
    this.value = 0;
    this.stopProgress();
  }
}