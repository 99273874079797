<div class="MainPopup">
  <div style="cursor: pointer;">
    <a (click)="cancel('Close')"> <i class="fa fa-times"></i></a>
    <h2> Bulk Action</h2>
    Comments
    <br />
    <kendo-textarea [(ngModel)]="comment" class="mt-5" [rows]="4" [cols]="100" resizable="both"></kendo-textarea>
    <br />
    Character Count: {{comment.length}}
    <br />
    <br />
    <div style="margin-left: 325px;">
      <button *ngIf="isCancel" kendoButton class="border-button ml-5 f-size-150 cancelButton"
        (click)="cancel('Cancel')">
        Cancel
      </button>
      <button *ngIf="!isCancel" kendoButton class="border-button ml-5 f-size-150 cancelButton"
        (click)="cancel('Delete')">
        Delete
      </button>
    </div>
    <br />
    <!-- <div class="note  mx-5 d-inline-block">
      Note:
      <br />
      Bulk complete will be enabled when mandatory fields of all the selected items are filled.
  </div> -->
  </div>