<div class="popup_add-new-action">
    <div class="text-right">
        <a class="font-size-h5">
            <i class="fa fa-times close-icon" aria-hidden="true" (click)="close('close')"></i>
        </a>
    </div>
    <h3 class="font-size-h3 m-0">Add New Entry</h3>
    <section class="c-display-flex">
        <div class="w-50">
            <div class="mt-10 mb-15">
                <label class="switch ml-10 mr-10">
                    <input type="checkbox" #showInactiveCheck id="showInactiveCheck"
                        (change)="generalComment(showInactiveCheck.checked)">
                    <span class="slider round"></span>
                </label>
                <small>General Comment</small>

                <div class="pop-box" *ngIf="!isGeneralCommnetActive">
                    <div class="d-flex j-c-s ">
                        <p>Select Line Items <span style="color:red">*</span></p>
                        <p class="color-lite-blue" (click)="selectAll(data.itemsList)">Select All</p>
                    </div>
                    <!-- fild add heare -->
                    <kendo-multiselect id="multiSelect-dropdown" #multiselect [data]="data.itemsList"
                        textField="lineNumber" valueField="requestLineId" placeholder="-- Please Select Line Item(s) --"
                        (valueChange)="selectLine($event)" [(ngModel)]="selecteLineItems" (keyup)="searchLine($event)"
                        (open)="onOpen($event)" (close)="onClose($event)" (click)="toggle()">
                    </kendo-multiselect>
                    
                    <i class="fa fa-times close-icon dropdown-add-icon" *ngIf="!selecteLineItems.length"
                        aria-hidden="true"></i>
                </div>

            </div>

            <!-- comment box -->
            <div>
                <kendo-label text="Comment"><span style="color:red">*</span></kendo-label><br>
                <kendo-textarea class="mt-5" [rows]="4" [cols]="100" resizable="both" [(ngModel)]="entryComment"
                    [maxlength]="2000">
                </kendo-textarea><br>
                <kendo-label text="Character count :">{{ 2000 - entryComment.length}}</kendo-label>
            </div>

            <!-- groups and users dropdown -->
            <div class="d-flex" *ngIf="!isGeneralCommnetActive">
                <div class="mt-10 mr-10 w-50" *ngIf="isUserIsAdmin">
                    <kendo-label text="Assigned To group">
                        <kendo-dropdownlist class="mt-5" [data]="listOfGroups" textField="name" valueField="id"
                            (selectionChange)="selectGroup($event)" [valuePrimitive]="true"
                            [defaultItem]="defaultAssignToGroup">
                        </kendo-dropdownlist>
                    </kendo-label>
                </div>
                <div class="mt-10 ml-10 w-50"*ngIf="isUserIsAdmin">
                    <kendo-label text="Assigned To user">
                        <kendo-dropdownlist class="mt-5" [data]="userList" textField="name" valueField="id"
                            (selectionChange)="selectUser($event)" [valuePrimitive]="true"
                            [disabled]="!isGroupSelected">
                        </kendo-dropdownlist>
                    </kendo-label>
                </div>
            </div>
        </div>

        <div class="ColumnSpacer"></div>
        <div class="w-50">
            <kendo-label text="Attachments"></kendo-label><br>
            <div class="fileuploadContainer">
                <p class="text">Drop Files or Click to Upload</p>
                <input class="fileupload" type="file" #fileInput (change)="onFilesUpload($event)"
                    accept="accptedFilesStr" />
            </div>
            <kendo-grid #myGrid *ngIf="uploadedFiles.length" [data]="uploadedFiles" [hideHeader]="true"
                class="list-container" scrollable="none" [style.width.px]="500">
                <kendo-grid-column>
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <button kendoButton (click)="downloadFile(rowIndex)" look="flat" [icon]="'download'"
                            class="download-button"></button>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [width]="400">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{dataItem.name}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column>
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <button kendoButton (click)="removeFile(rowIndex)" look="flat" [icon]="'delete'"
                            class="delete-button"></button>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </section>

    <div class="text-center mt-20">
        <button kendoButton class="border-button ml-5 mr-10 f-size-150 noButton" (click)="close('close')">
            Cancel
        </button>
        <button kendoButton class="primary-button ml-5 f-size-150" (click)="saveEntry()">
            Save New Entry
        </button>
    </div>
</div>