export const AddNewItemExcelColumn = {
    lineNumber: 'Line Number',
    description: 'Description',
    manufactureName: 'Manufacture Name',
    unitOfMeasureName: 'Unit Of Measure Name',
    materialGroupCodeName: 'Material Group CodeName',
    status: 'Status',
    createdBy: 'Created By',
    grossWeight: 'Gross Weight',
    dimensions: 'Dimensions',
    unitPrice: 'Unit Price',
    leadTime: 'Lead Time',
    supplierPartNumber: 'Supplier Part Number',
    isHazardous: 'Hazardous',
    isCritical: 'Critical',
    isMRp: 'MRP',
    plantName: 'Plant Name',
    minimum: 'Minimum',
    maximum: 'Maximum',
    roundingValue: 'Rounding Value',
    estimatedInventoryValue: 'Estimated Inventory Value',
    storageLocatinName: 'Storage Locatin Name',
    specialProcurementKey: 'Special Procurement Key'
}

export const AddNewItemApprovalExcelColumn = {
    manufacturePartNumber: 'Manufacture Part Number',
    materialTypeName: 'Material Type Name',
    segmentName: 'Segment Name',
    materialNumber: 'Material Number',
    shortDescription: 'Short Description',
    enrichedDescription: 'Enriched Description',
    materialStatusName: 'Material StatusN ame',
    storageConditioName: 'Storage Conditio Name',
    supplierPurchasing: 'Supplier Purchasing',
    lineNumber: 'Line Number',
    description: 'Description',
    manufactureName: 'Manufacture Name',
    unitOfMeasureName: 'Unit Of Measure Name',
    materialGroupCodeName: 'Material Group CodeName',
    status: 'Status',
    createdBy: 'Created By',
    grossWeight: 'Gross Weight',
    dimensions: 'Dimensions',
    unitPrice: 'Unit Price',
    leadTime: 'Lead Time',
    supplierPartNumber: 'Supplier Part Number',
    isHazardous: 'Hazardous',
    isCritical: 'Critical',
    isMRp: 'MRP',
    plantName: 'Plant Name',
    minimum: 'Minimum',
    maximum: 'Maximum',
    roundingValue: 'Rounding Value',
    estimatedInventoryValue: 'Estimated Inventory Value',
    storageLocatinName: 'Storage Locatin Name',
    specialProcurementKey: 'Special Procurement Key'
}

export const ExtnMRPUpdateApprovalExcelColumn = {
    lineNumber: 'Line Number',
    materialItemNumber: 'Material Number',
    description: 'Description',
    totalValue: 'Total Value',
    changeInValue: 'Change In Value',
    status: 'Status',
    comment: 'Comment',
    plantName: 'Plant Name',
    specialProcurementKey: 'Special Procurement Key',
    storageLocatinName: 'Storage Locatin Name',
    existingMinimum: 'Existing Minimum',
    existingMaximum: 'Existing Maximum',
    bin: 'BIN',
    valuationType: 'Valuation Type',
    isPlantForDeletion: 'Plant For Deletion',
    isStorageLocationForDeletion: 'Storage Location For Deletion',
    isValuationTypeForDeletion: 'Valuation Type For Deletion',
    unitPrice: 'Unit Price',
    minimum: 'Minimum',
    maximum: 'Maximum',
    estimatedInventoryValue: 'Estimated Inventory Value'
}


export const ChangeDataApprovalExcelColumn = {
    lineNumber: 'Line Number',
    materialNumber: 'Material Number',
    changeType: 'Change Type',
    plantOtherChangeType: 'Plant Other Change Type',
    oldData: 'Old Data',
    newData: 'New Data',
    status: 'Status',
    comment: 'Comment'
}

export const ExtnMRPUpdateExcelColumnGOMBU = {
    mmsKey: 'MMS Key (System Use Only) ',
    materialItemNumber: 'Material Number',
    plantName: 'Plant',
    unitPrice: 'Unit Cost',
    specialProcurementKey: 'SPK',
    isPlantForDeletion: 'Flag Plant for deletion',
    storageLocatinName: 'Storage Locatin',
    isStorageLocationForDeletion: 'Flag Storage Location for deletion',
    minimum: 'ROP (Min)',
    maximum: 'Max',
    bin: 'Bin',
    valuationType: 'Valuation Type',
    isValuationTypeForDeletion: 'Flag Valuation for deletion',
    estimatedInventoryValue: 'Estimated Stock Holding Value',
    comment: 'Requestor Notes ',
}

export const ChangeDataExcelColumns = {
    mmsKey: 'MMS Key (System Use Only)',
    lineNumber: 'Line Number',
    materialItemNumber: 'Material Number*',
    changeType: 'Change Type *',
    plant: 'Plant',
    otherChangeType: 'Other Change Type',
    oldData: 'Old Data',
    newData: 'New Data *',
    requesterNotes: 'Requester Notes',
    description: 'Description'
}

export const AddItemsExcelColumnGOMBU = {
  mmsKey: 'MMS Key (System Use Only) ',
  lineNumber: 'Line Number',
  description: 'Description',
  manufactureName: 'Manufacturer Name',
  manufacturePartNumber: 'Manufacturer Part Number',
  materialTypeName: 'Material Type',
  unitOfMeasureName: 'Unit of Measure *',
  materialGroupCodeName: 'Material Group Code *',
  segmentName: 'Segment *',
  grossWeight:'Gross Weight',
  dimensions: 'Size/Dimensions',
  materialStatusName: 'Plant Specific Material Status',
  storageConditioName: 'Storage Condition',
  isHazardous: 'Hazardous *',
  isCritical: 'Critical Part',
  isMRp: 'MRP *',
  unitPrice: 'Unit Price *',
  leadTime: 'Lead Time (days) * ',
  qaCode: 'QA Code',
  supplierPurchasing: 'Supplier (Purchasing)',
  supplierPartNumber: 'Supplier Part Number',
  plantName: 'Plant - Description',
  storageLocatinName: 'Storage Location',
  minimum: 'ROP (Min)',
  maximum: 'Max',
  roundingValue: 'Rounding Value',
  estimatedInventoryValue: 'Estimated  Inventory Value',
  specialProcurementKey: 'Special Proc. Key',
  comment: 'Requester Notes',
  materialNumber: 'Material Number *',
  shortDescription: 'Short Description *',
  enrichedDescription: 'Enriched Description *'
}

export const StockingLocationsExcelColumns = {
    code: "Branch",
    description: "Branch Description",
    company: "Company",
    location: "Location",
    asset: "Asset",
    assetDescription: "Asset Description",
    assetFunction: "Asset Function",
    isPQ: "Is PQ",
    isNCR: "Is NCR",
    isSAR: "Is SAR",
    assets: "Asset Group CSV"
}
