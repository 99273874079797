<section>
    <div class="mt-20 text-align-right">
        <button kendoButton class="upload-button" [disabled]="changeDataDetails!.items.length === 0" (click)="exportToExcel()"> Download Excel </button>
        <button kendoButton class="primary-bulk-button  ml-5 f-size-150" *ngIf="currentActionName !== 'summary'"
            [disabled]="isBulk || changeDataDetails.items.length === 0" (click)="openPopup()"> Bulk
            Action</button>
    </div>
</section>
<div>
    <section>
        <h3>Item List</h3>
        <!-- Grid -->
        <kendo-grid [data]="changeDataDetails.items" kendoGridSelectBy (selectionChange)="clickCheckBox($event)"
            [(selectedKeys)]="mySelection" #item style="height:auto">
            <kendo-grid-checkbox-column [width]="50">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <input
                        *ngIf="currentActionName !== 'summary' &&
                    (dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed')"
                        type="checkbox" id="checkbox-{{rowIndex}}" [kendoGridSelectionCheckbox]="rowIndex" />
                </ng-template>
                <ng-template kendoGridHeaderTemplate let-dataItem let-rowIndex="rowIndex">
                    <input kendoGridSelectAllCheckbox *ngIf="currentActionName !== 'summary'" type="checkbox"
                        id="headerCheckbox" />
                </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column title="Line No." [width]="100">
                <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
                    {{dataItem.lineNumber}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="materialNumber" title="Material Number" [headerStyle]="{'width': '15%'}"
                [style]="{'width': '15%'}"></kendo-grid-column>
            <kendo-grid-column field="changeType" title="Change Type" [headerStyle]="{'width': '15%'}"
                [style]="{'width': '15%'}"></kendo-grid-column>
            <kendo-grid-column field="plantOtherChangeType" title="Plant/Other Change Type"
                [headerStyle]="{'width': '15%'}" [style]="{'width': '15%'}"></kendo-grid-column>
            <kendo-grid-column field="oldData" title="Old Data" [headerStyle]="{'width': '20%'}"
                [style]="{'width': '20%'}"></kendo-grid-column>
            <kendo-grid-column field="newData" title="New Data" [headerStyle]="{'width': '20%'}"
                [style]="{'width': '20%'}"></kendo-grid-column>
            <kendo-grid-column title="Action/Status" [width]="350">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div
                        *ngIf="currentActionName !== 'summary' &&
                     (dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed')">
                        <span style="display: none;">{{assignEmpty(dataItem)}}</span>
                        <kendo-textarea class="mt-5" placeholder="Add Comment" [(ngModel)]="dataItem.comment" [rows]="4"
                            [cols]="100" resizable="both"></kendo-textarea>
                        <br />
                        <span *ngIf="dataItem.isCommentError" style="color: red;">Please enter Comment</span>
                        <br />
                        <button kendoButton class="border-button ml-5 f-size-150 cancelButton"
                            (click)="cancel(dataItem)"> Cancel
                        </button>
                        <button kendoButton class="primary-button ml-5 f-size-150 rejectButton"
                            (click)="reject(dataItem)"> Reject
                        </button>
                        <button kendoButton class="primary-button ml-5 f-size-150 successButton"
                            (click)="complete(dataItem)">
                            Complete
                        </button>
                    </div>
                    <div
                        *ngIf="dataItem.status ==='Cancelled' || dataItem.status ==='Rejected' || dataItem.status ==='Completed'
                    || (currentActionName === 'summary' && (dataItem.status === 'Pending Stocking Approval' || dataItem.status ==='Pending MMD') )">

                        <div *ngIf="dataItem.status==='Completed'" class="d-flex a-center status-box" [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
                        dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' :'' ">
                            <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                                {{ dataItem?.status }}
                            </p>
                        </div>

                        <div *ngIf="dataItem.status==='Cancelled'" class="d-flex a-center status-box" [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
                        dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' :'' ">
                            <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                                {{ dataItem?.status }}
                            </p>
                        </div>
                        <div *ngIf="dataItem.status==='Rejected'" class="d-flex a-center status-box" [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
                        dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' : '' ">
                            <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                                {{ dataItem?.status }}
                            </p>
                        </div>
                        <div *ngIf="dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed'"
                            class="d-flex a-center status-box">
                            <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                                {{ dataItem?.status }}
                            </p>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </section>
</div>