import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupConstant } from 'src/app/core/constants/group.constants';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Group } from '../models/group/group.model';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends HttpAbstractService<Group> {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
      super(http);
  }
  getGroup() {
      return this.getAll(GroupConstant.Group);
  }
  saveGroup(data: Group) {
      return this.post(GroupConstant.Group, data);
  }
  updateGroup(data: Group) {
      return this.put(GroupConstant.Group, data.id, data);
  }

}
