import { Component, OnInit } from '@angular/core';
import { MaterialInfoService } from '../../services/material-info.service';
import { SyncMaterialDataOnDemandDto } from '../../models/materialinfo/ondemandmaterialinfo.model';
import { MaterialInfoConst } from 'src/app/core/constants/material-info.const';
import { CommonService } from 'src/app/core/services/common.service';
import { MaterialDataDto } from '../../models/materialinfo/materialData.model';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ondemand-material-data',
  templateUrl: './ondemand-material-data.component.html',
  styleUrls: ['./ondemand-material-data.component.scss']
})
export class OndemandMaterialDataComponent implements OnInit {
  materialNumber = '';
  plantName = '';
  materialDataDto: MaterialDataDto = {
    materialDetails: undefined,
    materialPlants: [],
    materialMRPs: [],
    materialItemNotes: [],
    materialTaxs: []
  };


  public buName: any;
  show: boolean = false;
  isMaterialNumber: boolean = false;
  isPlantName: boolean = false;
  constructor(private materialInfoService: MaterialInfoService, private route: ActivatedRoute,
    public router: Router,

    public commonService: CommonService) {
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.materialNumber = '';
    this.plantName = '';
  }
  search() {
    if (!this.materialNumber || !this.plantName) {
      this.commonService.showNotificationMessage(MaterialInfoConst.onDemandRequiredErrorMessage, 'error', 'right');
      return false;
    }
    let syncMaterialDataOnDemandDto: SyncMaterialDataOnDemandDto = {
      id: 1,
      materialNumber: this.materialNumber,
      plantName: this.plantName,
      status: 'NotStarted',
      isActive: true,
      createdBy: this.commonService.getUserEmailId(),
      modifiedBy: this.commonService.getUserEmailId()
    }
    this.materialInfoService.postOnDemandMaterialInfo(syncMaterialDataOnDemandDto).subscribe(() => {
      this.commonService.showNotificationMessage(MaterialInfoConst.onDemandSuccessMessage, 'success', 'right');
    });
  }
  getmaterialData() {

    if (this.materialNumber == "") {
      this.isMaterialNumber = true;
    } else {
      this.isMaterialNumber = false;
    }

    if (this.plantName == "") {
      this.isPlantName = true;
    } else {
      this.isPlantName = false;
    }

    if (!this.isPlantName && !this.isMaterialNumber) {
      this.materialInfoService.getMaterialDataOnDemand<MaterialDataDto>(this.plantName, this.materialNumber).subscribe((outputDtata: MaterialDataDto) => {
        this.materialDataDto = outputDtata;
        if (this.materialDataDto.materialDetails?.number) {
          this.show = true;
        } else {

          this.show = false;
          this.commonService.showNotificationMessage(MaterialInfoConst.materialDataNotAvailable, 'info', 'center');
          return false;
        }
      }
      );
    }
  }
}
