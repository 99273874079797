import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HeaderComponent } from '../core/components/header/header.component';
import { HttpUtilityService } from '../core/services/http.utility.service';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '../core/Interceptors/msal.interceptor';
import { GlobalAuthService } from '../core/services/global-auth.service';
import { GraphService } from '../core/services/graph.service';
import { KendoModule } from './kendo.module';
import { CommonService } from '../core/services/common.service';
import { DialogUploadComponent } from '../core/components/dialog-upload/dialog-upload.component';
import { ConfigRoleComponent } from '../core/components/config-role/config-role.component';
import { FormsModule } from '@angular/forms';
import { LoadingService } from '../core/services/loading-progress-bar.service';
import { HttpConfigInterceptor } from '../core/Interceptors/httpconfig.interceptor';
import { BulkActionComponent } from '../core/components/bulk-action/bulk-action.component';
import { SarConfirmationBoxComponent } from '../core/components/sar-confirmation-box/sar-confirmation-box.component';

@NgModule({
    declarations: [
        HeaderComponent,
        DialogUploadComponent,
        ConfigRoleComponent,
        BulkActionComponent,
        SarConfirmationBoxComponent
    ],
    imports: [
        MsalModule,
        KendoModule,
        FormsModule
    ],
    exports: [
        HeaderComponent,
        DialogUploadComponent,
        ConfigRoleComponent,
        BulkActionComponent,
        SarConfirmationBoxComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        GraphService,
        GlobalAuthService,
        HttpUtilityService,
        CommonService,
        LoadingService,
    ]
})
export class CoreModule { }
