import { AfterViewInit, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AuditDataDto } from '../../models/audit.models';
import { GridComponent, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { take } from 'rxjs';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SortDescriptor, State, process } from "@progress/kendo-data-query";
import { ActivatedRoute, Router } from '@angular/router';
import { requestConst } from 'src/app/core/constants/request.const';
import { AuditService } from '../../services/audit-dashboard-service';
import { GridSettings } from '../../services/grid-settings-service';
import { StatePersistingService } from '../../services/state-persisting-service';
import { ColumnSettings } from '../../services/column-settings-service';
import { auditColumnConfig } from '../../services/column-config-service';

@Component({
  selector: 'app-audit-dashboard',
  templateUrl: './audit-dashboard.component.html',
  styleUrls: ['./audit-dashboard.component.scss']
})
export class AuditDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild(GridComponent)
  public grid: GridComponent;

  public filter: any = { logic: 'and', filters: [] };
  public sort: SortDescriptor[] = [{ field: 'sarId', dir: 'asc' }];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  public loading = false;
  public savedStateExists = false;
  public totalRecords = 0;
  public gridData: AuditDataDto[] = [];
  public initialGridSettings:  GridSettings = {
    state: {   
      filter: {
        logic: "and",
        filters: [],
      },
      group: [],
    },
    gridData: process(this.gridData, {
      filter: {
        logic: "and",
        filters: [],
      },
      group: [],
      sort: [],
    }),
    columnsConfig: auditColumnConfig
  };

  public gridSettings = this.initialGridSettings;
 
  constructor(
      private ngZone: NgZone,
      private router: Router,
      private route: ActivatedRoute,
      private service: AuditService,
      public persistingService: StatePersistingService)  {
    this.allData = this.allData.bind(this);
    const gridSettings: GridSettings =
      this.persistingService.get("gridSettings");

    if (gridSettings !== null) {
      this.gridSettings = this.mapGridSettings(gridSettings);
    }
  }

  ngOnInit(): void {
    this.loadData();
  }

  public ngAfterViewInit(): void {
    this.fitColumns();
  }

  public onDataStateChange(): void {
    this.fitColumns();
  }

  public onSaveConfigClick(grid: GridComponent) {
    const columns = grid.columns;

    const gridConfig = {
      state: this.gridSettings.state,
      gridData: this.gridSettings.gridData,
      columnsConfig: columns.toArray().map((item: any) => {
        return <ColumnSettings>{
          field: item["field"],
          width: item["width"],
          title: item["title"],
          filter: item["filter"],
          format: item["format"],
          filterable: item["filterable"],
          orderIndex: item["orderIndex"],
          hidden: item["hidden"],
        };
      }),
    };
    this.savedStateExists = true;
    this.persistingService.set("gridSettings", gridConfig);
  }

  public onResetConfigClick() {
    this.savedStateExists = false;
    this.gridSettings = this.initialGridSettings;
    this.onClearFilterClick();
    this.onClearSortingClick();
    this.gridSettings.state.group = [];
  }

  public onClearFilterClick() {
    this.gridSettings.state.filter = { logic: 'and', filters: [] };
  }

  public onClearSortingClick() {
    this.gridSettings.state.sort = [];
  }

  loadData() : void {
    this.loading = true;
    this.service.getAuditData()
      .subscribe(result => {
        this.gridData = result
        this.loading = false;
      });
  }

  manage(requestId: number) {
    const path = `false/false/${requestId}`;
    this.router.navigate([`../sar/${requestConst.viewSAR}/${path}`], {
      state: { isManage: false, isCreate: false, requestId: requestId }, relativeTo: this.route
    });
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }
  
  public dataStateChange(state: State): void {
    this.gridSettings.state = state;
    this.gridSettings.gridData = process(this.gridData, state);
  }

  public mapGridSettings(gridSettings: GridSettings): GridSettings {
    const state = gridSettings.state;
    this.mapDateFilter(state.filter);

    return {
      state,
      columnsConfig: gridSettings.columnsConfig.sort(
        (a: any, b: any) => a.orderIndex - b.orderIndex
      ),
      gridData: process(this.gridData, state),
    };
  }

  private mapDateFilter = (descriptor: any) => {
    const filters = descriptor.filters || [];

    filters.forEach((filter: any) => {
      if (filter.filters) {
        this.mapDateFilter(filter);
      } else if (filter.field === "FirstOrderedOn" && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  };

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: [{ field: "sarId", dir: "asc" }],
      }).data,
    };

    return result;
  }

}