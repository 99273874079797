<!-- Create / Update setup pages -->
<div  class="contentBox">
<h2 class="heading content-top">{{headerText}}</h2>
<section class="w-100">
    <form #supplierForm="ngForm">
        
        <div class="mt-10">
            <kendo-label text="Supplier Number"></kendo-label>
            <br>
            <kendo-textbox class="mt-5 w-25" name="fSuppliernumber" [required]="hasSupplierNumberError" [(ngModel)]="supplierNumber"
                [clearButton]="true"></kendo-textbox>
            <div *ngIf="hasSupplierNumberError">
                <kendo-formerror>Please enter a Supplier number</kendo-formerror>
            </div>
        </div>
        <div class="mt-10">
            <kendo-label text="Supplier Name"></kendo-label>
            <br>
            <kendo-textbox class="mt-5 w-25" name="fSupplierName" [required]="'required'" [(ngModel)]="supplierName"
                [clearButton]="true"></kendo-textbox>
            <div *ngIf="hasSupplierNameError">
                <kendo-formerror>Please enter a Supplier name</kendo-formerror>
            </div>
        </div>
        <div class="mt-10">
            <kendo-label text="Is Active"></kendo-label>
            <br>
            <input class="checkbox" type="checkbox" #isActiveCheck id="isActiveCheck" [checked]="isActive"
                (change)="activeChanged(isActiveCheck.checked)" />
        </div>
        <div class="text-right">
            <kendo-button class="border-button mr-20" (click)="cancel()">Cancel</kendo-button>
            <kendo-button class="primary-button" (click)="save()">Save</kendo-button>
        </div>
    </form>
</section>
</div>