
    <div class="MainPopup">
    <div class="popup-close">
        <h2 class="m-0"><i class="fa fa-times" (click)="cancel()" aria-hidden="true"></i></h2>
    </div>
    
        <div class="mb-20">
            <input name="wtInputFilename1" (change)="upload($event)" type="file" #wtInputFilename1 size="20">
        </div>
        <div>
            <input type="submit" name="wt7" (click)="Save()" value="Upload" id="wt7" class="Button upload Is_Default">&nbsp;
            <input type="submit" name="wt11" (click)="cancel()" value="Cancel" id="wt11" class="Button cancel">
        </div>
    </div>
