import { Component, OnInit } from '@angular/core';
import { MaterialInfoService } from '../../services/material-info.service';
import { MaterialStatus } from '../../models/materialstatus/materialstatus.model';
import { Observable } from 'rxjs';
import { MaterialInfoSearchResultDto } from '../../models/materialinfo/materialinfo.model';
import { MaterialInfoConst } from 'src/app/core/constants/material-info.const';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-material-info',
  templateUrl: './material-info.component.html',
  styleUrls: ['./material-info.component.scss']
})
export class MaterialInfoComponent implements OnInit {

  materialList = [];
  plantList = [];
  selectedMaterial = '';
  public virtual: any = {
    itemHeight: 50,
  };
  materialDtata = [];
  gridData: MaterialInfoSearchResultDto[] = [];
  selectedPlant = MaterialInfoConst.selectPlant;
  matValidError: boolean = false;
  constructor(private materialInfoService: MaterialInfoService, public router: Router,
    private route: ActivatedRoute, public commonService: CommonService) { }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getMaterialList();
    // this.getMaterialPlantList();
  }


  getMaterialList() {
    this.materialInfoService.getAllMaterialNumbers<[]>().subscribe((outputDtata: []) => {
      this.materialList = outputDtata;
      this.materialDtata = outputDtata;
    }
    );
  }

  getMaterialPlantList() {
    this.materialInfoService.getAllMaterialPlants<[]>().subscribe((outputDtata: []) => {
      this.plantList = outputDtata;
    });
  }
  getAllPlantsByMaterialNumberList(matNumber: string) {
    this.materialInfoService.getAllPlantsByMaterialNumber<[]>(matNumber).subscribe((outputDtata: []) => {
      this.plantList = outputDtata;
    });
  }


  filter(value: any) {
    this.materialDtata = this.materialList.filter((s: string) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  search() {
    let plant = '';
    if (this.selectedPlant !== MaterialInfoConst.selectPlant) {
      plant = this.selectedPlant;
    }

    this.materialInfoService.searchMaterialInfo<MaterialInfoSearchResultDto[]>(this.selectedMaterial, plant).subscribe((outputDtata: MaterialInfoSearchResultDto[]) => {
      this.gridData = outputDtata;
    }
    );
  }

  view(number: string, plant: string) {
    this.router.navigate(["../" + MaterialInfoConst.viewDetails], {
      state: { matNumber: number, plant: plant, },
      relativeTo: this.route
    });
  }

  clear() {
    this.gridData = [];
    this.selectedMaterial = '';
    this.selectedPlant = MaterialInfoConst.selectPlant;
  }
  materialChange(value: any) {

    this.matValidError = false;
    this.selectedPlant = MaterialInfoConst.selectPlant;
    let indexOfSelectedMaterial = this.materialDtata.findIndex(x => x === value)
    if (indexOfSelectedMaterial === -1) {
      this.commonService.showNotificationMessage("Plese select the valid number", 'error', 'right')
      return false;
    }
    if (!this.commonService.isNullOrEmptyOrUndefined(value)) {
      this.getAllPlantsByMaterialNumberList(value);
      this.matValidError = true;
    } 

  }
}
