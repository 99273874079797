export const ExtensionMRPUpdateConst = {
    requestLineAPI: 'api/RequestLine',
    requestAPI: 'api/Request',
    matInfoApi: 'api/MaterialInfo',
    requestIssueAPI: 'api/RequestIssue',
    getExtnMRPUpdateActionItemDetailAPI: '/GetExtnMRPUpdateActionItemDetails',
    getExtnMRPUpdateActionItemDetailEditAPI: '/GetExtnMRPUpdateAction',
    getPlantUnitPrice: '/GetPlantUnitPrice',
    getExistingROPMinAndMaxValue: '/GetExistingROPMinAndMaxValue',
    getPlantDeactivationDetails:'/GetPlantDeactivationDetails',
    getStorageLocationDeactivationDetails:'/GetStorageLocationDeactivationDetails',
    PlantDetails: '/GetPlantDetails',
    getMaterialDescription: '/GetMaterialDescription',
    addNewItemAPIpath: '/CreateExtensionMRPUpdateAction',
    updateNewItemAPIpath: '/UpdateExtensionMRPUpdateAction',
    importExtensionMRPUpdateAction: '/ImportExtensionMRPUpdateAction',
    updateLineAssignment: '/UpdateLineAssignment',
    successMessage: 'Request line succesfully created',
    errorMessage: 'Please review the highlighted fields before proceeding',
    updateSuccessMessage: 'Request line succesfully updated',
    globalErrorMessage: 'Please review the highlighted fields before proceeding',
    storageLocationErrorMessage: 'Please provide storage location before proceeding',
    ExtensionMRPUpdatesErrorMessage: 'Same plants with different deletion flags have been selected. Remove the deletion flag or keep only the plant with the deletion flag.',
    excelvalidations: {
        noRows: 'No rows were found in the Excel spreadsheet',
        invalidMaterialNumber: 'Invalid Material Number',
        duplicateMaterialNumber: 'Material number is already exist. Please add required request line id in MMS Key column by this (1234-0) format to proceed',
        plantValidation: 'Invalid plant selected',
        storageLocationValidation: 'Invalid storage location selected',
        minimumValidation: 'Invalid Minimum value',
        maximumValidation: 'Invalid Maximum value',
        maximumValueGreaterValidation: 'Maximum value should be greater than minimum value',
        spkForSJVBUValidation: 'SPK column is only applicable for SJVBU',
        unitPriceValidation: 'Incorrect unit price found',
        successfulUploadMessage: 'Row added/updated successfully',
        plantAndStorageLocationDuplication: 'Duplicate Plant and Storage Location were found in the different line of Excel spreadsheet',
        mmsKeyNotPresent: 'Please provide valid MMS key'
    }
}
