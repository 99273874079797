export const SharedConst = {
    empty: '',
    isActive: 'isActive',
    validationMessage: 'Please review the highlighted fields before proceeding',
    fileRequired: 'File is required',
    fileFormat: 'Please select the valid file format',
    importApiName: '/Import',
    dateFormatWithTime: 'dd-MMM-yyyy hh:mm a',
    dateFormat: 'dd-MMM-yyyy',
    deleteLineItem: 'Request line(s) are succesfully deleted',
    cancelLineItem: 'Request line(s) are succesfully cancelled',
    notAuthorizedActionMessage: 'You are not authorized to edit this request action, Please contact admin to elevate your permissions'
};