export const MaterialInfoConst = {
    apiName: 'api/MaterialInfo',
    getAllMaterialNumbers: '/GetAllMaterialNumbers',
    getAllMaterialPlants: '/GetAllMaterialPlants',
    getAllPlantsByMaterialNumber: '/GetAllPlantsByMaterialNumber',
    getMaterialInfo: '/GetMaterialInfo',
    searchMaterialInformation: '/SearchMaterialInformation',
    viewDetails: 'materialDetails',
    selectPlant: '--Select Plant--',

    // On Demand Material Info Config
    onDemandApiName: 'api/SyncMaterialDataOnDemand',
    onDemandSuccessMessage: 'Data synced successfully ',
    dataAsPerMaterialNoPlant: 'dataaspermaterialnoplant',
    onDemandRequiredErrorMessage: 'Plant and Material Number field cannot be empty',
    getMaterialDataOnDemand : '/GetMaterialDataOnDemand',
    materialDataNotAvailable: 'Material data not available for selected  material number and Plant ',
    syncMaterialData: '/SyncMaterialData',
    isValidMaterialNumber:'/IsValidMaterialNumber',
};
