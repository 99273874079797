import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { LoadingService } from '../services/loading-progress-bar.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    private totalRequests = 0;
    constructor(public commonService: CommonService, private loadingService: LoadingService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.totalRequests++;
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        this.loadingService.setLoading(true);
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data: any = {};
                data = {
                    message: error && error?.message
                };
                if (!this.commonService.isNullOrEmptyOrUndefined(error?.error?.Message)) {
                    return throwError(() => new Error(error?.error?.Message));
                }
                if (!error?.message.toString().includes('already')) {
                    this.commonService.showNotificationMessage(data.message, 'error', 'center');
                }
                return throwError(() => new Error(error?.message));
            }),
            finalize(() => {               
                this.totalRequests--;
                if (this.totalRequests === 0) {
                    this.loadingService.setLoading(false);
                }
            }));;
    }
}