<!-- Create component -->
<div class="sar-contentBox">
    <form [formGroup]="addNewItemForm">
        <div class="content-top d-flex j-c-s">
            <h1> <a class="c-left" (click)="navBack()"> <span class="fa fa-fw fa-angle-left"></span></a>
                <span class="in-active"> Stock Authorization Request</span>
                <span class="in-active">
                    {{newActionInputParam.requestNumber}}</span>
                <span class="active" *ngIf="newActionInputParam.isNew">
                    <i class="fa fa-fw fa-angle-right"></i> New
                    Item</span>

                <span class="active" *ngIf="!newActionInputParam.isNew"><i class="fa fa-fw fa-angle-right"></i>
                    Item {{lineItem}}
                </span>
            </h1>
            <div>
                <button kendoButton class="border-button ml-20 f-size-150" (click)="navBack()"> Cancel </button>
                <button kendoButton class="primary-button ml-20 f-size-150" (click)="add()">{{newActionInputParam.isNew
                    ? 'Add' : 'Update'}}</button>
            </div>
        </div>

        <section class="w-75 d-flex flex">
            <div class="w-75 mx-5">
                <div class="d-flex j-c-s">
                    <kendo-label text="Description"><span style="color:red">*</span></kendo-label>
                    <kendo-label>Characters Left:{{descMaxlength - descCharachtersCount}}</kendo-label>
                </div>
                <kendo-textarea [rows]="3" formControlName="description" resizable="none" [maxlength]="descMaxlength"
                    (valueChange)="descValueChange($event)" [required]="hasDescriptionError"
                    placeholder="Material specifications, vendor/catalog/quotation descriptions, equipment model, etc.">
                </kendo-textarea>
                <div *ngIf="hasDescriptionError">
                    <kendo-formerror>Please enter a vendor description</kendo-formerror>
                </div>

            </div>
        </section>
        <!--  section 1 -->
        <section class="w-75 d-flex ">
            <div class="mt-10 mx-5 w-25">
                <kendo-label text="Manufacturer"></kendo-label><br>
                <kendo-textbox formControlName="manufacturerName" class="mt-5"></kendo-textbox>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Manufacturer Part Number"></kendo-label><br>
                <kendo-textbox formControlName="manufacturerPartNumber" class="mt-5"></kendo-textbox>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Material Type"><span style="color:red">*</span></kendo-label><br>
                <kendo-dropdownlist name="fMaterialType" class="mt-5" [required]="hasMaterialTypeError"
                    formControlName="materialType" [data]="materialTypeData" [defaultItem]="{ displayName: '-'}"
                    textField="displayName" valueField="id" (selectionChange)="selectMaterialType($event)"
                    [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasMaterialTypeError">
                    <kendo-formerror>Please select Material Type</kendo-formerror>
                </div>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Unit of Measure"><span style="color:red">*</span></kendo-label><br>
                <kendo-dropdownlist name="fuom" [required]="hasUOMError" class="mt-5" formControlName="unitOfMeasure"
                    [data]="uomData" [defaultItem]="{ displayName: '-'}" textField="displayName" valueField="id"
                    (selectionChange)="selectUOM($event)" [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasUOMError">
                    <kendo-formerror>Please select Unit Of Measure</kendo-formerror>
                </div>
            </div>
        </section>
        <!--  section 2 -->
        <section class="w-75 d-flex j-c-start">
            <div class="mt-10 mx-5 w-25">
                <kendo-label text="Material Group Code"><span style="color:red">*</span></kendo-label><br>
                <kendo-dropdownlist name="fmaterialGroupCode" [required]="hasMaterialGroupCodeError"
                    formControlName="materialGroupCode" class="mt-5" [data]="materialGroupCodeData"
                    [defaultItem]="{ displayName: '-'}" textField="displayName" valueField="id"
                    (selectionChange)="selectMaterialGroupCode($event)" [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasMaterialGroupCodeError">
                    <kendo-formerror>Please select Material Group Code</kendo-formerror>
                </div>
            </div>

            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Segment"><span style="color:red">*</span></kendo-label><br>
                <kendo-dropdownlist name="fsegment" class="mt-5" [required]="hasSegmentError" formControlName="segment"
                    [data]="segmentData" [defaultItem]="{ name: '-'}" textField="name" valueField="id"
                    (selectionChange)="selectSegment($event)" [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasSegmentError">
                    <kendo-formerror>Please select Segment</kendo-formerror>
                </div>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Gross Weight(LB)"></kendo-label><br>
                <kendo-numerictextbox formControlName="grossWeight" class="mt-5"></kendo-numerictextbox>
            </div>
            <div class="mt-10 mx-5  w-25"></div>
        </section>
        <!--  section 3 -->
        <section class="w-75 d-flex">
            <div class="mt-10 mx-5 w-25">
                <kendo-label text="Size/Dimensions"></kendo-label><br>
                <kendo-textbox formControlName="dimensions" class="mt-5"></kendo-textbox>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Plant Specific Material Status"></kendo-label>
                <span kendoTooltip tooltipClass="tooltip-design" id="plantMaterial"
                    title="Plant Specific Material Status i.e. Criticality"
                    class="fa fa-question-circle primary-color title-tool float"></span>
                <br>
                <kendo-dropdownlist name="fMaterialStatus" class="mt-5" [required]="hasMaterialStatusError"
                    formControlName="materialStatus" [data]="materialStatusData" [defaultItem]="{ displayName: '-'}"
                    textField="displayName" valueField="id" (selectionChange)="selectMaterialStatus($event)"
                    [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasMaterialStatusError">
                    <kendo-formerror>Please select Material Status</kendo-formerror>
                </div>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Storage Condition"></kendo-label><br>
                <kendo-dropdownlist name="fStorageCondition" class="mt-5" [required]="hasStorageConditionError"
                    formControlName="storageCondition" [data]="storageConditionData" [defaultItem]="{ displayName: '-'}"
                    textField="displayName" valueField="id" (selectionChange)="selectStorageCondition($event)"
                    [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasStorageConditionError">
                    <kendo-formerror>Please select Storage Condition</kendo-formerror>
                </div>
            </div>
            <div class="mt-10 mx-5 w-25">
                <kendo-label text="Equipment Criticality"></kendo-label><br>
                <kendo-textbox formControlName="equipmentCriticality" class="mt-5" [disabled]="true"></kendo-textbox>
            </div>
            <div class="mt-10 mx-5 w-25">
                <kendo-label text="Item Part Ranking"></kendo-label><br>
                <kendo-textbox formControlName="itemPartRanking" class="mt-5" [disabled]="true"></kendo-textbox>
            </div>
        </section>
        <!--  section 4 -->

        <section class="w-75 d-flex mb-20">
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Hazardous"></kendo-label><br>
                <input formControlName="isHazardous" type="radio" [value]=true>
                <kendo-label text="Yes"></kendo-label>
                <input type="radio" formControlName="isHazardous" [value]=false>
                <kendo-label text="No"></kendo-label>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Critical Part"></kendo-label><br>
                <input formControlName="isCritical" type="radio" [value]=true>
                <kendo-label text="Yes"></kendo-label>
                <input type="radio" formControlName="isCritical" [value]=false>
                <kendo-label text="No"></kendo-label>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="MRP"><span style="color:red">*</span></kendo-label><br>
                <input formControlName="isMRp" type="radio" [value]=true [required]="hasMRPError">
                <kendo-label text="Yes"></kendo-label>
                <input type="radio" [value]=false formControlName="isMRp">
                <kendo-label text="No"></kendo-label>
                <div *ngIf="hasMRPError">
                    <kendo-formerror>Please select if MRP is required</kendo-formerror>
                </div>
            </div>
            <div class="mt-10 mx-5  w-25"></div>
        </section>
        <!--  section 5 -->
        <section class="w-75 d-flex">
            <div class="mt-10 mx-5 w-25">
                <kendo-label text="Unit Price"><span style="color:red">*</span></kendo-label><br>
                <kendo-numerictextbox formControlName="unitPrice" class="mt-5" [required]="hasUnitPriceError"
                    (valueChange)="updateEstimatedValue()"></kendo-numerictextbox>
                <div *ngIf="hasUnitPriceError">
                    <kendo-formerror>Please enter positive Unit Cost</kendo-formerror>
                </div>
            </div>
            <div class="mt-10 mx-5  w-25">
                <kendo-label text="Lead Time (Days)"><span style="color:red">*</span></kendo-label><br>
                <kendo-numerictextbox formControlName="leadTime" class="mt-5"
                    [required]="hasLeadTimeError"></kendo-numerictextbox>
                <div *ngIf="hasLeadTimeError">
                    <kendo-formerror>Please enter Lead Time</kendo-formerror>
                </div>
            </div>

            <div class="mt-10 mx-5  w-50">
                <kendo-label text="QA Codes"></kendo-label><br>
                <kendo-multiselect [filterable]="true" #multiselect id="multiselect" formControlName="qaCode"
                    [data]="qaCodeData" textField="displayName" valueField="id" (removeTag)="removeQACode($event)"
                    (valueChange)="selectQACode($event)" (open)="onOpen($event)" (close)="onClose($event)"
                    (click)="toggle()" class="mt-5">
                    <ng-template kendoMultiSelectItemTemplate let-dataItem>
                        <span class="">
                            <button class="autoCompleteItem" [disabled]="dataItem.status">
                                {{ dataItem.displayName }}
                            </button>
                        </span>
                    </ng-template>

                </kendo-multiselect>
            </div>
        </section>
        <div class="separator mt-10 mb-20"></div>

        <!--  section 6 -->
        <section class="w-75 d-flex">
            <div class="mt-10 mx-5 w-50">
                <kendo-label text="Supplier (Purchasing)"></kendo-label><br>

                <kendo-autocomplete [data]="supplierData" #supplierAutocomplete [virtual]="virtual" [filterable]="true"
                    [(ngModel)]="supplierBinding" [ngModelOptions]="{standalone: true}" textField="displayName"
                    valueField="displayName" (filterChange)="filter($event)" (valueChange)="supplierChange($event)"
                    (focus)="focus()" (blur)="blur()" [disabled]="!(isMCBU || isRBU || isSJVBU)"
                    placeholder="Type or click for list" class="mt-5" [class.disabled]="!(isMCBU || isRBU || isSJVBU)">
                </kendo-autocomplete>
            </div>
            <div class="mt-10 mx-5 w-50">
                <kendo-label text="Supplier Part Number"></kendo-label><br>
                <kendo-textbox formControlName="supplierPartNumber" class="mt-5"
                    [class.disabled]="!(isMCBU || isRBU || isSJVBU)"></kendo-textbox>
            </div>
        </section>
        <section class="w-75 d-flex">
            <div class="w-75 mx-5">
                <div class="d-flex j-c-s mt-10 mb-5">
                    <kendo-label text="Tag numbers"></kendo-label>
                </div>
                <kendo-textarea formControlName="equipmentTags" [rows]="5" resizable="none" [disabled]="true">
                </kendo-textarea>
            </div>
        </section>
        <div class="note  mx-5 d-inline-block">
            Note: If items are required to be set up to specific plants and storage locations, with specific stocking
            levels, fill in the table below.
        </div>

        <!--  section grid-->

        <div class="grid-box">
            <div>
                <div *ngFor="let item of requestItems; let rowIndex = index">
                    <div>
                        <kendo-grid [data]="gridData" [resizable]="true" [hideHeader]="rowIndex !== 0">

                            <kendo-grid-column title="Plant">
                                <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                    <kendo-dropdownlist [valuePrimitive]="true" [virtual]="virtual" [data]="plantList"
                                        (valueChange)="plantChange(item!.plantName,rowIndex)"
                                        [(ngModel)]="item.plantName" [ngModelOptions]="{standalone: true}"
                                        class="dropwdown-box" textField="displayName" [valuePrimitive]="true"
                                        [defaultItem]="defaultPlantItem" valueField="plantName">
                                        <ng-template kendoDropDownListItemTemplate let-dataItem>
                                            <div class="template">
                                                <p class="word-wrap">{{ dataItem.plantName }}
                                                    <br />
                                                    <small> -{{dataItem.plantDiscription}}</small>
                                                </p>
                                            </div>
                                        </ng-template>
                                    </kendo-dropdownlist>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column title="Storage Location">
                                <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                    <kendo-dropdownlist
                                        [disabled]="(item.plantName === '' || item.plantName === undefined)"
                                        [(ngModel)]="item.storageLocatinName" [ngModelOptions]="{standalone: true}"
                                        class="grid-input" kendoGridFocusable textField="displayName"
                                        (valueChange)="storageLocationChange($event,rowIndex)"
                                        valueField="storageLocation" [defaultItem]="defaultSLItem"
                                        [data]="tempArray[rowIndex]" [valuePrimitive]="true"></kendo-dropdownlist>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column title="ROP(Min)">
                                <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                    <kendo-numerictextbox [(ngModel)]="item.minimum"
                                        [disabled]="item.plantName === '' || item.plantName === undefined"
                                        [ngModelOptions]="{standalone: true}" class="grid-input" [min]="0"
                                        (blur)="item.minimum = updateValue(item.minimum)"
                                        [spinners]="false"></kendo-numerictextbox>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column title="Max">
                                <ng-template class="status-column" kendoGridHeaderTemplate let-column
                                    let-columnIndex="columnIndex">
                                    Max
                                    <span kendoTooltip tooltipClass="tooltip-design" id="max"
                                        title="Setting the MIN = 0 and MAX = 1 will mean when we use one, we will go and order another.
                                        A setting of MIN = 1 and MAX = 4 will mean when our stock level falls to 0, we will order 4 more to restore our max stock level."
                                        class="fa fa-question-circle primary-color title-tool ml-5"></span>

                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                    <kendo-numerictextbox
                                        [disabled]="item.plantName === '' || item.plantName === undefined"
                                        [(ngModel)]="item.maximum" [ngModelOptions]="{standalone: true}"
                                        class="grid-input" [min]="0" (blur)="item.maximum = updateValue(item.maximum)"
                                        (valueChange)="calculateEstimatedValue(item.maximum,item.roundingValue, item)"
                                        [spinners]="false"></kendo-numerictextbox>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column title="Rounding Value">
                                <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                    <kendo-numerictextbox
                                        [disabled]="item.plantName === '' || item.plantName === undefined"
                                        [(ngModel)]="item.roundingValue" [ngModelOptions]="{standalone: true}"
                                        class="grid-input" [min]="0"
                                        (blur)="item.roundingValue = updateValue(item.roundingValue)"
                                        (valueChange)="calculateEstimatedValue(item.maximum,item.roundingValue, item)"
                                        [spinners]="false"></kendo-numerictextbox>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column title="Estimated Inventory Value">
                                <ng-template class="status-column" kendoGridHeaderTemplate let-column
                                    let-columnIndex="columnIndex">
                                    <span>
                                        Estimated Inventory Value
                                        <span kendoTooltip tooltipClass="tooltip-design" id="estimatedValue"
                                            title="(Maximum + Rounding Value)x Quoted Price"
                                            class="fa fa-question-circle primary-color title-tool"></span></span>

                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                    <small>USD ${{item.estimatedInventoryValue}}</small>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column title="Special Procurement Key">
                                <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                    <kendo-dropdownlist [disabled]="!this.isSJVBU || !item.plantName" class="grid-input"
                                        [defaultItem]="defaultSPKItem" [(ngModel)]="item.spkId"
                                        [ngModelOptions]="{standalone: true}" (valueChange)="SPKChange($event,rowIndex)"
                                        [data]="SPKList" [valuePrimitive]="true" textField="name" kendoGridFocusable
                                        valueField="id"></kendo-dropdownlist>

                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column title="">
                                <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                    <div class="icons">
                                        <div *ngIf="item.plantName && rowIndex === (requestItems.length-1)"
                                            (click)="addNewRow()" class="k-icon k-i-plus">
                                        </div>
                                        <div *ngIf="requestItems.length > 0 && item.plantName"
                                            (click)="removeRow(rowIndex)" class="k-icon k-i-delete"></div>
                                    </div>


                                </ng-template>
                            </kendo-grid-column>

                        </kendo-grid>
                        <div>
                            <span
                                *ngIf="requestItems[rowIndex]?.isPlantEmptyError === true && requestItems[rowIndex]?.isMinMaxError === false && requestItems[rowIndex]?.isDuplicateError === true"
                                class="noValue">
                                Plant details cannot be empty</span>

                            <span
                                *ngIf="requestItems[rowIndex]?.isPlantEmptyError === true && requestItems[rowIndex]?.isDuplicateError === false && requestItems[rowIndex]?.isMinMaxError === true"
                                class="noValue">
                                Plant details cannot be empty / Max stock level must be greater than or equal to the ROP
                                (Min)</span>

                            <span
                                *ngIf="requestItems[rowIndex]?.isDuplicateError === true && requestItems[rowIndex]?.isPlantEmptyError === false && requestItems[rowIndex]?.isMinMaxError === false"
                                class="noValue">
                                Duplicate Plant and Storage location found</span>

                            <span
                                *ngIf="requestItems[rowIndex]?.isDuplicateError === true && requestItems[rowIndex]?.isPlantEmptyError === false && requestItems[rowIndex]?.isMinMaxError === true"
                                class="noValue">
                                Duplicate Plant and Storage location found / Max stock level must be greater than or
                                equal to the ROP (Min)</span>

                            <span
                                *ngIf="requestItems[rowIndex]?.isMinMaxError === true && requestItems[rowIndex]?.isDuplicateError === false && requestItems[rowIndex]?.isPlantEmptyError === false"
                                class="noValue">
                                Max stock level must be greater than or equal to the ROP (Min)</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="w-75 d-flex flex">
            <div class="d-flex">
                <input type="checkbox" [checked]="locationChecked" #showLocationInfo id="showLocationInfo"
                    (change)="showLocationDialog(showLocationInfo.checked)">
                <p>Need to add Stocking locations</p>
            </div>
        </section>

        <kendo-dialog *ngIf="locationDialogShow" (close)="closeLocationDialog()">
            <h1>Before suggesting a stock level for this item please consider the following</h1>
            <img src="./../assets/images/stocking-location.png" alt="">
            <kendo-dialog-actions [layout]="locationDialogActionsLayout">
                <button kendoButton (click)="closeLocationDialog()">Cancel</button>
                <button kendoButton (click)="proceedLocationInfo()" class="primary-button">Proceed</button>
            </kendo-dialog-actions>
        </kendo-dialog>

        <section class="w-100 d-flex flex" *ngIf="showLocationSection">
            <div>
                <div class="mb-5 w-25">
                    <kendo-label text="Recommended Stocking Locations"></kendo-label><br>
                    <kendo-multiselect [data]="recommendedStockingLocations" textField="code" valueField="code"
                        (removeTag)="removeLocation($event.dataItem)" (valueChange)="selectLocation($event)" formControlName="stockingLocations"
                        placeholder="-- Please Select At Least One Branch --">
                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                            <span>
                                <button class="autoCompleteItem">
                                    {{ dataItem.code }}
                                </button>
                            </span>
                        </ng-template>
                    </kendo-multiselect>
                </div>
                <div class="w-100 mb-5">
                    <kendo-grid class="kendo-grid" [data]="locationGridData">
                        <kendo-grid-column field="code" title="Branch Plant Code" [width]="150"></kendo-grid-column>
                        <kendo-grid-column field="description" title="Description" [width]="150"></kendo-grid-column>
                        <kendo-grid-column field="min" title="Minimum" [width]="150">
                            <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                <kendo-numerictextbox [(ngModel)]="dataItem.min" [ngModelOptions]="{standalone: true}"
                                    class="grid-input" [min]="0" [spinners]="false"></kendo-numerictextbox>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="max" [width]="150">
                            <ng-template class="status-column" kendoGridHeaderTemplate let-column
                                let-columnIndex="columnIndex">
                                Maximum
                                <span kendoTooltip tooltipClass="tooltip-design" id="max"
                                    title="Setting the MIN = 0 and MAX = 1 will mean when we use one, we will go and order another.
                                A setting of MIN = 1 and MAX = 4 will mean when our stock level falls to 0, we will order 4 more to restore our max stock level."
                                    class="fa fa-question-circle primary-color title-tool ml-5"></span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                <kendo-numerictextbox class="grid-input" [min]="0" [(ngModel)]="dataItem.max"
                                    [ngModelOptions]="{standalone: true}"
                                    (blur)="dataItem.max = updateValue(dataItem.max)"
                                    [spinners]="false"></kendo-numerictextbox>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="stockHolding" [width]="150">
                            <ng-template class="status-column" kendoGridHeaderTemplate let-column
                                let-columnIndex="columnIndex">
                                Estimated Stock Holding Value
                                <span kendoTooltip tooltipClass="tooltip-design" id="max" title="Maximum x Quoted Price"
                                    class="fa fa-question-circle primary-color title-tool ml-5"></span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                <small>AUD ${{calculateStockHolding(dataItem)}}</small>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </section>

        <!--  section 7 -->
        <section class="w-75 d-flex flex">
            <div class="w-75 mx-5">
                <br>
                <div class="d-flex j-c-s">
                    <kendo-label text="Requestor Notes :"></kendo-label>
                    <kendo-label>Characters Left: {{reqNotesMaxLength - reqNotesCharachtersCount}}</kendo-label>
                </div>
                <kendo-textarea [rows]="3" [maxlength]="reqNotesMaxLength" [cols]="200" resizable="both"
                    (valueChange)="reqNotesValueChange($event)" formControlName="requestorNotes">
                </kendo-textarea>

            </div>
        </section>
    </form>
</div>