import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { KendoModule } from "src/app/modules/kendo.module";
import { AuditDashboardComponent } from "../components/audit-dashboard/audit-dashboard.component";
import { StatePersistingService } from "../services/state-persisting-service";

@NgModule({
    declarations: [        
        AuditDashboardComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        HttpClientModule,
        KendoModule,
    ],
    exports: [
        AuditDashboardComponent
    ],
    providers: [
        StatePersistingService,
    ],
})
export class AuditModule { }