import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { UserGuideComponent } from './features/userguide/components/user-guide/user-guide.component';
import { InvalidPermissionsComponent } from './core/components/invalid-permissions/invalid-permissions.component';
import { UserInfoComponent } from './core/components/user-info/user-info.component';

const routes: Routes = [
  {
    path: 'sar',
    loadChildren: () => import('./features/sar/modules/sar-routing.module').then(m => m.SARRoutingModule),
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'allsar',
    loadChildren: () => import('./features/allsar/modules/allsar-routing.module').then(m => m.AllSARRoutingModule),
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'sar-allocation',
    loadChildren: () => import('./features/sar-allocation/modules/sar-allocation-routing.module').then(m => m.SarAllocationRoutingModule),
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'audit',
    loadChildren: () => import('./features/audit/modules/audit-routing.module').then(m => m.AuditRoutingModule),
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'setup',
    loadChildren: () => import('./features/setup/modules/setup-routing.module').then(m => m.SetupModule),
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'userguide',
    component:  UserGuideComponent 

  },
  {
    path: 'userInfo',
    component:  UserInfoComponent 

  },
  {
    path: 'invalidPermissions',
    component:  InvalidPermissionsComponent 

  },
  {
    path: '',
    redirectTo: 'sar',
    pathMatch: 'full'    
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
