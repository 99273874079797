<div class="MainPopup">
    <div class="text-right" style="cursor: pointer;"><a (click)="close('no')"><b>X</b></a> <i class="fa fa-close"></i>
    </div>
    <h2> <b>Confirmation</b></h2>
    <br />
    <br />
    <div *ngIf="plantNames.length > 0">
        The following selected plant/s are flagged for deletion in SAP for the selected Material Number:
        <br />
        <ul *ngFor="let item of plantNames">
            <li>{{item.plantName}} - {{item.plantDiscription}}</li>
        </ul>
    </div>
    <br />
    <div *ngIf="slNames.length > 0">
        The following selected storage location/s are flagged for deletion in SAP for the selected Material Number:
        <br />
        <ul *ngFor="let item of slNames">
            <li>{{item.slName}} - {{item.slDescription}}</li>
        </ul>
    </div>
    <br />
    <div *ngIf="slNames.length > 0 && plantNames.length === 0"> Do you wish to re-activate the above storage loation/s?
    </div>
    <br />
    <div *ngIf="plantNames.length > 0 && slNames.length === 0"> Do you wish to re-activate the above Plant/s?</div>
    <br />
    <div *ngIf="slNames.length > 0 && plantNames.length > 0"> Do you wish to re-activate the above Plant/s? and storage
        loation/s?</div>

    <br />
    <div class="mt-10 mx-5 w-100">
        <button kendoButton class="primary-button ml-5 f-size-150 noButton" (click)="close('no')"> No
        </button>
        <button kendoButton class="primary-button ml-5 f-size-150" (click)="close('yes')">
            Yes
        </button>
    </div>
</div>