<!-- header start -->
<div kendoDialogContainer></div>
<kendo-appbar positionMode="fixed" class="app-head">

  <kendo-progressbar *ngIf="running !== null" [label]="labelSettings"  [progressCssStyle]="progressStyles"
    [value]="value"></kendo-progressbar>
  <kendo-appbar-section>
    <div class="logo">
      <img src="./../assets/icons/CVX_Logo_Corp_Flat_Rev_RGB.png" alt="">
    </div>
    <h1 class="title"> Stock Authorization Request (SAR)</h1>
  </kendo-appbar-section>

  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-appbar-section class="actions">
    <kendo-button #anchor (click)="onToggle()" class="teamMate mr-20">
      <div class="profile">
        <p>{{profileShortName}}</p>
      </div>
      <span class="mate-info">
        <h2>{{profile?.givenName}} {{profile?.surname}}<span class="ml-5 k-icon k-i-arrow-60-down"></span></h2>
      </span>
    </kendo-button>
  </kendo-appbar-section>

</kendo-appbar>

<!-- <h1>Stock Authorization Request</h1>
  <div>
    <p><strong>First Name: </strong> {{profile?.givenName}}</p>
    <p><strong>Last Name: </strong> {{profile?.surname}}</p>
    <p><strong>Email: </strong> {{profile?.userPrincipalName}}</p>
    <p><strong>Id: </strong> {{profile?.id}}</p>
</div>
      <button class="btn btn-sm text-white" (click)="logout()"> LogOut </button>   -->
<!-- <kendo-button #anchor (click)="onToggle()" class="check"><h2>{{profile?.givenName}} {{profile?.surname}}<span class="k-icon k-i-arrow-60-down"></span></h2></kendo-button> -->
<kendo-popup class="wrapper" [anchor]="anchor.element" (anchorViewportLeave)="show = false" *ngIf="show">
  <div class="content">
    <div><a>Business Unit: {{userBUName}}</a></div>
    <hr class="separator" />
    <div><a>Role: {{userRole}}</a></div>
    <hr class="separator" />
    <div class="header-action" (click)="showUserInfo()"><a>My Profile</a></div>
    <hr class="separator" />
    <div class="header-action" (click)="logout()"><a>log out</a></div>
  </div>
</kendo-popup>