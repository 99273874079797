
export const BussinesUnitConst = {
    BUApiName:'api/BusinessUnit',
    BUPath: 'BU',
    manageBUPath: 'manageBU',
    createText:'Create New Business Unit',
    insertMessage:'BU has been added successfully',
    updateMessage:'BU has been updated successfully',
    bulkInsertMessage:'Business units are successfully importd',
    nameError:'Name is required',
    searchFieldName: 'name',
    shortname:'shortName'
    
  };