import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SegmentConst } from 'src/app/core/constants/segment.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { SegmentDto } from '../../models/segment/segment.model';
import { SegmentService } from '../../services/segment.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent implements OnInit {
  gridData: SegmentDto[] = [];
  globalGridData: SegmentDto[] = [];
  searchText: string = SharedConst.empty;
  isActive = false;

  constructor(private segmentService: SegmentService, public router: Router,
    private route: ActivatedRoute, public commonService: CommonService, private dialogService: DialogService) {
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getSegments();
  }

  getSegments() {
    this.segmentService.getsegments().subscribe((outputDtata: SegmentDto[]) => {
      this.gridData = outputDtata;
      this.globalGridData = Object.assign([], outputDtata);
      this.search();
    });
  }


  search() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData,
      this.isActive, SegmentConst.searchFieldName, this.searchText)
  }

  clear() {
    this.searchText = SharedConst.empty;
    this.gridData = Object.assign([], this.globalGridData);
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  manage(isCreate: boolean, isActive: boolean, name: string, segmentId: number) {
    this.router.navigate(["../" + SegmentConst.manageSegementPath], {
      state: { isCreate: isCreate, segmentId: segmentId, name: name, isActive: isActive },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let segment: SegmentDto[] = arr.map((item: any) => {
          return {
            id: 0,
            name: item['Name'].toString(), isActive: item['Is Active'],
            createdBy: item['Created By'], modifiedBy: item['Modified By'],
            createdDateTime: undefined, modifiedDateTime: undefined
          };
        });
        this.segmentService.importExcelData(segment).subscribe((response: SegmentDto) => {
          this.commonService.showNotificationMessage(SegmentConst.bulkInsertMessage, 'success', 'right')
          this.getSegments();
        });
      }
    });
  }
}
