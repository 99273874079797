import { Component, OnInit } from '@angular/core';
import { StockingLocation } from '../../models/stockinglocation/stockinglocation.model';
import { StockingLocationsService } from '../../services/stocking-locations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { StockingLocationsExcelColumns } from 'src/app/core/constants/excel-columns-const';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { StockingLocationConstant, StockingLocationOperationConst } from 'src/app/core/constants/stocking-location.constant';

@Component({
  selector: 'app-stocking-locations',
  templateUrl: './stocking-locations.component.html',
  styleUrls: ['./stocking-locations.component.scss']
})
export class StockingLocationsComponent implements OnInit {

  gridData: StockingLocation[] = [];
  gridDataSource: StockingLocation[] = [];
  stockingLocationsExcelColumns: any = StockingLocationsExcelColumns;
  searchText: string = "";

  constructor(
    private stockingLocationsService: StockingLocationsService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.stockingLocationsService.getStockingLocations().subscribe(data => {
      this.gridData = data;
      this.gridDataSource = this.gridData;
      this.search();
    });
  }

  manage(isCreate: boolean, id: number, data: StockingLocation | null) {
    this.router.navigate(["../" + StockingLocationConstant.ManageStockingLocationsPath], {
      state: { 
        isCreate: isCreate, 
        id: id,
        data: data},
      relativeTo: this.route
    },
    );
  }

  export() {
    const date = new Date().toLocaleDateString();
    this.commonService.exportAsExcelFile(JSON.parse(JSON.stringify(this.gridData)), this.stockingLocationsExcelColumns, `${date}_stockingLocations`);
  }

  clear() {
    this.searchText = "";
    this.gridData = this.gridDataSource;
  }

  search() {
    this.gridData = this.gridDataSource.filter(element => this.filter(element));
  }

  filter(item: StockingLocation): boolean {
    if (item.code && item.code.includes(this.searchText)
      || item.description && item.description.includes(this.searchText)
      || item.company && item.company.includes(this.searchText)
      || item.location && item.location.includes(this.searchText)
      || item.asset && item.asset.includes(this.searchText)
      || item.assetDescription && item.assetDescription.includes(this.searchText)
      || item.assetFunction && item.assetFunction.includes(this.searchText)
      || item.assets && item.assets.includes(this.searchText))
      return true;
    else
      return false;
  }

  import() {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        const arr = JSON.parse(r.value).data as Array<any>;
        let stockingLocations: StockingLocation[] = arr.map((item: any) => {
           return this.mapStockingLocationFromExcel(item);
          });

        const stockingUpsert = this.prepareStockingLocationsImport(stockingLocations);

        this.stockingLocationsService.upsertStockingLocation(stockingUpsert).subscribe(() => {
          this.commonService.showNotificationMessage(StockingLocationConstant.importMessage, 'success', 'right');
          this.loadData();
        });
      }
    });
  }

  prepareStockingLocationsImport(stockingLocations: StockingLocation[]): StockingLocation[] {
    let stockingUpsert: StockingLocation[] = [];

    stockingLocations.forEach(loc => {
      if (this.gridDataSource.some(element => element.code === loc.code)) {
        loc.id = this.gridDataSource.find(data => data.code === loc.code)?.id ?? 0;
        loc.operation = StockingLocationOperationConst.update;
        loc.createdBy = this.gridDataSource.find(data => data.code === loc.code)?.createdBy;
        loc.modifiedBy = "Import";
        loc.createdDateTime = this.gridDataSource.find(data => data.code === loc.code)?.createdDateTime;
        loc.modifiedDateTime = new Date();
      } else {
        loc.operation = StockingLocationOperationConst.create;
        loc.createdBy = "Import";
        loc.modifiedBy = "";
        loc.createdDateTime = new Date();
        loc.modifiedDateTime = undefined;
      }
      loc.createdDateTime = new Date();
      stockingUpsert.push(loc);
    });

    return stockingUpsert;
  }

  mapStockingLocationFromExcel(item: any): StockingLocation {
    var stockingLocation: StockingLocation = {
      id: 0,
      code: item['Branch'].toString(),
      description: item['Branch Description'].toString(),
      company: item['Company'].toString(),
      location: item['Location'].toString(),
      asset: item['Asset'].toString(),
      assetDescription: item['Asset Description'].toString(),
      assetFunction: item['Asset Function'].toString(),
      isPQ: item["Is PQ"],
      isNCR: item["Is NCR"],
      isSAR: item["Is SAR"],
      isStockingLocation: item["Is Stocking Location"],
      assets: item["Asset Group CSV"].toString(),
      isActive: true
    };
    return stockingLocation;
  }
}
