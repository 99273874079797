import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaterialGroupCodeConstant } from 'src/app/core/constants/material-group-code.constants';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { MaterialGroupCode } from '../models/materialgroupcode/materialgroupcode.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
  providedIn: 'root'
})
export class MaterialGroupCodeService extends HttpAbstractService<MaterialGroupCode> {

  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    super(http);
  }

  getMaterialGroupCode() {
    return this.getAll(MaterialGroupCodeConstant.materialGroupCode);
  }

  saveMaterialGroupCode(data: MaterialGroupCode) {
    return this.post(MaterialGroupCodeConstant.materialGroupCode, data);
  }

  updateMaterialGroupCode(data: MaterialGroupCode) {
    return this.put(MaterialGroupCodeConstant.materialGroupCode, data.id, data);
  }
  importExcelData(data: MaterialGroupCode[]) {
    return this.postAll(MaterialGroupCodeConstant.materialGroupCode + SharedConst.importApiName, data);
  }
}
