import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageConditionConst } from 'src/app/core/constants/storagecondition.const';
import { CommonService } from 'src/app/core/services/common.service';
import { StorageCondtionService } from '../../../services/storagecondition.service';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { StorageConditionDto } from '../../../models/storagecondition/storagecondition.model';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-manage-storage-condition',
  templateUrl: './manage-storage-condition.component.html',
  styleUrls: ['./manage-storage-condition.component.css']
})
export class ManageStorageConditionComponent implements OnInit {
  public id: number = 0;
  public isCreate: boolean = false;
  public name: string = '';
  public isActive: boolean = false;
  public headerText: string = StorageConditionConst.createText;
  public code: string = '';
  hasNameError = false;
  hasCodeError = false;
  hasError = false;

  @ViewChild('buForm') myForm!: NgForm;
  constructor(public router: Router,
    private route: ActivatedRoute,
    private storageCondtionService: StorageCondtionService, public commonService: CommonService
  ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.isCreate = stateData['isCreate']
    this.name = stateData['name'];
    this.isActive = stateData['isActive']
    this.code = stateData['code']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.name;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }
  cleareError() {
    this.hasNameError = false;
    this.hasCodeError = false;
    this.hasError = false;
  }

  save() {
    this.cleareError();
    const sc = new StorageConditionDto();
    sc.id = this.id;
    sc.name = this.name;
    sc.isActive = this.isActive;
    sc.code = this.code;
    sc.createdBy = this.commonService.getUserEmailId();
    sc.modifiedBy = this.commonService.getUserEmailId();
    if (!this.name) {
      this.myForm.form.get('fName')?.markAllAsTouched();
      this.hasNameError = true;
      this.hasError = true;
    }
    if (!this.code) {
      this.myForm.form.get('fSPKCode')?.markAllAsTouched();
      this.hasCodeError = true;
      this.hasError = true;
    }
    if (this.hasError) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'right');
      return false;
    }
    if (!this.isCreate) {
      this.storageCondtionService.UpdateStorageCondition(sc).subscribe((response: StorageConditionDto) => {
        this.router.navigate([StorageConditionConst.SCPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(StorageConditionConst.updateMessage, 'success', 'right');
      });
    }
    else {
      this.storageCondtionService.saveStorageCondition(sc).subscribe((response: StorageConditionDto) => {
        this.router.navigate([StorageConditionConst.SCPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(StorageConditionConst.insertMessage, 'success', 'right')
      });
    }
  }

  cancel() {
    this.router.navigate([StorageConditionConst.SCPath], { relativeTo: this.route.parent });
  }

}
