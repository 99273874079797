import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SARApplicationGroup } from '../../models/sarapplicationgroup/sarapplicationgroup.model';
import { SarApplicationGroupService } from '../../services/sar-application-group.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { BusinessUnitDto } from '../../models/bussinessunits/bussinessunits.model';
import { BussinessUnitService } from '../../services/bussinessunit.service';
import { GroupTypeService } from '../../services/group-type.service';
import { GroupType } from '../../models/groupType/groupType.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { CommonService } from 'src/app/core/services/common.service';
import { SarApplicationGroupConstant } from 'src/app/core/constants/sar-application-group.constants';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-sar-application-group',
  templateUrl: './sar-application-group.component.html',
  styleUrls: ['./sar-application-group.component.scss']
})
export class SarApplicationGroupComponent implements OnInit {
  constructor(private sarApplicationGroupService: SarApplicationGroupService,
    private bussinessUnitService: BussinessUnitService,
    private groupTypeService: GroupTypeService,
    private dialogService: DialogService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    public router: Router
  ) { }

  public gridData: SARApplicationGroup[] = [];
  public globalGridData: SARApplicationGroup[] = [];
  public checked: true = true;
  public searchText: string = '';
  public showInactive = false;
  public businessUnitsList: BusinessUnitDto[] = [];
  public groupTypesList: GroupType[] = [];

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getSARApplicationGroup();
    this.getBusinessUnitsList();
    this.getGroupTypesList();
  }

  getBusinessUnitsList() {
    this.bussinessUnitService.getBUAll().subscribe((outputDtata: BusinessUnitDto[]) => {
      this.businessUnitsList = outputDtata;
    });
  }

  getGroupTypesList() {
    this.groupTypeService.getGroupType().subscribe((outputDtata: GroupType[]) => {
      this.groupTypesList = outputDtata;
    });

  }

  getSARApplicationGroup() {
    this.sarApplicationGroupService.getGroup().subscribe((response: SARApplicationGroup[]) => {
      this.globalGridData = Object.assign([], response);
      this.updateGrid();
    }
    );
  }

  updateGrid() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData, this.showInactive, SarApplicationGroupConstant.searchFieldName, this.searchText);
  }


  cancelSearch() {
    this.searchText = '';
    this.updateGrid();
  }

  activeChanged(value: boolean) {
    this.showInactive = value;
    this.updateGrid();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  manage(isCreate: boolean, isActive: boolean, groupName: string, id: number, description: string, businessunitId: string, groupTypeId: string, businessunitName: string, groupTypeName: string, displayOrder: string) {
    this.router.navigate(["../manageSARApplicationGroup"], {
      state: {
        'isCreate': isCreate, 'id': id, 'name': groupName, 'isActive': isActive, 'description': description, 'businessunitId': businessunitId, 'groupTypeId': groupTypeId,
        'displayOrder': +displayOrder, 'businessUnitList': this.businessUnitsList, 'groupTypesList': this.groupTypesList, 'businessunitName': businessunitName, 'groupTypeName': groupTypeName
      },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let sarApplicationGroup: SARApplicationGroup[] = arr.map((item: any) => {
          return {
            id: 0,
            name: item['Group Name'].toString(),
            description: '',
            businessunitId: 1,
            groupTypeId: 1,
            displayOrder: item['Display Order'],
            isActive: item['Is Active'],
            createdBy: item['Created By'],
            modifiedBy: item['Modified By'],
            businessUnitname: item['Business Unit'],
            groupTypeName: item['Group Type'],
          };
        });
        this.sarApplicationGroupService.importExcelData(sarApplicationGroup).subscribe((response: SARApplicationGroup) => {
          this.commonService.showNotificationMessage(SarApplicationGroupConstant.bulkInsertMessage, 'success', 'right')
          this.getSARApplicationGroup();
        });
      }
    });
  }

}
