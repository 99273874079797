<section>
  <div class="mt-20 text-align-right">
    <button kendoButton class="upload-button ml-20" *ngIf="isDraftavailable" (click)="DownloadItems()"> Download & Edit
      Items</button>
    <button kendoButton class="upload-button ml-20" (click)="import()"> Upload Excel </button>
    <button kendoButton class="upload-button ml-20" (click)="navGenerateTemplate()"> Generate Excel </button>
    <button kendoButton class="upload-button ml-20" (click)="AddChangeSAR()"> Add New Item </button>
    <button kendoButton class="upload-button ml-20" [disabled]="selectedItems.length===0" (click)="bulkCancel()">Bulk
      Action </button>
  </div>
</section>
<div *ngIf="!requiredParams.isNew">
  <section>
    <h3>Item List</h3>
    <!-- Grid -->
    <kendo-grid kendoGridSelectBy="id" [(selectedKeys)]="selectedItems" [selectable]="true"
      [data]="changeDataDetails.items" #item style="height:auto">
      <kendo-grid-checkbox-column [width]="50">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <input type="checkbox" id="checkbox-{{rowIndex}}"
            *ngIf="!containsReject() && accessForEditAction(dataItem) && (dataItem.status ==='Rejected' || dataItem.status ==='Draft')"
            [disabled]="containsReject() && (dataItem.status!=='Rejected' || dataItem.status!=='Draft')"
            [kendoGridSelectionCheckbox]="rowIndex" />
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-dataItem let-rowIndex="rowIndex">
          <input kendoGridSelectAllCheckbox type="checkbox"
            [disabled]="containsReject() && accessForEditAction(dataItem)" id="headerCheckbox" />
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-column field="lineNumber" title="Line No." [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{dataItem.lineNumber}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="materialNumber" title="Material Number" [width]="200"></kendo-grid-column>
      <kendo-grid-column field="changeType" title="Change Type"></kendo-grid-column>
      <kendo-grid-column field="plantOtherChangeType" title="Plant/Other Change Type"></kendo-grid-column>
      <kendo-grid-column field="oldData" title="Old Data">
      </kendo-grid-column>
      <kendo-grid-column field="newData" title="New Data">
      </kendo-grid-column>
      <kendo-grid-column title="Status" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div [ngClass]="{'default-button status-btn':true,'draftbttn':dataItem.status === 'Draft', 
                    'rejectedbttn':dataItem.status === 'Rejected', 'completedbttn':dataItem.status === 'Completed',
                    'cancelledbttn':dataItem.status === 'Cancelled'}">{{dataItem.status}}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title=" " [width]="50">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div kendoPopoverAnchor [popover]="myPopover"
            *ngIf="(dataItem.status === 'Draft' || dataItem.status === 'Rejected') && accessForEditAction(dataItem)">
            <span class="k-icon k-i-more-vertical"></span>
          </div>
          <kendo-popover #myPopover position="bottom">
            <ng-template kendoPopoverBodyTemplate>
              <div class="popover-content">
                <a href="javascript:void();" (click)="editLineItem(dataItem,rowIndex)">Edit Line
                  Item</a>

                <hr class="separator ml-5 mr-5" />
                <a href="javascript:void();" *ngIf="dataItem.status === 'Draft'"
                  (click)="rejectLineItem(dataItem,rowIndex)">Delete Line Item</a>
                <a href="javascript:void();" *ngIf="dataItem.status === 'Rejected'"
                  (click)="cancelLineItems(dataItem,rowIndex,'')">Cancel Line Item</a>
              </div>
            </ng-template>
          </kendo-popover>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </section>
  <div *ngIf="changeDataDetails && changeDataDetails!.items.length !== 0" class="separator  mb-20"></div>
  <div *ngIf="isDraftavailable">
    <fieldset *ngIf="changeDataDetails && changeDataDetails!.items.length !== 0" kendoTooltip
      tooltipClass="tooltip-design-caret"
      title="Resolution details and attachment is only applicable for the items under “Draft” status"
      class="w-75 mb-20 fieldset">
      <legend>Attachments </legend>

      <kendo-label text="Action Attachments"></kendo-label><br>
      <div class="fileuploadContainer">
        <p class="text">Drop Files or Click to Upload</p>
        <input class="fileupload" type="file" #fileInput (change)="onFilesUpload($event)" accept="accptedFilesStr" />
      </div>
      <kendo-grid *ngIf="attachmentDetailsDisplay && attachmentDetailsDisplay.length" #myGrid
        [data]="attachmentDetailsDisplay" [hideHeader]="true" class="list-container" scrollable="none"
        [style.width.px]="500">
        <kendo-grid-column>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button kendoButton (click)="downloadFile(rowIndex)" look="flat" [icon]="'download'"
              class="download-button"></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="400">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.fileName}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button kendoButton (click)="removeFile(rowIndex)" look="flat" [icon]="'delete'"
              class="delete-button"></button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </fieldset>
    <fieldset *ngIf="changeDataDetails && changeDataDetails!.items.length !== 0" class="w-75 fieldset">
      <legend>Assignment Details </legend>
      <div>
        <kendo-label text="Comment"></kendo-label><br>
        <kendo-textarea (change)="onChangeComment()" [(ngModel)]="tempActionRequest.comment"></kendo-textarea>
      </div>
      <!--  section 6 -->
      <section class="d-flex">
        <div class="mt-10 mx-5 w-50">
          <kendo-label text="Assign To Group"><span style="color:red"> *</span></kendo-label><br>
          <kendo-dropdownlist class="mt-5" [data]="assignToGroups" textField="name" valueField="id"
            (selectionChange)="selectAssignToGroup($event)" [defaultItem]="defaultAssignToGroup"
            [valuePrimitive]="true">
          </kendo-dropdownlist>
          <span *ngIf="!isGoupSelected" class="noValue">
            Required field!
            Please select group
          </span>
          <br />
        </div>
        <div class="mt-10 mx-5 w-50">
          <kendo-label text="Assign To User"></kendo-label><br>
          <kendo-dropdownlist [(ngModel)]="selectedUser" [disabled]="!enabledAssignToUser" class="mt-5"
            [data]="assignToUsers" [defaultItem]="defaultAssignToUser" textField="name" valueField="id"
            (selectionChange)="selectAssignToUser($event)" [valuePrimitive]="true">
          </kendo-dropdownlist>
        </div>
      </section>
    </fieldset>
  </div>