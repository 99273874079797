import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { StorageConditionDto } from '../models/storagecondition/storagecondition.model';
import { StorageConditionConst } from 'src/app/core/constants/storagecondition.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
    providedIn: 'root',
})
export class StorageCondtionService extends HttpAbstractService<StorageConditionDto> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getStorageConditionAll() {
        return this.getAll(StorageConditionConst.SCApiName);
    }
    saveStorageCondition(data: StorageConditionDto) {
        return this.post(StorageConditionConst.SCApiName, data);
    }

    importExcelData(data: StorageConditionDto[]) {
        return this.postAll(StorageConditionConst.SCApiName + SharedConst.importApiName, data);
    }
    UpdateStorageCondition(data: StorageConditionDto) {
        return this.put(StorageConditionConst.SCApiName, data.id, data);
    }
}