
export const QACodeConst = {
    QAApiName: 'api/QACode',
    QAPath: 'QACode',
    manageQAPath: 'manageQACode',
    createText: 'New QA Code',
    insertMessage: 'QA code has been added successfully',
    updateMessage: 'QA code has been updated successfully',
    nameError: 'Name is required',
    searchFieldName: 'name',
    code: 'code',
    bulkInsertMessage:'QA codes are successfully importd'
};