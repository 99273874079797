import { Component, OnInit } from '@angular/core';
import { QACodeDto } from '../../models/qacode/qacode.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QACodeService } from '../../services/qacode.service';
import { QACodeConst } from 'src/app/core/constants/qacode.const';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-qacode',
  templateUrl: './qacode.component.html',
  styleUrls: ['./qacode.component.scss']
})
export class QacodeComponent implements OnInit {
  globalGridData: QACodeDto[] = [];
  searchText: string = SharedConst.empty;
  gridData: QACodeDto[] = [];
  isActive = false;
  constructor(private qaCodeService: QACodeService, public router: Router,
    private route: ActivatedRoute, public commonService: CommonService, private dialogService: DialogService) {
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.geQAList();
  }

  geQAList() {
    this.qaCodeService.getQAAll().subscribe((outputDtata: QACodeDto[]) => {
      this.gridData = outputDtata;
      this.globalGridData = Object.assign([], outputDtata);
      this.search();
    }
    );
  }

  search() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData,
      this.isActive, QACodeConst.searchFieldName, this.searchText, QACodeConst.code)
  }

  clear() {
    this.searchText = SharedConst.empty;
    this.gridData = Object.assign([], this.globalGridData);
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  manage(isCreate: boolean, isActive: boolean, name: string, code: string, id: number) {
    this.router.navigate(["../" + QACodeConst.manageQAPath], {
      state: { isCreate: isCreate, id: id, name: name, code: code, isActive: isActive },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let storageConditions: QACodeDto[] = arr.map((item: any) => {
          return {
            id: 0,
            name: item['Name'].toString(), code: item['Code'].toString(), isActive: item['Is Active'],
            createdBy: item['Created By'], modifiedBy: item['Modified By'],
            createdDateTime: undefined, modifiedDateTime: undefined
          };
        });
        this.qaCodeService.importExcelData(storageConditions).subscribe((response: QACodeDto) => {
          this.commonService.showNotificationMessage(QACodeConst.bulkInsertMessage, 'success', 'right')
          this.geQAList();
        });
      }
    });
  }
}

