import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ApprovalAssignmentComponent } from 'src/app/features/sar/components/approval-management/approval-assignment/approval-assignment.component';
import { CommonService } from '../../services/common.service';
import { ApprovalDetailsConstants } from '../../constants/approval-details.constants';

@Component({
  selector: 'app-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.scss']
})
export class BulkActionComponent {
  comment: string = '';
  isMMD: boolean = false;
  btnText: string = 'Complete';
  isCommentError: boolean = false;
  isCompleteDisable: boolean = false;
  changeType: string = '';
  constructor(public dialog: DialogRef, private dialogService: DialogService,
    public commonService: CommonService) { }


  getButtonText() {
    if (this.isMMD !== true) {
      this.btnText = 'Approve';
    }
    return this.btnText;
  }
  close(actionName: string) {
    if (!this.isMMD && actionName === 'Complete') {
      const dialog: DialogRef = this.dialogService.open({
        content: ApprovalAssignmentComponent
      });
      dialog.result.subscribe((r: any) => {
        if (r.actionType == 's') {
          this.dialog.close({ action: actionName, comment: this.comment, lineItemDtail: r.lineItemDtail });
        }
        this.dialog.close({ action: actionName, comment: this.comment, lineItemDtail: r.lineItemDtail });
      });
    }
    else if (actionName === 'Cancel') {
      this.isCommentError = false;
      if (this.commonService.isNullOrEmptyOrUndefined(this.comment)) {
        this.commonService.showNotificationMessage(ApprovalDetailsConstants.commentMessage, 'error', 'right')
        this.isCommentError = true;
        return false;
      }
      this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
        .subscribe((res: any) => {
          if (res.action === 'yes') {
            this.dialog.close({ action: actionName, comment: this.comment, lineItemDtail: {} });
          }
        });
    }
    else if (actionName === 'Reject') {
      this.isCommentError = false;
      if (this.commonService.isNullOrEmptyOrUndefined(this.comment)) {
        this.commonService.showNotificationMessage(ApprovalDetailsConstants.commentMessage, 'error', 'right')
        this.isCommentError = true;
        return false;
      }
      this.commonService.showConfirmation('Rejection', ApprovalDetailsConstants.rejectMessage)
        .subscribe((res: any) => {
          if (res.action === 'yes') {
            this.dialog.close({ action: actionName, comment: this.comment, lineItemDtail: {} });
          }
        });
    }
    else if (this.isMMD && actionName === 'Complete') {
      this.dialog.close({ action: actionName, comment: this.comment, lineItemDtail: {} });
    }
    else {
      this.dialog.close({ action: 'Close', comment: this.comment, lineItemDtail: {} });
    }
  }


}
