import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { SPKConst } from 'src/app/core/constants/spk.const';
import { CommonService } from 'src/app/core/services/common.service';
import { SPKDto } from '../../../models/spk/spk.model';
import { SPKService } from '../../../services/spk.service';

@Component({
  selector: 'app-manage-spk',
  templateUrl: './manage-spk.component.html',
  styleUrls: ['./manage-spk.component.css']
})
export class ManageSpkComponent implements OnInit {
  public id: number = 0;
  public isCreate: boolean = false;
  public name: string = '';
  public isActive: boolean = false;
  public headerText: string = SPKConst.createText;
  public code: string = '';
  hasNameError = false;
  hasCodeError = false;
  hasError = false;

  @ViewChild('buForm') myForm!: NgForm;
  constructor(public router: Router,
    private route: ActivatedRoute,
    private spkService: SPKService, public commonService: CommonService
  ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.isCreate = stateData['isCreate']
    this.name = stateData['name'];
    this.isActive = stateData['isActive']
    this.code = stateData['code']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.name;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }
  cleareError() {
    this.hasNameError = false;
    this.hasCodeError = false;
    this.hasError = false;
  }

  save() {
    this.cleareError();
    const spk = new SPKDto();
    spk.id = this.id;
    spk.name = this.name;
    spk.isActive = this.isActive;
    spk.code = this.code;
    spk.createdBy = this.commonService.getUserEmailId();
    spk.modifiedBy = this.commonService.getUserEmailId();
    if (!this.name) {
      this.myForm.form.get('fName')?.markAllAsTouched();
      this.hasNameError = true;
      this.hasError = true;
    }
    if (!this.code) {
      this.myForm.form.get('fSPKCode')?.markAllAsTouched();
      this.hasCodeError = true;
      this.hasError = true;
    }
    if (this.hasError) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'right');
      return false;
    }
    if (!this.isCreate) {
      this.spkService.updateSPK(spk).subscribe((response: SPKDto) => {
        this.router.navigate([SPKConst.SPKPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(SPKConst.updateMessage, 'success', 'right');
      });
    }
    else {
      this.spkService.saveSPK(spk).subscribe((response: SPKDto) => {
        this.router.navigate([SPKConst.SPKPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(SPKConst.insertMessage, 'success', 'right')
      });
    }
  }

  cancel() {
    this.router.navigate([SPKConst.SPKPath], { relativeTo: this.route.parent });
  }

}
