<!-- Create / Update setup pages -->
<div  class="contentBox">
    <h2 class="heading content-top">{{headerText}}</h2>
    <section class="w-100">
        <form [formGroup]="sarApplicationGroupForm">
            
            <div class="mt-10">
                <kendo-label text="Group Name"></kendo-label>
                <br>
                <kendo-textbox class="mt-5 w-25" name="fGroupName" [required]="hasgroupNameError" formControlName="groupName" [clearButton]="true"></kendo-textbox>
                <div *ngIf="hasgroupNameError">
                    <kendo-formerror>Please enter a Group Name</kendo-formerror>
                </div>
            </div>

            <div class="mt-10 w-25">
                <kendo-label text="Business Unit"></kendo-label>
                <br>
                <kendo-dropdownlist name="fBusinessUnit" 
                                    [required]="hasBusinessUnitError"
                                    formControlName="businessUnit"
                                    [data]="businessUnitsList"
                                    [defaultItem]="{ name: 'Select SAR Buiness Unit' }"
                                    textField="name"
                                    valueField="name"
                                    (selectionChange)="selectBusinessUnit($event)"
                                    [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasBusinessUnitError">
                    <kendo-formerror>Please select a Business Unit</kendo-formerror>
                </div>
            </div>

            <div class="mt-10 w-25">
                <kendo-label text="Group Type"></kendo-label>
                <br>
                <kendo-dropdownlist name="fGroupType" 
                                    [required]="hasGroupTypeError"
                                    formControlName="groupType"
                                    [data]="groupTypesList"
                                    [defaultItem]="{ name: 'Select SAR Group Type'}"
                                    textField="name"
                                    valueField="name"
                                    (selectionChange)="selectGroupType($event)"
                                    [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasGroupTypeError">
                    <kendo-formerror>Please select a Group Type</kendo-formerror>
                </div>
            </div>

            <div class="mt-10">
                <kendo-label text="Display Order"></kendo-label>
                <br>
                <kendo-textbox class="mt-5 w-25" type="number" min="0" name="fdisplayOrder" [required]="hasDisplayOrderError" formControlName="displayOrder" [clearButton]="true"></kendo-textbox>
                <div *ngIf="hasDisplayOrderError">
                    <kendo-formerror>Please enter a display order</kendo-formerror>
                </div>
            </div>

            <div class="mt-10">
                <kendo-label text="Is Active"></kendo-label>
                <br>
                <input class="checkbox" type="checkbox" id="isActiveCheck" [checked]="isActive" formControlName="isActive" />
            </div>
            <div class="text-right">
                <kendo-button class="border-button mr-20" (click)="cancel()">Cancel</kendo-button>
                <kendo-button class="primary-button" (click)="save()">Save</kendo-button>
            </div>
        </form>
    </section>
    </div>