import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaterialStatusConstant } from 'src/app/core/constants/material-status.constants';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { MaterialStatus } from '../models/materialstatus/materialstatus.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
  providedIn: 'root'
})
export class MaterialStatusService extends HttpAbstractService<MaterialStatus> {

  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    super(http);
  }

  getMaterialStatus() {
    return this.getAll(MaterialStatusConstant.materialStatus);
  }

  saveMaterialStatus(data: MaterialStatus) {
    return this.post(MaterialStatusConstant.materialStatus, data);
  }

  updateMaterialStatus(data: MaterialStatus) {
    return this.put(MaterialStatusConstant.materialStatus, data.id, data);
  }
  importExcelData(data: MaterialStatus[]) {
    return this.postAll(MaterialStatusConstant.materialStatus + SharedConst.importApiName, data);
  }
}
