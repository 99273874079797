import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { UserGroupsService } from 'src/app/features/setup/services/user-groups.service';
import { LineAssignmentDto } from '../../../models/common/line-assignment.model';
import { Group } from 'src/app/features/setup/models/group/group.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { ApprovalDetailService } from '../../../services/approvalDetail/approval-details.service';
import { GetTimelines } from '../../../models/approval-details/get-approval-details.model';
import { LoaderSize, LoaderThemeColor, LoaderType } from '@progress/kendo-angular-indicators';
import { RequestLineService } from '../../../services/request-line.service';
import { AssignmentDetailsDto } from '../../../models/approval-details/assignment-detail.model';

@Component({
  selector: 'app-re-assign-item',
  templateUrl: './re-assign-item.component.html',
  styleUrls: ['./re-assign-item.component.scss']
})
export class ReAssignItemComponent implements OnInit {

  assignToGroups: Group[] = [];
  assignToUsers: UserDto[] = [];
  selecteLineItems: any = []
  lineAssignment: LineAssignmentDto = new LineAssignmentDto();
  selectedUser = 0;
  isChangeData: boolean = false;
  enabledAssignToUser: boolean = false;
  rejectedLineItems: any = [];
  public defaultAssignToGroup: { name: string; id: number } = {
    id: 0,
    name: "Select Group"
  };
  public defaultAssignToUser: { name: string; id: number } = {
    id: 0,
    name: "-"
  };
  isGoupSelected: boolean = true;
  isMMD: boolean = true;
  listItems: string[] = [];
  requestIssueId: number = 0;
  timelineDetails: GetTimelines[] = [];
  lineItemId: number = 0;
  prevAssignedTo: string = '';
  prevgroup: string = '';
  newAssignment: boolean = true;
  groupType: number = 1;
  public isOpen: boolean = false;
  @ViewChild("multiselect", { static: true }) public multiselect: any;
  lineItemSelected: boolean = true;
  assignmentDetailsDto!: AssignmentDetailsDto;
  isLoader: boolean = true;
  public loader =
    {
      type: <LoaderType>"converging-spinner",
      themeColor: <LoaderThemeColor>"info",
      size: <LoaderSize>"medium",
    };
  assignedGroup: number = 0;
  isShowReAssign: boolean = false;
  constructor(private userGroupsService: UserGroupsService, private sarUsersService: SarUsersService,
    public dialog: DialogRef, private el: ElementRef, private renderer: Renderer2,
    public commonService: CommonService, private approvalDetailService: ApprovalDetailService,
    public requestLineService: RequestLineService) { }

  ngOnInit(): void {
    this.isLoader = true;
    if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment.comment)) {
      this.lineAssignment.comment = '';
    }
    this.getAssignmentGroup();
  }

  getAssignmentGroup() {
    this.userGroupsService.getAssignmentGroups(this.groupType).subscribe((data: any) => {
      this.assignToGroups = data;
      this.getAssignmentDetail()
    })
  }

  selectAll() {
    this.selecteLineItems = this.rejectedLineItems;
  }
  reSubmit() {
    this.newAssignment = this.newAssignment === true ? false : true;
    this.lineAssignment.assignedGroupId = 0;
    this.lineAssignment.assignedUserId = 0;
    this.isGoupSelected = true;
    this.assignedGroup = 0;
    this.selectedUser = 0;
  }
  getAssignmentDetail(): void {
    if (this.isChangeData) {
      this.lineItemId = this.selecteLineItems.length == 0 ? this.rejectedLineItems[0].id : this.selecteLineItems[0].id;
    }
    this.requestLineService.getAssignmentDetailsByRequestLineId(this.lineItemId).subscribe((data: AssignmentDetailsDto) => {
      this.assignmentDetailsDto = data;
      this.isShowReAssign = this.checkReSubmit();
      this.isLoader = false;
    })
  }


  getAssignmentUsers() {
    if (this.lineAssignment.assignedGroupId) {
      this.sarUsersService.getAssignedUsers(this.lineAssignment.assignedGroupId).subscribe((data: any) => {
        this.assignToUsers = data;
      })
    }
  }

  selectAssignToGroup(group: Group) {
    this.lineAssignment.assignedGroupId = group.id;
    this.lineAssignment.assignedUserId = 0;
    this.selectedUser = 0;
    this.getAssignmentUsers();
    this.enabledAssignToUser = group.id ? true : false;
  }

  selectAssignToUser(user: UserDto) {
    this.lineAssignment.assignedUserId = user.id;
  }

  close(actionName: string) {
    if (actionName === 'Close') {
      this.dialog.close({ actionType: actionName, lineItemDtail: this.lineAssignment });
      return false;
    }
    this.isGoupSelected = true;
    this.lineItemSelected = true;
    if (this.isChangeData) {
      if (this.selecteLineItems.length === 0) {
        this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
        this.lineItemSelected = false;
        return false;
      }
    }
    if (this.newAssignment) {
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!) && !this.lineAssignment?.isMixedValues) {
        this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
        this.isGoupSelected = false;
        return false;
      }
    }
    else {
      this.lineAssignment.assignedGroupId = this.assignmentDetailsDto.assignedGroupId;
      this.lineAssignment.assignedUserId = this.assignmentDetailsDto.assignedUserId === null ? 0 : this.assignmentDetailsDto.assignedUserId;
    }

    if (this.isChangeData) {
      this.lineAssignment.requestLineIds = this.selecteLineItems.map((x: any) => { return x.id });
    }
    this.dialog.close({ actionType: actionName, lineItemDtail: this.lineAssignment });
  }

  checkReSubmit() {
    //1 MMD , 3 SA
    if (this.commonService.isNullOrEmptyOrUndefined(this.assignmentDetailsDto.assignedGroup)) {
      return false;
    }
    let assignedgroupName = this.assignmentDetailsDto.assignedGroup?.toString();
    if ((assignedgroupName!.search('Stock') >= 0 && this.groupType !== 2)
      || (assignedgroupName!.search('MMD') >= 0 && this.groupType !== 1)) {
      return false;
    }
    return true
  }
}

