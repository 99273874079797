import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GraphResponseType, ProfileType } from "../models/graph.models";
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const GRAPH_ENDPOINT_USER = 'https://graph.microsoft.com/v1.0/users';

@Injectable({
    providedIn: 'root'
})
export class GraphService {
    constructor(private http: HttpClient) { }

    getAdUserProfile() {
        return this.http.get<ProfileType>(GRAPH_ENDPOINT + '?$select=givenName,displayName,surname,userPrincipalName,id,extension_39c7d3e68666465dab296ee0fc538118_cvx_ProvisioningID,extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11');
    }

    getAdUserNameByCAI(cai: string) {
        return this.http.get<GraphResponseType>(GRAPH_ENDPOINT_USER + '?$filter=extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11 eq ' + "'" + cai + "'" + ' &$select=givenName,displayName,surname,userPrincipalName,id,extension_39c7d3e68666465dab296ee0fc538118_cvx_ProvisioningID,extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11');
    }

    searchAdUser(searchText: string) {
      let relativePath = `?$filter=startswith(displayName,'${searchText}') or startswith(givenName,'${searchText}') or startswith(surname,'${searchText}') or startswith(mail,'${searchText}') or startswith(userPrincipalName,'${searchText}') or startswith(extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11, '${searchText}') &$select=givenName,surname,displayName,extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11,extension_39c7d3e68666465dab296ee0fc538118_cvx_ProvisioningID`;
        return this.http.get<GraphResponseType>(GRAPH_ENDPOINT_USER + relativePath);
    }
}
