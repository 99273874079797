import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Role } from '../models/role/role.model';
import { Observable, observable, of } from 'rxjs';
import { UserAndRoleConst } from 'src/app/core/constants/userandrole.const';


@Injectable({
    providedIn: 'root'
})
export class RoleService extends HttpAbstractService<Role> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getRole() {
        return this.getAll(RoleConstant.Role);
    }
    saveRole(data: Role) {
        return this.post(RoleConstant.Role, data);
    }
    updateRole(data: Role) {
        return this.put(RoleConstant.Role, data.id, data);
    }



    getRoleByEmail<T>(email: string): Observable<T> {

        return this.httpUtilityService.getAll(UserAndRoleConst.userAPI + UserAndRoleConst.getAllUserDetailsByEmail + '/' + email.toString());
    }
}
