import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UnitOfMeasureConstant } from 'src/app/core/constants/unit-of-measure.constants';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { UnitOfMeasure } from '../models/unitofmeasure/unitofmeasure.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
    providedIn: 'root',
})
export class UnitOfMeasureService extends HttpAbstractService<UnitOfMeasure> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getUnitOfMeasure() {
        return this.getAll(UnitOfMeasureConstant.uom);
    }
    saveUnitOfMeasure(data: UnitOfMeasure) {
        return this.post(UnitOfMeasureConstant.uom, data);
    }
    updateUnitOfMeasure(data: UnitOfMeasure) {
        return this.put(UnitOfMeasureConstant.uom, data.id, data);
    }
    importExcelData(data: UnitOfMeasure[]) {
        return this.postAll(UnitOfMeasureConstant.uom+ SharedConst.importApiName, data);
    }
}