import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilityService } from "src/app/core/services/http.utility.service";
import { AuditDataDto } from "../models/audit.models";

@Injectable({
    providedIn: 'root'
})
export class AuditService {
    constructor(public httpUtilityService: HttpUtilityService) { }

    getAuditData(): Observable<AuditDataDto[]> {
        return this.httpUtilityService.get<AuditDataDto[]>('api/Audit');
    }
}