import { Injectable } from '@angular/core';
import { IconSettingsService } from '@progress/kendo-angular-icons';

@Injectable()
export class MyIconService extends IconSettingsService {
    private iconDictionary: { [key: string]: string } = {
        'caret-alt-down': 'k-icon k-i-arrow-chevron-down',
        'caret-alt-up': 'k-icon k-i-arrow-chevron-up'
    };

    public override getCustomFontIconClass(iconName: string): string {
        return this.iconDictionary[iconName] || super.getCustomFontIconClass(iconName);
    }
}
