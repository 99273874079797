import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SegmentConst } from 'src/app/core/constants/segment.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { SegmentDto } from '../../../models/segment/segment.model';
import { SegmentService } from '../../../services/segment.service';

@Component({
  selector: 'app-segment-manage',
  templateUrl: './segment-manage.component.html',
  styleUrls: ['./segment-manage.component.scss']
})
export class SegmentManageComponent implements OnInit {
  segmentId = 0;
  isCreate = false;
  name = '';
  isActive = false;
  headerText = SegmentConst.createText;
  hasNameError = false;
  hasShortNameError = false;
  hasError = false;
  @ViewChild('segmentForm') myForm!: NgForm;

  constructor(public router: Router, private segmentService: SegmentService,
    private route: ActivatedRoute, public commonService: CommonService) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.segmentId = stateData['segmentId'];
    this.isCreate = stateData['isCreate'];
    this.name = stateData['name'];
    this.isActive = stateData[SharedConst.isActive];
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.name;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }

  cleareError() {
    this.hasNameError = false;
    this.hasShortNameError = false;
    this.hasError = false;
  }
  save() {
    this.cleareError();
    const segment = new SegmentDto();
    segment.id = this.segmentId;
    segment.name = this.name;
    segment.isActive = this.isActive;
    segment.createdBy = this.commonService.getUserEmailId();
    segment.modifiedBy =this.commonService.getUserEmailId();
    if (!this.name) {
      this.hasNameError = true;
      this.hasError = true;
      this.myForm.form.get('fSegmentName')?.markAllAsTouched();
    }
    if (this.hasError) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'right');
      return false;
    }
    if (!this.isCreate) {
      this.segmentService.updatesegment(segment).subscribe((outputDtata: SegmentDto) => {
        this.commonService.showNotificationMessage(SegmentConst.updateMessage, 'success', 'right')
        this.router.navigate([SegmentConst.segmentPath], { relativeTo: this.route.parent });
      });
    }
    else {
      this.segmentService.savesegment(segment).subscribe((outputDtata: SegmentDto) => {
        this.commonService.showNotificationMessage(SegmentConst.insertMessage, 'success', 'right')
        this.router.navigate([SegmentConst.segmentPath], { relativeTo: this.route.parent });
      });
    }
  }

  cancel() {
    this.router.navigate([SegmentConst.segmentPath], { relativeTo: this.route.parent });
  }
}
