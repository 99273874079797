<div class="sar-contentBox my-profile">
    <div class="content-top d-flex j-c-s">
        <h1>
            <span class="active"> My Profile </span>
        </h1>
        <div>
            <button kendoButton class="border-button ml-20 f-size-150" (click)="reset()"> Reset
            </button>
            <button kendoButton class="primary-button ml-20 f-size-150" (click)="save()" [disabled]="isFormInvalid">
                Save
            </button>
        </div>
    </div>
    <form [formGroup]="profileForm">
        <fieldset class="w-75 fieldset">
            <legend> Email Notifications </legend>
            <section class="w-75 d-inline-flex flex-row align-items-center">
                <div class="mt-10 mx-5 w-75 d-flex j-c-s">
                    <p>Email Address</p>
                    <div class="flex-column w-50">
                        <div class="d-flex w-100">
                            <kendo-textbox formControlName="email" class="mt-5" required></kendo-textbox>
                            <p>{{ emailDomain }}</p>
                        </div>
                        <kendo-formerror *ngIf="isFormFilled && isFormInvalid"> {{ emailError }} </kendo-formerror>
                    </div>
                </div>
                <div class="mt-10 mx-5 w-75 d-flex j-c-s">
                    <p>Secondary Email Address</p>
                    <div class="d-flex w-50">
                        <kendo-textbox formControlName="secondEmail" class="mt-5"></kendo-textbox>
                        <p>{{ emailDomain }}</p>
                    </div>
                </div>
                <div class="mt-10 mx-5 w-75 d-flex j-c-s">
                    <p>Send Email Notifications</p>
                    <div class="w-50 d-flex checkbox-section">
                        <input kendoCheckBox type="checkbox" formControlName="isSendNotification" [checked]="false"
                            size="large" />
                    </div>
                </div>
            </section>
        </fieldset>
        <fieldset class="w-75 fieldset">
            <legend> Delegation </legend>
            <section class="w-75 d-inline-flex flex-row align-items-center">
                <div class="mt-10 mx-5 w-75 d-flex j-c-s">
                    <p>Delegation Active</p>
                    <div class="w-50 d-flex">
                        <label class="switch">
                            <input type="checkbox" formControlName="isDelegationActive" [checked]="false">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                <div class="mt-10 mx-5 w-75 d-flex j-c-s">
                    <p>Delegated to</p>
                    <div class="d-flex w-50">
                        <kendo-dropdownlist formControlName="delegatedUserId" class="mt-5" [data]="assignToUsers"
                            textField="name" valueField="id" [valuePrimitive]="true">
                        </kendo-dropdownlist>
                    </div>
                </div>
            </section>
        </fieldset>
    </form>
</div>