
<section class="userguide-content-box">
    <h2 class="heading content-top">User Guides</h2>   
    <a target="_blank" href="https://chevron.sharepoint.com/:b:/s/pscmgss/manila/EfS0v370NgtNvYoqucLt2uMBH0TufhTYmeUeh8IyHkKorw?e=9sfsAf" class="user-guide-item">
        <div>
        <img src="../../../../../assets/icons/LinkPDFicon.svg" alt="">
        <div class="link-text mt-10" >Quick Reference Guide</div>
        </div>
    </a>
    <a target="_blank" href="https://chevron.sharepoint.com/:f:/s/pscmgss/manila/EoE-qB5TKpdIl4qmN0oNUPsBDGUgY3RRDVTYJc0MeKXtDw?e=NCAGNZ" class="user-guide-item">
        <div>
        <img src="../../../../../assets/icons/LinkTraningIcon.svg" alt="">
        <div class="link-text mt-10">Training Resources</div>
        </div>
    </a>

</section>