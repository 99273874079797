export const SupplierConst = {
    SupplierApiName:'api/Supplier',
    SupplierPath: 'supplier',
    manageSupplierPath: 'manageSupplier',
    createText:'Create New Supplier',
    insertMessage:'Supplier has been added successfully',
    updateMessage:'Supplier has been updated successfully',
    nameError:'Name is required',
    searchsupplierName: 'supplierName',
    bulkInsertMessage:'Suppliers are successfully importd',
    supplierNumber:'supplierNumber'
    
  };