import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplierConst } from 'src/app/core/constants/Supplier.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { SupplierDto } from '../../models/supplier/supplier.model';
import { SupplierService } from '../../services/supplier.service';

import { process } from "@progress/kendo-data-query";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {
  globalGridData: SupplierDto[] = [];
  searchText: string = SharedConst.empty;
  gridData: SupplierDto[] = [];
  isActive = false;
  public group: { field: string }[] = [];

  constructor(private supplierService: SupplierService, public router: Router,
    private route: ActivatedRoute, public commonService: CommonService, private dialogService: DialogService) {
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getSupplierList();
  }

  getSupplierList() {
    this.supplierService.getAllSupplier().subscribe((outputDtata: SupplierDto[]) => {
      this.gridData = outputDtata;
      this.globalGridData = Object.assign([], outputDtata);
      this.search();
    }
    );
  }

  search() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData,
      this.isActive, SupplierConst.searchsupplierName, this.searchText, SupplierConst.supplierNumber)
  }

  clear() {
    this.searchText = SharedConst.empty;
    this.gridData = Object.assign([], this.globalGridData);
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  manage(isCreate: boolean, isActive: boolean, supplierName: string, supplierNumber: string, buId: number) {
    this.router.navigate(["../" + SupplierConst.manageSupplierPath], {
      state: { isCreate: isCreate, buId: buId, supplierName: supplierName, supplierNumber: supplierNumber, isActive: isActive },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let supplier: SupplierDto[] = arr.map((item: any) => {
          return {
            id: 0,
            supplierName: item['Supplier Name'].toString(), supplierNumber: item['Supplier Number'].toString(), isActive: item['Is Active'],
            createdBy: item['Created By'], modifiedBy: item['Modified By'],
            createdDateTime: undefined, modifiedDateTime: undefined
          };
        });
        this.supplierService.importExcelData(supplier).subscribe((response: SupplierDto) => {
          this.commonService.showNotificationMessage(SupplierConst.bulkInsertMessage, 'success', 'right')
          this.getSupplierList();
        });
      }
    });
  }
}


