
export const SARUsersConstant = {
    SARUsers: 'api/User',
    GetSAROwnersByBUId: '/GetSAROwnersByBUId',
    GetAllUserDetailsByEmail: '/GetAllUserDetailsByEmail',
    getAssignedUsers: '/GetAssignedUsers',
    SARUserRoute: 'SARUsers',
    ManageSARUserRoute: 'ManageSARUsers',
    bulkInsertMessage: 'SAR Users are successfully imported',
    caiError: 'Please enter CAI.',
    caiLengthError: 'CAI should be of 4 characters length, Please enter valid CAI.',
    invalidCAIError: 'CAI entered is invalid, Please enter valid CAI.',
    searchFieldName: 'name',
    cai: 'cai',
};