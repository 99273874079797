import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaterialTypeConstant } from 'src/app/core/constants/material-type.constants';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { MaterialType } from '../models/materialtype/materialtype.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
  providedIn: 'root'
})
export class MaterialTypeService extends HttpAbstractService<MaterialType> {

  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    super(http);
}

  getMaterialType() {
      return this.getAll(MaterialTypeConstant.materialType);
  }

  saveMaterialType(data: MaterialType) {
      return this.post(MaterialTypeConstant.materialType, data);
  }
  
  updateMaterialType(data: MaterialType) {
      return this.put(MaterialTypeConstant.materialType, data.id, data);
  }
  importExcelData(data: MaterialType[]) {
    return this.postAll(MaterialTypeConstant.materialType + SharedConst.importApiName, data);
}
}