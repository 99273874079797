export class MMDActionDto {
    comment?: string;
    materialNumber?: string;
    shortDescription?: string;
    enrichedDescription?: string;
    createdBy?: string;
    modifiedBy?: string;
    requestIssueTypeId?: number;
    requestIssueId?: number;
    requestLineId?: number;
    MMDAction?: number;
    requestId!: number;
    isEditLineItem!: boolean;
}

