import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { SARUsers } from 'src/app/features/setup/models/sarusers/sarusers.model';
import { BussinessUnitService } from 'src/app/features/setup/services/bussinessunit.service';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { PriorityDto } from '../../models/sar-request/priority.model';
import { RequestService } from '../../services/request.service';
import { RequestDto } from '../../models/sar-request/request.model';
import { ActivatedRoute, Router } from '@angular/router';
import { requestConst } from 'src/app/core/constants/request.const';
import { OnDestroy } from "@angular/core"
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SarAllocationService } from 'src/app/features/sar-allocation/services/sar-allocation-service';
import { Observable } from 'rxjs';
import { SelectDataDto } from 'src/app/core/models/select-data.models';
@Component({
  selector: 'app-manage-sar',
  templateUrl: './manage-sar.component.html',
  styleUrls: ['./manage-sar.component.css']
})
export class ManageSARComponent implements OnInit, OnDestroy {
  assets$: Observable<SelectDataDto[]>;
  SARrequest: RequestDto = new RequestDto();
  trackingNumber: string = '';
  id: number = 0;
  isCreate: boolean = true;
  buName: string | undefined;

  priorityList: PriorityDto[] = [];
  SAROwnersByBUList: SARUsers[] = [];
  manageAction: string = requestConst.createSARAction;
  headerText: string = requestConst.createText;

  public get saveDisabled(): boolean {
    return this.isReadOnlyUser() || this.SARrequest.tittle!.length === 0 || this.SARrequest.justification!.length === 0 || this.SARrequest.assetId === undefined;
  }

  constructor(public commonService: CommonService, private sarAllocationService: SarAllocationService, private userService: SarUsersService,
    private buService: BussinessUnitService, private requestService: RequestService,
    private router: Router, private route: ActivatedRoute) {
    this.assets$ = this.sarAllocationService.getAssets();
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isEmptyOrUndefined(stateData)) {
      this.isCreate = stateData['isCreate'];
      this.id = parseInt(stateData['requestId']);
      sessionStorage.setItem('managesar', JSON.stringify(stateData));
    }
    else {
      let sessionState = JSON.parse(sessionStorage.getItem('managesar')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.isCreate = sessionState.isCreate;
        this.id = sessionState.requestId;
      }
    }
    if (!this.isCreate && this.id && this.id !== 0) {
      this.GetSARDetailsById(this.id);
    }
    else {
      this.SARrequest.requestStateId = 5;
    }
    this.SARrequest.requestTypeId = 1;
    this.SARrequest.segmentId = 0;
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('managesar');
  }

  ngOnInit(): void {
    if (!this.isCreate) {
      this.headerText = requestConst.editText + ' ' + this.trackingNumber;
      this.manageAction = requestConst.updateSARAction;
    }
    this.getBUOwners();
    this.getPriorityList();
  }
  isReadOnlyUser() {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return true;
    }
    return false;
  }
  GetSARDetailsById(id: number) {
    this.requestService.GetSARDetailsById(id).subscribe(sardetails => {
      this.trackingNumber = sardetails.trackingNumber;
      this.SARrequest!.id = id;
      this.SARrequest!.requestStateId = sardetails.requestStateId;
      this.SARrequest!.ownerId = sardetails.ownerId;
      this.SARrequest!.tittle = sardetails.tittle;
      this.SARrequest!.priority = sardetails.priorityId;
      this.SARrequest!.justification = sardetails.justification;
      this.SARrequest!.createdBy = sardetails.createdBy;
      this.SARrequest!.modifiedBy = sardetails.modifiedBy;
      this.SARrequest!.assetId = sardetails.assetId;
    });
  }

  getPriorityList() {
    this.requestService.getPriorityList().subscribe(data => {
      this.priorityList = data;
      if (this.isCreate) {
        this.SARrequest.priority = data[0].id;
      }
    });
  }

  getBUOwners() {
    this.userService.GetAllUserDetailsByEmail(this.commonService.getUserEmailId()).subscribe(user => {
      if (user && user.id && user.businessUnitId) {
        this.SARrequest.buId = user.businessUnitId;
        if (this.isCreate) {
          this.SARrequest.ownerId = user.id;
        }
        this.buService.getBUById(this.SARrequest.buId).subscribe(bu => {
          this.buName = bu.name;
          if (this.buName && this.buName != '') {
            this.userService.GetSAROwnersByBUId(this.buName).subscribe(buOwnersList => {
              buOwnersList.map(BU_Owner => { this.SAROwnersByBUList.push(BU_Owner) });
            })
          }

        });

      }
    });
  }

  save(): void {
    this.SARrequest!.modifiedBy = this.commonService.getUserEmailId();
    if (!this.isCreate) {
      this.requestService.updateRequest(this.SARrequest, this.SARrequest!.id).subscribe((response: RequestDto) => {
        const path = `true/${this.isCreate}/${response.id}`;
        this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
          state: { isManage: true, isCreate: this.isCreate, requestId: response.id }, relativeTo: this.route.parent
        });
      });
    }
    else {
      this.SARrequest.createdBy = this.commonService.getUserEmailId();
      this.requestService.saveRequest(this.SARrequest).subscribe((response: RequestDto) => {
        const path = `true/${this.isCreate}/${response.id}`;
        this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
          state: { isManage: true, isCreate: this.isCreate, requestId: response.id, newSARCreated: true }, relativeTo: this.route.parent
        });
      });
    }


  }

  navBack() {
    if (this.isCreate) {
      this.router.navigate(['./'], { relativeTo: this.route.parent });
    }
    else {
      const path = `true/${this.isCreate}/${this.id}`;
      this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
        state: { isManage: true, isCreate: this.isCreate, requestId: this.id, isBack: true }, relativeTo: this.route.parent
      });
    }
  }
}
