export class StockApproverActionDto {
    comment?: string;
    createdBy?: string;
    modifiedBy?: string;
    assignedGroupId?: number;
    assignedUserId?: number;
    requestIssueId?: number;
    requestLineId?: number;
    stockApproverAction?: number;
    requestId!: number;
}

