import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplierConst } from 'src/app/core/constants/Supplier.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { SupplierDto } from '../../../models/supplier/supplier.model';
import { SupplierService } from '../../../services/supplier.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-manage-supplier',
  templateUrl: './manage-supplier.component.html',
  styleUrls: ['./manage-supplier.component.css']
})
export class ManageSupplierComponent implements OnInit {
  public id: number = 0;
  public isCreate: boolean = false;
  public supplierName: string = '';
  public isActive: boolean = false;
  public headerText: string = SupplierConst.createText;
  public supplierNumber: string = '';
  hasSupplierNameError = false;
  hasSupplierNumberError = false;
  hasError = false;

  @ViewChild('supplierForm') myForm!: NgForm;
  constructor(public router: Router,
    private route: ActivatedRoute,
    private supplierService: SupplierService, public commonService: CommonService
  ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['buId'];
    this.isCreate = stateData['isCreate']
    this.supplierName = stateData['supplierName'];
    this.isActive = stateData['isActive']
    this.supplierNumber = stateData['supplierNumber']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.supplierName;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }
  cleareError() {
    this.hasSupplierNameError = false;
    this.hasSupplierNumberError = false;
    this.hasError = false;
  }

  save() {
    this.cleareError();
    const sd = new SupplierDto();
    sd.id = this.id;
    sd.supplierName = this.supplierName;
    sd.isActive = this.isActive;
    sd.supplierNumber = this.supplierNumber;
    sd.createdBy = this.commonService.getUserEmailId();
    sd.modifiedBy = this.commonService.getUserEmailId();
    if (!this.supplierName) {
      this.myForm.form.get('fSupplierName')?.markAllAsTouched();
      this.hasSupplierNameError = true;
      this.hasError = true;
    }
    if (!this.supplierNumber) {
      this.myForm.form.get('fSuppliernumber')?.markAllAsTouched();
      this.hasSupplierNumberError = true;
      this.hasError = true;
    }
    if (this.hasError) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
      return false;
    }
    if (!this.isCreate) {
      this.supplierService.UpdateSupplier(sd).subscribe((response: SupplierDto) => {
        this.router.navigate([SupplierConst.SupplierPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(SupplierConst.updateMessage, 'success', 'right');
      });
    }
    else {
      this.supplierService.saveSupplier(sd).subscribe((response: SupplierDto) => {
        this.router.navigate([SupplierConst.SupplierPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(SupplierConst.insertMessage, 'success', 'right')
      });
    }
  }

  cancel() {
    this.router.navigate([SupplierConst.SupplierPath], { relativeTo: this.route.parent });
  }

}

