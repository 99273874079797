import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CELL_CONTEXT, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { CommonService } from 'src/app/core/services/common.service';
import { MaterialInfoService } from 'src/app/features/setup/services/material-info.service';
import { saveAs } from 'file-saver';
import { PlantDetailsDto } from '../../../models/common/plant-detail.model';
import { SPKDto } from 'src/app/features/setup/models/spk/spk.model';
import * as JSZip from 'jszip';
import { ApprovalDetailService } from '../../../services/approvalDetail/approval-details.service';
import * as XLSX from 'xlsx';
import { TempActionService } from '../../../services/temp-action.service';
import { BlobURlsDetails } from 'src/app/core/constants/commonConst/blobUrl.cons';

@Component({
  selector: 'app-extn-mrp-update-excel-template',
  templateUrl: './extn-mrp-update-excel-template.component.html',
  styleUrls: ['./extn-mrp-update-excel-template.component.scss']
})
export class ExtnMrpUpdateExcelTemplateComponent implements OnInit, OnDestroy {
  requestNumber: string = '';
  isNew: boolean = true;
  navData: any;
  materialList: [] = [];
  enteredmaterialNumbers: string = '';
  validMaterialNumbers: string[] = [];
  buName: string = '';
  excelFilePath: string = `ExtensionMRPUpdateTemplate`;
  plantList: PlantDetailsDto[] = [];
  SPKList: SPKDto[] = [];
  @ViewChild('myGrid', { static: true }) myGrid!: GridComponent;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private materialInfoService: MaterialInfoService,
    private approvalDetailService: ApprovalDetailService, private tempActionService: TempActionService) {
    this.buName = this.commonService.getUserBUName();
    this.navData = this.router.getCurrentNavigation()?.extras.state;

    if (!this.commonService.isNullOrEmptyOrUndefined(this.navData)) {
      this.requestNumber = this.navData['requestNumber'];
      this.isNew = this.navData['isNew'];
      sessionStorage.setItem('sarnewaction', JSON.stringify(this.navData));
    }
    else {
      const sessionStorageData = JSON.parse(sessionStorage.getItem('sarnewaction')!);
      if (!this.commonService.isNullOrEmptyOrUndefined(sessionStorageData)) {
        this.requestNumber = sessionStorageData.requestNumber;
        this.isNew = sessionStorageData.isNew
        this.navData = sessionStorageData;
      }
    }
  }

  ngOnInit(): void {
    this.excelFilePath = this.excelFilePath + this.commonService.getUserBUName();
    this.getMaterialList();
  }

  getMaterialList() {
    this.materialInfoService.getAllMaterialNumbers<[]>().subscribe((outputDtata: []) => {
      this.materialList = outputDtata;
    });
  }
  navBack() {
    this.router.navigate(['./newaction'], { state: { isNew: this.isNew, requestId: this.navData.requestId, requestNumber: this.navData.requestNumber, requestIssueId: this.navData.requestIssueId, actionNumber: this.navData.actionNumber, changeType: this.navData.changeType }, relativeTo: this.route.parent });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('sarnewaction');
  }
  onValidate() {
    let data: string[] = this.enteredmaterialNumbers.split(/[\n\t\s,]+/).filter(num => num !== '');
    let correctMaterialNumbers: string[] = [];
    let wrongMaterialNumbers: string[] = [];
    let notificationMessage: string = '';
    let messageType: 'success' | 'warning' | 'error' = 'success';
    for (let num of data) {
      if (this.materialList.findIndex(e => e === num) !== -1) {
        correctMaterialNumbers.push(num);
      }
      else {
        wrongMaterialNumbers.push(num);
      };
    }
    this.validMaterialNumbers = this.validMaterialNumbers.concat(correctMaterialNumbers);

    if (wrongMaterialNumbers.length === 0) {
      notificationMessage = correctMaterialNumbers.length + ' items found';
    }
    else {
      if (correctMaterialNumbers.length === 0) {
        notificationMessage = 'No item numbers were found out of ' + data.length + ' entries';
        messageType = 'error';
      }
      else {
        let wrongEntries = wrongMaterialNumbers.join(',');
        notificationMessage = correctMaterialNumbers.length + ' items found, but ' + wrongEntries +
          ' entries could not be found and have been ignored';
        messageType = 'warning';
      }
    }
    this.commonService.showNotificationMessage(notificationMessage, messageType, 'center');
    this.enteredmaterialNumbers = '';
  }
  clearValidMatNumbers() {
    this.validMaterialNumbers = [];
  }
  removeRow(index: number) {
    this.validMaterialNumbers.splice(index, 1);
  }
  public downloadExcelTemplate(): void {
    if (this.validMaterialNumbers.length === 0) {
      this.commonService.showNotificationMessage('Please add the validated material numbers', 'error', 'center');
    }
    else {
      this.newFileDownload();
    }
  }

  newFileDownload() {
    const zip = new JSZip();
    type newType = XLSX.WorkSheet;
    let matNumbers = this.validMaterialNumbers.map(x => { return { 'Material Number': x } })
    const worksheet: newType = XLSX.utils.json_to_sheet(matNumbers);

    this.approvalDetailService.getUnitPriceList(this.validMaterialNumbers, this.commonService.getUserBUName()).subscribe((response: any) => {
      let listData = [];
      for (let listItem of response) {
        let unitCost = listItem.unitPrice === '0.00' ? 0 : listItem.unitPrice;
        let item: any = {
          'Material Number | Plant Code': listItem.materialNumber + '|' + listItem.plantId,
          'Material Number': listItem.materialNumber, 'Plant Code': listItem.plantId, 'Unit Cost': unitCost
        };
        listData.push(item);
      }

      let listSheet1: newType = XLSX.utils.json_to_sheet(listData);
      let workbook: XLSX.WorkBook = {
        Sheets: { 'data': worksheet, 'list': listSheet1 },
        SheetNames: ['data', 'list']
      };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      zip.file("ExtensionMRPUpdate_Raw_Data.xlsx", blob);
      this.tempActionService.fetchBlobUrl(this.excelFilePath).subscribe((responseUrl: string) => {
        this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
          const blobdownload = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
          zip.file(this.requestNumber + '-' + 'ExtensionMRPUpdateTemplate_' + this.commonService.getUserBUName() + '.xlsx', blobdownload)
          this.tempActionService.fetchBlobUrl(BlobURlsDetails.extensionMRPUpdateTemplateUserGuide).subscribe((responseUrl: string) => {
            this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
              const blobdownload = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
              zip.file(BlobURlsDetails.extensionMRPUpdateTemplateUserGuide + '.docx', blobdownload);
              zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, this.requestNumber + ' ' + "ExternsionMRPUpdate.zip");
                this.router.navigate(['./newaction'], { state: { isNew: this.isNew, requestId: this.navData.requestId, requestNumber: this.navData.requestNumber, requestIssueId: this.navData.requestIssueId, actionNumber: this.navData.actionNumber, changeType: this.navData.changeType }, relativeTo: this.route.parent });
              });
            })
          })
        })
      });
    });
  }
}
