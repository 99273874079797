<div class="MainPopup" style="width: 500px">
    <div class="text-right" style="cursor: pointer;"><a (click)="close('c')"><i class="fa fa-times"></i></a>
    </div>
    <fieldset class=" w-90 fieldset">
        <legend>Select MMD details to Approve</legend>
        <section class="d-flex">
            <div class="mt-10 mx-5 w-50">
                <kendo-label text="Assign To Group"><span style="color:red"> *</span></kendo-label><br>
                <kendo-dropdownlist class="mt-5" [data]="assignToGroups" textField="name" valueField="id"
                    (selectionChange)="selectAssignToGroup($event)" [defaultItem]="defaultAssignToGroup"
                    [valuePrimitive]="true">
                </kendo-dropdownlist>
                <span *ngIf="!isGoupSelected" class="noValue">
                    Required field!
                    Please select group
                </span>
                <br />
            </div>
            <div class="mt-10 mx-5 w-50">
                <kendo-label text="Assign To User"></kendo-label><br>
                <kendo-dropdownlist [(ngModel)]="selectedUser" [disabled]="!enabledAssignToUser" class="mt-5"
                    [data]="assignToUsers" [defaultItem]="defaultAssignToUser" textField="name" valueField="id"
                    (selectionChange)="selectAssignToUser($event)" [valuePrimitive]="true">
                </kendo-dropdownlist>
            </div>
        </section>
        <div class="mt-10 mx-5 w-100">
            <button kendoButton class="primary-button ml-5 f-size-150 backButton" (click)="close('c')"> Back
            </button>
            <button kendoButton class="primary-button ml-5 f-size-150 successButton" (click)="close('s')">
                Confirm And Approve
            </button>
        </div>
    </fieldset>
</div>