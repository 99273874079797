<!-- Create / Update setup pages -->
<div  class="contentBox">
    <h2 class="heading content-top">{{headerText}}</h2>
    <section class="w-100">
        <form [formGroup]="sarUserForm">
            
            <div class="mt-10">
                <kendo-label text="CAI"></kendo-label>
                <br>
                <kendo-maskedtextbox class="mt-5 w-25" name="fCai" [required]="hasCAIError" #cai id="fcai"
                                formControlName="cai" [value]="InputCAI" [(ngModel)]="InputCAI"
                                (valueChange)="caiValueChange($event)"
                                [prompt]="''"
                                [disabled]="!isCreate" (blur)="handleBlur();"
                                [mask]="mask">
                </kendo-maskedtextbox>
 
                <div *ngIf="hasCAIError">
                    <kendo-formerror>{{CAIError}}</kendo-formerror>
                </div>
            </div>

            <div class="mt-10" *ngIf="!isCreate">
                <kendo-label text="Username"></kendo-label>
                <br>
                <kendo-textbox class="mt-5 w-25" name="fUsername" [required]="hasUsernameError" formControlName="username" [clearButton]="true" [disabled]="true"></kendo-textbox>
                <div *ngIf="hasUsernameError">
                    <kendo-formerror>Please enter Username</kendo-formerror>
                </div>
            </div>

            <div class="mt-10 w-25">
                <kendo-label text="Business Unit"></kendo-label>
                <br>
                <kendo-dropdownlist name="fBusinessUnit" 
                                    [required]="hasBusinessUnitError"
                                    formControlName="businessUnitname"
                                    [data]="businessUnitsList"
                                    [defaultItem]="{ name: 'Select SAR Buiness Unit'}"
                                    textField="name"
                                    valueField="name"
                                    (selectionChange)="selectBusinessUnit($event)"
                                    [valuePrimitive]="true">
                </kendo-dropdownlist>
                <div *ngIf="hasBusinessUnitError">
                    <kendo-formerror>Please select user Business Unit</kendo-formerror>
                </div>
            </div>

            <div class="mt-10 w-25">
                <kendo-label text="ESAR User Access Role."></kendo-label>
                <br>
                <kendo-multiselect name="fRole" 
                                    [filterable]="true"
                                    [(ngModel)]="roleDtos"
                                    [required]="hasRoleError"
                                    formControlName="roleDtos"
                                    [data]="roleList"
                                    textField="name"
                                    valueField="id"
                                    (valueChange)="selectRole($event)">
                </kendo-multiselect>

                <div *ngIf="hasRoleError">
                    <kendo-formerror>Please select ESAR role access group</kendo-formerror>
                </div>
            </div>

            <div class="mt-10 w-25">
                <kendo-label text="ESAR User Application Groups"></kendo-label>
                <br>
                <kendo-multiselect name="fGroup" 
                                    [required]="hasGroupError"
                                    formControlName="groupDtos"
                                    [(ngModel)]="groupDtos"
                                    [data]="groupList"
                                    textField="name"
                                    valueField="id"
                                    (valueChange)="selectGroup($event)">
                </kendo-multiselect>
                <div *ngIf="hasGroupError">
                    <kendo-formerror>Please select esar application group</kendo-formerror>
                </div>
            </div> 

            <div class="mt-10">
                <kendo-label text="Is Active"></kendo-label>
                <br>
                <input class="checkbox" type="checkbox" id="isActiveCheck" [checked]="isActive" formControlName="isActive" />
            </div>
            <div class="text-right">
                <kendo-button class="border-button mr-20" (click)="cancel()">Cancel</kendo-button>
                <kendo-button class="primary-button" [disabled]="hasCAIError" (click)="save()">Save</kendo-button>
            </div>
        </form>
    </section>
    </div>