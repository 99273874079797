import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FeaturesModule } from './modules/features.module';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './modules/core.module';
import { RootComponent } from './core/components/root/root.component';
import { KendoModule } from './modules/kendo.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogUploadComponent } from './core/components/dialog-upload/dialog-upload.component';
import { MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import { RoleService } from './features/setup/services/role.service';
import { ConfigRoleComponent } from './core/components/config-role/config-role.component';
import { HttpConfigInterceptor } from './core/Interceptors/httpconfig.interceptor';
import { BulkActionComponent } from './core/components/bulk-action/bulk-action.component';
import { ApprovalAssignmentComponent } from './features/sar/components/approval-management/approval-assignment/approval-assignment.component';
import { SarConfirmationBoxComponent } from './core/components/sar-confirmation-box/sar-confirmation-box.component';
import { ReAssignItemComponent } from './features/sar/components/approval-management/re-assign-item/re-assign-item.component';
import { BulkCancelComponent } from './core/components/bulk-cancel/bulk-cancel.component';
import { UserGuideComponent } from './features/userguide/components/user-guide/user-guide.component';
import { ReActivateComponent } from './features/sar/components/change-sar/re-activate/re-activate.component';
import { ChangeDataExcelTemplateComponent } from './features/sar/components/change-sar/change-data-excel-template/change-data-excel-template.component';
import { InvalidPermissionsComponent } from './core/components/invalid-permissions/invalid-permissions.component';
import { UserInfoComponent } from './core/components/user-info/user-info.component';

@NgModule({
  declarations: [
    RootComponent,
    BulkCancelComponent,
    UserGuideComponent,
    ChangeDataExcelTemplateComponent,
    InvalidPermissionsComponent,
    UserInfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    KendoModule,
    FeaturesModule,
    BrowserAnimationsModule,
    CoreModule
  ],
  entryComponents: [DialogUploadComponent, ConfigRoleComponent, BulkActionComponent,
    ApprovalAssignmentComponent, SarConfirmationBoxComponent, ReAssignItemComponent,
    ReActivateComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }, RoleService
  ],
  bootstrap: [RootComponent, MsalRedirectComponent]
})
export class AppModule { }
