import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { ValuationTypeConst } from 'src/app/core/constants/valuationtype.const';
import { CommonService } from 'src/app/core/services/common.service';
import { ValuationTypeDto } from '../../../models/valuationtype/valuationtype.model';
import { ValuationTypeService } from '../../../services/valuationtype.service';

@Component({
  selector: 'app-manage-valuation-type',
  templateUrl: './manage-valuation-type.component.html',
  styleUrls: ['./manage-valuation-type.component.css']
})
export class ManageValuationTypeComponent implements OnInit {
  id = 0;
  isCreate = false;
  name = '';
  isActive = false;
  headerText = ValuationTypeConst.createText;
  hasNameError = false;
  hasShortNameError = false;
  hasError = false;
  @ViewChild('vtForm') myForm!: NgForm;

  constructor(public router: Router, private valuationTypeService: ValuationTypeService,
    private route: ActivatedRoute, public commonService: CommonService) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.isCreate = stateData['isCreate'];
    this.name = stateData['name'];
    this.isActive = stateData[SharedConst.isActive];
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.name;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }

  cleareError() {
    this.hasNameError = false;
    this.hasShortNameError = false;
    this.hasError = false;
  }
  save() {
    this.cleareError();
    const vt = new ValuationTypeDto();
    vt.id = this.id;
    vt.name = this.name;
    vt.isActive = this.isActive;
    vt.createdBy = this.commonService.getUserEmailId();
    vt.modifiedBy = this.commonService.getUserEmailId();
    if (!this.name) {
      this.hasNameError = true;
      this.hasError = true;
      this.myForm.form.get('fVtName')?.markAllAsTouched();
    }
    if (this.hasError) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'right');
      return false;
    }
    if (!this.isCreate) {
      this.valuationTypeService.UpdateValuationType(vt).subscribe((outputDtata: ValuationTypeDto) => {
        this.commonService.showNotificationMessage(ValuationTypeConst.updateMessage, 'success', 'right')
        this.router.navigate([ValuationTypeConst.VTPath], { relativeTo: this.route.parent });
      });
    }
    else {
      this.valuationTypeService.saveValuationType(vt).subscribe((outputDtata: ValuationTypeDto) => {
        this.commonService.showNotificationMessage(ValuationTypeConst.insertMessage, 'success', 'right')
        this.router.navigate([ValuationTypeConst.VTPath], { relativeTo: this.route.parent });
      });
    }
  }

  cancel() {
    this.router.navigate([ValuationTypeConst.VTPath], { relativeTo: this.route.parent });
  }
}
