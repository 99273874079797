
export const StorageConditionConst = {
    SCApiName:'api/StorageConditions',    
    SCPath: 'StorageCondition',
    manageSCPath: 'ManageStorgaeCondition',
    createText:'New Sotrage Condition',
    insertMessage:'Storage condition code has been added successfully',
    updateMessage:'Storage condition code has been updated successfully',
    bulkInsertMessage:'Storage conditions are successfully importd',
    nameError: 'Name is required',
    searchFieldName: 'name',
    code: 'code'
    
  };