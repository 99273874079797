import { Component, OnInit } from '@angular/core';
import { RoleConstant } from '../../constants/role.constants';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
  activeTab = 'sar'
  isExpanded = false;
  isAuthenticated = true;
  constructor(private commonService: CommonService) {

  }

  ngOnInit() {
    let splittedUrl = window.location.href.split('/');
    let url = splittedUrl[splittedUrl.length - 1];
    if (url !== '' && url === 'sar' || url === 'allsar' || url === 'setup') {
      this.activeTab = url;
    }
    else {
      this.activeTab = splittedUrl[splittedUrl.length - 2];
    }
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  loadApplication(value: boolean) {
    this.isAuthenticated = value;
  }
  isAdmin() {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      return true;
    }
    return false;
  }
}