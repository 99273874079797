export class LineAssignmentDto {
    requestId?: number;
    requestIssueId?: number;
    requestIssueTypeId?: number;
    businessUnitId?: number;
    assignedGroupId?: number | null;
    assignedUserId?: number | null;
    state?: number;
    comment?: string;
    isGeneralComment?: boolean;
    createdBy?: string;
    modifiedBy?: string;
    attachments?: File[];
    isMixedValues?: boolean = false;
    requestLineIds!: number[];
}