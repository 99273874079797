export class RequestDetailsDto{
    id: number = 0;
    trackingNumber: string='';
    tittle: string ='';
    priorityId:number =0;     
    priorityName:string|undefined;
    justification:string='';
    ownerId:number|undefined;
    assetId?:number;
    assetName?: string;
    ownerName:string|undefined;
    requestStateId:number =0;
    requestStatName:string|undefined;
    createdDateTime:Date|undefined;
    modifiedDateTime:Date|undefined;
    isActive:boolean|undefined;        
    createdBy:string|undefined;
    modifiedBy:string|undefined;
    createdByFullName:string|undefined;    
}