import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { ChangeDataActionResponseDto } from '../../../models/change-sar/change-data-action-response.model';
import { ExtensionMRPUpdateService } from '../../../services/extensionMRPupdate.service';
import { RequestExtMRPUpdateActionItemDetailsDto } from '../../../models/change-sar/extension-MRP-update-Item-list.model';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { ExtMRPUpdateActionItem } from '../../../models/change-sar/extension-MRP-update-line-item.model';
import { ApprovalMMDActionDetail, ApprovalSAActionDetail } from '../../../models/approval-details/action-detail.model';
import { BulkActionComponent } from 'src/app/core/components/bulk-action/bulk-action.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { ApprovalAssignmentComponent } from '../approval-assignment/approval-assignment.component';
import { LineAssignmentDto } from '../../../models/common/line-assignment.model';
import { ChangeDataConst } from 'src/app/core/constants/change-data.const';
import { ExtnMRPUpdateApprovalExcelColumn } from 'src/app/core/constants/excel-columns-const';

@Component({
  selector: 'app-extn-mrp-update-approval',
  templateUrl: './extn-mrp-update-approval.component.html',
  styleUrls: ['./extn-mrp-update-approval.component.scss']
})
export class ExtnMrpUpdateApprovalComponent {
  requestExtMRPUpdateActionItemDetails: RequestExtMRPUpdateActionItemDetailsDto | undefined;
  @Input() public requiredParams: NewActionInputParam = {};
  @Input() public currentActionName: string = '';
  @Input() public dummyInput: number = 0;

  @Output() saActionEvent = new EventEmitter<ApprovalSAActionDetail>();
  @Output() mmdActionEvent = new EventEmitter<ApprovalMMDActionDetail>();
  @Output() mmdBulkActionEvent = new EventEmitter<ApprovalMMDActionDetail[]>();
  @Output() saBulkActionEvent = new EventEmitter<ApprovalSAActionDetail[]>();
  mySelection: number[] = [];
  isBulk: boolean = true;
  extnMRPUpdateApprovalExcelColumn: any = ExtnMRPUpdateApprovalExcelColumn;
  constructor(public router: Router,
    private route: ActivatedRoute,
    private extensionMRPUpdateService: ExtensionMRPUpdateService,
    private dialogService: DialogService,
    public commonService: CommonService) { }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.getExtnMRPUpdateDetail();
  }

  openPopup() {
    const dialog: DialogRef = this.dialogService.open({
      content: BulkActionComponent
    });
    const inc = dialog.content.instance as BulkActionComponent;
    inc.isMMD = this.currentActionName === 'mmd-action' ? true : false;
    dialog.result.subscribe((r: any) => {
      if (this.commonService.isNullOrEmpty(r.action) || r.action === 'Close') {
        return false;
      }
      this.bulkAction(r.action, r.comment, r.lineItemDtail);
    });
  }

  bulkAction(actionType: string, comment: string, lineItemDtail: any) {
    if (this.currentActionName === 'mmd-action') {
      let approvalMMDActionDetail: ApprovalMMDActionDetail[] = [];
      for (let selectedValue of this.mySelection) {
        let dataItem = this.requestExtMRPUpdateActionItemDetails!.items[selectedValue];
        if (dataItem.status !== 'Pending MMD') {
          continue;
        }
        dataItem.comment = comment;
        let approvalActionDetail = this.getmmdValues(dataItem);
        if (actionType === 'Cancel') {
          approvalActionDetail.mmdAction = 3;
        }
        if (actionType === 'Reject') {
          approvalActionDetail.mmdAction = 1;
        }
        if (actionType === 'Complete') {
          approvalActionDetail.mmdAction = 2;
        }
        approvalMMDActionDetail.push(approvalActionDetail);
      }
      this.mySelection = [];
      this.mmdBulkActionEvent.emit(approvalMMDActionDetail);
    }
    if (this.currentActionName === 'sa-action') {
      let approvalSAActionDetail: ApprovalSAActionDetail[] = [];
      for (let selectedValue of this.mySelection) {
        let dataItem = this.requestExtMRPUpdateActionItemDetails!.items[selectedValue];
        if (dataItem.status !== ApprovalDetailsConstants.PendingStockingApproval) {
          continue;
        }
        dataItem.comment = comment;
        let approvalActionDetail = this.getsaValues(dataItem);
        if (actionType === 'Cancel') {
          approvalActionDetail.stockApproverAction = 3;
          approvalActionDetail.assignedGroupId = null;
          approvalActionDetail.assignedUserId = null;
        }
        if (actionType === 'Reject') {
          approvalActionDetail.stockApproverAction = 1;
          approvalActionDetail.assignedGroupId = null;
          approvalActionDetail.assignedUserId = null;
        }
        if (actionType === 'Complete') {
          approvalActionDetail.stockApproverAction = 2;
          approvalActionDetail.assignedGroupId = lineItemDtail.assignedGroupId;
          approvalActionDetail.assignedUserId = lineItemDtail.assignedUserId;
        }
        approvalSAActionDetail.push(approvalActionDetail);
      }
      this.mySelection = [];
      this.saBulkActionEvent.emit(approvalSAActionDetail);
    }
  }

  clickCheckBox(e: SelectionEvent) {
    this.isBulk = true;
    for (let selected of this.mySelection) {
      if (this.requestExtMRPUpdateActionItemDetails!.items[selected].status === 'Pending MMD' ||
        this.requestExtMRPUpdateActionItemDetails!.items[selected].status === 'Pending Stocking Approval'
      ) {
        this.isBulk = false;
        break;
      }
    }
  }

  assignEmpty(dataItem: any) {
    dataItem.comment = dataItem.comment == undefined ? '' : dataItem.comment
  }

  getSAButtonText(): string {
    if (this.currentActionName === 'sa-action') {
      return 'Approve';
    }
    else {
      return 'Complete';
    }
  }
  getExtnMRPUpdateDetail() {
    this.extensionMRPUpdateService.getMRPUpdateDetail(this.requiredParams.requestId!,
      this.requiredParams.requestIssueId!).subscribe((outputDtata: RequestExtMRPUpdateActionItemDetailsDto) => {
        outputDtata.items = outputDtata.items.filter(x => x.status !== 'Draft');
        this.requestExtMRPUpdateActionItemDetails = outputDtata;
        this.mySelection = [];
        let mmdItems: ExtMRPUpdateActionItem[] = [];
        if (this.currentActionName === 'mmd-action') {
          for (let item of this.requestExtMRPUpdateActionItemDetails.items) {
            if (item.extMRPUpdateActionItemStorageLocations!.reduce((sum, current) => sum + current.maximum!, 0) == 0
              || item.status === 'Completed') {
              mmdItems.push(item);
            }
            else {
              if (item.status === 'Pending MMD') {
                mmdItems.push(item);
              }
            }
          }
          this.requestExtMRPUpdateActionItemDetails.items = mmdItems;
          this.requestExtMRPUpdateActionItemDetails!.items = this.requestExtMRPUpdateActionItemDetails.items.filter(x =>
            x.status !== 'Rejected' && x.status != 'Cancelled');
        }
        if (this.currentActionName === 'sa-action') {
          for (let item of this.requestExtMRPUpdateActionItemDetails.items) {
            if (item.extMRPUpdateActionItemStorageLocations!.reduce((sum, current) => sum + current.maximum!, 0) > 0
              && item.status !== 'Pending MMD') {
              mmdItems.push(item);
            }
          }
          this.requestExtMRPUpdateActionItemDetails.items = mmdItems;
          this.requestExtMRPUpdateActionItemDetails!.items = this.requestExtMRPUpdateActionItemDetails.items.filter(x =>
            x.status !== 'Rejected' && x.status != 'Cancelled' && x.status !== 'Completed');
        }
      });

  }

  cancel(dataItem: ExtMRPUpdateActionItem) {
    dataItem.isCommentError = false;
    if (this.commonService.isNullOrEmpty(dataItem.comment!)) {
      dataItem.isCommentError = true;
      this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          if (this.currentActionName === 'mmd-action') {
            let approvalActionDetail = this.getmmdValues(dataItem);
            approvalActionDetail.mmdAction = 3;
            this.mmdActionEvent.emit(approvalActionDetail);
          }

          if (this.currentActionName === 'sa-action') {
            let approvalActionDetail = this.getsaValues(dataItem);
            approvalActionDetail.assignedGroupId = null;
            approvalActionDetail.assignedUserId = null;
            approvalActionDetail.stockApproverAction = 3;
            this.saActionEvent.emit(approvalActionDetail);
          }
        }
      });
  }

  reject(dataItem: ExtMRPUpdateActionItem) {
    dataItem.isCommentError = false;
    if (this.commonService.isNullOrEmpty(dataItem.comment!)) {
      dataItem.isCommentError = true;
      this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    this.commonService.showConfirmation('Rejection', ApprovalDetailsConstants.rejectMessage)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          if (this.currentActionName === 'mmd-action') {
            let approvalActionDetail = this.getmmdValues(dataItem);
            approvalActionDetail.mmdAction = 1;
            this.mmdActionEvent.emit(approvalActionDetail);
          }

          if (this.currentActionName === 'sa-action') {
            let approvalActionDetail = this.getsaValues(dataItem);
            approvalActionDetail.stockApproverAction = 1;
            approvalActionDetail.assignedGroupId = null;
            approvalActionDetail.assignedUserId = null;
            this.saActionEvent.emit(approvalActionDetail);
          }
        }
      });


  }

  complete(dataItem: ExtMRPUpdateActionItem) {
    if (this.currentActionName === 'mmd-action') {
      this.commonService.showConfirmation('Complete', ApprovalDetailsConstants.completeMessage)
        .subscribe((res: any) => {
          if (res.action === 'yes') {
            let approvalActionDetail = this.getmmdValues(dataItem);
            approvalActionDetail.mmdAction = 2;
            this.mmdActionEvent.emit(approvalActionDetail);
          }
        })
    }
    if (this.currentActionName === 'sa-action') {
      let approvalActionDetail = this.getsaValues(dataItem);
      const dialog: DialogRef = this.dialogService.open({
        content: ApprovalAssignmentComponent
      });
      dialog.result.subscribe((r: any) => {
        if (r.actionType == 's') {
          approvalActionDetail.assignedGroupId = r.lineItemDtail.assignedGroupId;
          approvalActionDetail.assignedUserId = this.commonService.isNullOrEmptyOrUndefined(
            r.lineItemDtail.assignedUserId) ? null : r.lineItemDtail.assignedUserId;
          approvalActionDetail.stockApproverAction = 2;
          this.saActionEvent.emit(approvalActionDetail);
        }
      });

    }
  }

  getmmdValues(dataItem: ExtMRPUpdateActionItem): ApprovalMMDActionDetail {
    let approvalMMDActionDetail: ApprovalMMDActionDetail = new ApprovalMMDActionDetail()
    approvalMMDActionDetail.comment = dataItem.comment;
    approvalMMDActionDetail.createdBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.enrichedDescription = '';
    approvalMMDActionDetail.materialNumber = '';
    approvalMMDActionDetail.shortDescription = ''
    approvalMMDActionDetail.modifiedBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.requestIssueId = this.requiredParams.requestIssueId!;
    approvalMMDActionDetail.requestIssueTypeId = 3;
    approvalMMDActionDetail.requestLineId = dataItem.requestLineId;
    approvalMMDActionDetail.requestId = this.requiredParams.requestId!;
    return approvalMMDActionDetail;
  }

  getsaValues(dataItem: ExtMRPUpdateActionItem): ApprovalSAActionDetail {
    let approvalMMDActionDetail: ApprovalSAActionDetail = new ApprovalSAActionDetail()
    approvalMMDActionDetail.comment = dataItem.comment;
    approvalMMDActionDetail.createdBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.modifiedBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.requestIssueId = this.requiredParams.requestIssueId!;
    approvalMMDActionDetail.requestIssueTypeId = 3;
    approvalMMDActionDetail.requestLineId = dataItem.requestLineId;
    approvalMMDActionDetail.requestId = this.requiredParams.requestId!;
    return approvalMMDActionDetail;
  }
  public exportToExcel() {
    let finalAddNewItemList: ExtMRPUpdateActionItem[] = [];
    let tempAddNewItem: ExtMRPUpdateActionItem[] = JSON.parse(JSON.stringify(this.requestExtMRPUpdateActionItemDetails!.items));
    for (let tempDataObj of tempAddNewItem) {
      let tempSLObj = JSON.parse(JSON.stringify(tempDataObj));
      tempDataObj.extMRPUpdateActionItemStorageLocations?.forEach((slValue: any) => {
        tempSLObj.extMRPUpdateActionItemStorageLocations = [];
        tempSLObj.extMRPUpdateActionItemStorageLocations.push(slValue);
        finalAddNewItemList.push(JSON.parse(JSON.stringify(tempSLObj)));
      })
    }
    this.commonService.exportAsExcelFile(finalAddNewItemList, this.extnMRPUpdateApprovalExcelColumn, this.requiredParams.requestNumber + ' ' + 'ExtensionMRPUpdate')
  }
}
