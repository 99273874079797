import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SPKConst } from 'src/app/core/constants/spk.const';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { SPKDto } from '../models/spk/spk.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
    providedIn: 'root',
})
export class SPKService extends HttpAbstractService<SPKDto> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getSPKAll() {
        return this.getAll(SPKConst.SPKApiName);
    }
    saveSPK(data: SPKDto) {
        return this.post(SPKConst.SPKApiName, data);
    }
    updateSPK(data: SPKDto) {
        return this.put(SPKConst.SPKApiName, data.id, data);
    }
    importExcelData(data: SPKDto[]) {
        return this.postAll(SPKConst.SPKApiName + SharedConst.importApiName, data);
    }
}