import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-re-activate',
  templateUrl: './re-activate.component.html',
  styleUrls: ['./re-activate.component.scss']
})
export class ReActivateComponent {

  plantNames: any[] = [];
  slNames: any[] = [];
  constructor(public dialog: DialogRef) { }

  close(actionName: string) {
    this.dialog.close({ action: actionName });

  }
}
