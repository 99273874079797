import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SarApplicationGroupConstant } from 'src/app/core/constants/sar-application-group.constants';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { SARApplicationGroup } from '../models/sarapplicationgroup/sarapplicationgroup.model';

@Injectable({
  providedIn: 'root'
})
export class SarApplicationGroupService extends HttpAbstractService<SARApplicationGroup> {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
      super(http);
  }
  getGroup() {
      return this.getAll(SarApplicationGroupConstant.SARGroup);
  }
  saveGroup(data: SARApplicationGroup) {
      return this.post(SarApplicationGroupConstant.SARGroup, data);
  }
  updateGroup(data: SARApplicationGroup) {
      return this.put(SarApplicationGroupConstant.SARGroup, data.id, data);
  }
  importExcelData(data: SARApplicationGroup[]) {
    return this.postAll(SarApplicationGroupConstant.SARGroup+ SharedConst.importApiName, data);
  }

}

