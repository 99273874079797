import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ValuationTypeConst } from 'src/app/core/constants/valuationtype.const';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { ValuationTypeDto } from '../models/valuationtype/valuationtype.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
    providedIn: 'root',
})
export class ValuationTypeService extends HttpAbstractService<ValuationTypeDto> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getValuationTypeAll() {
        return this.getAll(ValuationTypeConst.VTApiName);
    }
    saveValuationType(data: ValuationTypeDto) {
        return this.post(ValuationTypeConst.VTApiName, data);
    }
    UpdateValuationType(data: ValuationTypeDto) {
        return this.put(ValuationTypeConst.VTApiName, data.id, data);
    }
    importExcelData(data: ValuationTypeDto[]) {
        return this.postAll(ValuationTypeConst.VTApiName + SharedConst.importApiName, data);
    }
}