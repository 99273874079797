import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SegmentConst } from 'src/app/core/constants/segment.const';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { SegmentDto } from '../models/segment/segment.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
    providedIn: 'root',
})
export class SegmentService extends HttpAbstractService<SegmentDto> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getsegments() {
        return this.getAll(SegmentConst.segment);
    }
    savesegment(data: SegmentDto) {
        return this.post(SegmentConst.segment, data);
    }
    updatesegment(data: SegmentDto) {
        return this.put(SegmentConst.segment, data.id, data);
    }
    importExcelData(data: SegmentDto[]) {
        return this.postAll(SegmentConst.segment + SharedConst.importApiName, data);
    }
}