import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EquipmentDto } from './equipment.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentDataService {
  private data = new Subject<EquipmentDto>();
  data$ = this.data.asObservable();

  setData(data: EquipmentDto) {
    this.data.next(data);
  }
}