<div class="sar-contentBox">
    <h2 class="heading content-top">SAR allocation</h2>
    <section class="sar-allocation-component">
        <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
        </ng-template>
        <section>
            <div class="select-container">
                <div>
                    <kendo-label text="Select state"><span style="color:red"> *</span></kendo-label>
                    <kendo-dropdownlist [(ngModel)]="selectedState" class="dropdown" [data]="states" textField="name"
                        valueField="id" [defaultItem]="stateSelectPlaceHolder"
                        (selectionChange)="stateSelectionChange($event)">
                    </kendo-dropdownlist>
                </div>
                <div>
                    <kendo-label text="Select Action type"></kendo-label>
                    <kendo-dropdownlist [(ngModel)]="selectedActionType" class="dropdown" [data]="actionTypes"
                        textField="name" valueField="id" [defaultItem]="actionSelectPlaceHolder"
                        [disabled]="actionTypeSelectDisabled" (selectionChange)="actionTypeSelectionChange($event)">
                    </kendo-dropdownlist>
                </div>
                <div>
                    <kendo-label text="Select Asset"></kendo-label>
                    <kendo-dropdownlist [(ngModel)]="selectedAsset" class="dropdown" [data]="assets" textField="name"
                        valueField="id" [defaultItem]="assetSelectPlaceHolder" [disabled]="assetSelectDisabled"
                        (selectionChange)="assetSelectionChange($event)">
                        <option *ngFor="let asset of assets">{{asset}}</option>
                    </kendo-dropdownlist>
                </div>
                <div class="select-button">
                    <button kendoButton class="primary-button" (click)='onSearchClick()'
                        [disabled]="searchButtonDisabled">Search</button>
                    <button kendoButton (click)='onResetClick()'>Reset</button>
                </div>

            </div>
        </section>
        <div class="button-container">
            <div class="left-button-container">
                <button kendoButton (click)='onClearFilterClick()'>Clear Filters</button>
                <button kendoButton (click)='onClearSortingClick()'>Clear Sorting</button>
            </div>
            <div class="right-button-container">
                <button kendoButton class="primary-button" *ngIf="!allocateClicked" [disabled]="allocateButtonDisabled"
                    (click)='onAllocateClick()'>Allocate</button>
                <kendo-dialog title="Allocate" *ngIf="allocateClicked" (close)="close()" [height]="280" [width]="420">
                    <div class="allocate-items-container">
                        <kendo-label text="Assign to group"><span style="color:red"> *</span></kendo-label>
                        <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedGroup" [data]="groups"
                            [defaultItem]="actionSelectPlaceHolder" textField="name" valueField="id"
                            (selectionChange)="groupSelectionChange($event)">
                        </kendo-dropdownlist>

                        <kendo-label text="Assign to sub group / user"><span style="color:red"> *</span></kendo-label>
                        <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedUserGroup" [data]="groupUsers"
                            [defaultItem]="userGroupSelectPlaceHolder" textField="name" valueField="id"
                            (selectionChange)="userGroupSelectionChange($event)" [disabled]="userGroupSelectDisabled">
                        </kendo-dropdownlist>
                        <br>
                    </div>
                    <kendo-dialog-actions>
                        <button kendoButton (click)="close()">Cancel</button>
                        <button kendoButton (click)="submitAllocationClick(selectedGroup?.id, selectedUserGroup?.id)"
                            class="primary-button" [disabled]="submitButtonDisabled">Submit</button>
                    </kendo-dialog-actions>
                </kendo-dialog>
                <kendo-dialog class="error-dialog" title="Error" *ngIf="submitAllocationErrorDialog"
                    (close)="closeError()" [minWidth]="250" [width]="100">
                </kendo-dialog>
            </div>
        </div>
        <br><br>
        <kendo-grid #grid class="kendo-grid" [kendoGridBinding]="gridData" [loading]="loading" [pageable]="true"
            (pageChange)="pageChange($event)"
            [pageSize]="pageSize" [filterable]="true" [(filter)]='filter' [sortable]="sortSettings" [sort]="sort"
            [height]="750" kendoGridSelectBy="id" [selectable]="{ enabled: true, checkboxOnly: true }" 
            (selectionChange)="onSelectedKeysChange($event)"
            [(selectedKeys)]="itemSelection"
            [groupable]="true">
            <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel">
                    Export to Excel
                </button>
            </ng-template>
            <kendo-grid-column [width]="40">
                <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                    {{ rowIndex + 1 }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-checkbox-column [width]="40">
                <ng-template kendoGridHeaderTemplate>
                    <input type="checkbox" kendoCheckBox id="selectAllCheckboxId" kendoGridSelectAllCheckbox [state]="selectAllState"
                        (selectAllChange)="onSelectAllChange($event)" />
                    <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column field="sarId" title="SAR ID" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="sarType" title="SAR Type" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="title" title="Title" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="actionNumber" title="Action Number" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="actionType" title="Action Type" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="supplier" title="Supplier" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="supplierNumber" title="Supplier Number" [width]="90"> </kendo-grid-column>
            <kendo-grid-column field="owner" title="Owner" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="plant" title="Branch/Plant" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="priority" title="Priority" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="lineNumber" title="Line Number" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="itemNumber" title="Item Number" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="itemDescription" title="Item Description" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="lineStatus" title="Line Status" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="uOM" title="UoM" [width]="90" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="createdDate" title="Created" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="lastModified" title="Last Modified" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="modifiedBy" title="Modified By" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="assignedTo" title="Assigned To" [width]="150"> </kendo-grid-column>
            <kendo-grid-column field="qaCode" title="QA Code" [width]="150"> </kendo-grid-column>
            <kendo-grid-excel fileName="AuditData.xlsx" [fetchData]="allData"></kendo-grid-excel>
        </kendo-grid>
    </section>
</div>