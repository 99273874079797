export class QACodeDto {
    id: number = 0;
    name?: string | undefined;
    code?: string | undefined;
    isActive?: boolean | undefined;
    createdBy?: string | undefined;
    createdDateTime?: Date | undefined;
    modifiedBy?: string | undefined;
    modifiedDateTime?: Date | undefined;
    displayName?: string | undefined; 
    operation?: number | undefined;
    status?: boolean = true;
}