import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialGroupCode } from '../../../models/materialgroupcode/materialgroupcode.model';
import { MaterialGroupCodeService } from '../../../services/material-group-code.service';
import { CommonService } from 'src/app/core/services/common.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-manage-material-group-code',
  templateUrl: './manage-material-group-code.component.html',
  styleUrls: ['./manage-material-group-code.component.css']
})
export class ManageMaterialGroupCodeComponent implements OnInit {

  public id: number = 0;
  public isCreate: boolean = false;
  public name: string = '';
  public isActive: boolean = false;
  public headerText: string = "Create New Material Group Code"
  public code: string = ''

  constructor(public router: Router, 
              private route: ActivatedRoute,
              private materialGroupCodeService: MaterialGroupCodeService,
              public commonService: CommonService
              ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.isCreate = stateData['isCreate']
    this.name = stateData['name'];
    this.isActive = stateData['isActive']
    this.code = stateData['code']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.name;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }

  save() {
    const materialGroupCode = new MaterialGroupCode();
    materialGroupCode.id = this.id;
    materialGroupCode.name = this.name;
    materialGroupCode.isActive = this.isActive;
    materialGroupCode.code = this.code;
    materialGroupCode.createdBy = this.commonService.getUserEmailId();
    materialGroupCode.modifiedBy = this.commonService.getUserEmailId();
    if (!this.isCreate) {
      this.materialGroupCodeService.updateMaterialGroupCode(materialGroupCode).subscribe((response: MaterialGroupCode) => {
        this.router.navigate(["materialGroupCode"], { relativeTo: this.route.parent });
      });
    }
    else {
      this.materialGroupCodeService.saveMaterialGroupCode(materialGroupCode).subscribe((response: MaterialGroupCode) => {
        this.router.navigate(["materialGroupCode"], { relativeTo: this.route.parent });
      });
    }
  }

  cancel() {
      this.router.navigate(["materialGroupCode"], { relativeTo: this.route.parent });
  }


}
