<div  class="contentBox">
<h2 class="heading content-top">{{headerText}}</h2>
<section class="w-100">
    <div class="mt-10">
        <kendo-label text="Name"></kendo-label>
        <br>
        <kendo-textbox class="mt-5 w-25" [(ngModel)]="name" [clearButton]="true"></kendo-textbox>
    </div>
    <div class="mt-10">
        <kendo-label text="Code"></kendo-label>
        <br>
        <kendo-textbox class="mt-5 w-25" [(ngModel)]="code" [clearButton]="true"></kendo-textbox>
    </div>
    <div class="mt-10">
        <kendo-label text="Is Active"></kendo-label>
        <br>
        <input class="checkbox" type="checkbox" #isActiveCheck id="isActiveCheck" [checked]="isActive"
            (change)="activeChanged(isActiveCheck.checked)" />
    </div>
    <div class="text-right">
        <kendo-button class="border-button mr-20" (click)="cancel()">Cancel</kendo-button>
        <kendo-button class="primary-button" (click)="save()">Save</kendo-button>
    </div>
</section>
</div>