export class StorageLocationDto {
    id: number | undefined;
    requestLineId?: number
    plantName?: string;
    plantDescription?: string;
    storageLocatinName?: string;
    storageLocationDescription?: string;
    minimum: number | undefined;
    maximum: number | undefined;
    roundingValue: number | undefined;
    estimatedInventoryValue?: number;
    spkId?: number;
    operation?: number | undefined;
    createdBy: string | undefined;
    modifiedBy?: string;
    isPlantEmptyError?: boolean;
    isDuplicateError?: boolean;
    isMinMaxError?: boolean;

}
