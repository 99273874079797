import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requestConst } from 'src/app/core/constants/request.const';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { PriorityDto } from '../models/sar-request/priority.model';
import { RequestDetailsDto } from '../models/sar-request/request-details.model';
import { RequestDto } from '../models/sar-request/request.model';

@Injectable({
  providedIn: 'root'
})
export class RequestService extends HttpAbstractService<RequestDto> {

  constructor(http:HttpClient, public httpUtilityService: HttpUtilityService) { 
    super(http);
  }

  saveRequest(data:RequestDto){
    return this.post(requestConst.apiName+requestConst.createSAR,data);
  }
  updateRequest(data:RequestDto,id:number){
    return this.put(requestConst.apiName+requestConst.updateSAR,id,data);
  }
  GetSARDetailsById(id:number){
    return this.httpUtilityService.get<RequestDetailsDto>(requestConst.apiName+requestConst.GetSARDetailsById+'/'+id);
  }
  
  getPriorityList(){
    return this.httpUtilityService.getAll<PriorityDto[]>(requestConst.apiName+requestConst.getPriorityList);
  }

  getRequestActionsMock(){
    
  }
}
