export const ApprovalDetailsConstants = {
  getTimelines: 'api/TimeLine/GetTimeLines/',
  getItemList: 'api/TimeLine/GetItemList/',
  sarDetails: 'api/Request/GetSARDetailsById/',
  addNewEntry: 'api/TimeLine/AddNewEntry',
  assignedGroup: `api/UserGroups/GetAssignmentGroups/`,
  deleteTimelineAttachment: `api/TimeLine/DeleteAttachment/`,
  downloadAllAttachements: `api/TimeLine/DownLoadAllAttachments`,
  stockApproverAPI: 'api/StockApproverAction/',
  mmdApproverAPI: 'api/MMDAction/',
  approveOrReject: 'ApproveOrRejectRequestLineItem',
  bulkApprove: 'BulkApproveOrRejectRequestLineItem',
  PendingStockingApproval: 'Pending Stocking Approval',
  rejectMessage: 'Are you sure that this item has to be rejected?',
  cancelledMessage: 'Are you sure that this item has to be cancelled?',
  completeMessage: 'Are you sure that this item has to be completed?',
  commentMessage: 'Please enter comment',
  assignedUser: 'api/User/GetAssignedUsers/',
  bulkCancel: 'BulkCancelRejectedRequestLineItems',
  deleteMessage: 'Are you sure that this item has to be deleted?',
  GetUnitPriceDetails:'api/MaterialInfo/GetUnitPriceDetails'
};