export class ApprovalMMDActionDetail {
    comment?: string;
    materialNumber?: string;
    shortDescription?: string;
    enrichedDescription!: string;
    createdBy!: string;
    modifiedBy?: string;
    requestIssueTypeId!: number;
    requestIssueId!: number;
    requestLineId!: number;
    mmdAction!: number;
    requestId!: number;
    isEditLineItem!: boolean;
}

export class ApprovalSAActionDetail {
    createdBy!: string;
    modifiedBy?: string;
    requestIssueTypeId!: number;
    requestIssueId!: number;
    requestLineId!: number;
    stockApproverAction!: number;
    comment: string | undefined;
    assignedGroupId?: number | null;
    assignedUserId?: number | null;
    requestId!: number;
}
