import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { ValuationTypeConst } from 'src/app/core/constants/valuationtype.const';
import { CommonService } from 'src/app/core/services/common.service';
import { ValuationTypeDto } from '../../models/valuationtype/valuationtype.model';
import { ValuationTypeService } from '../../services/valuationtype.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-valuationtype',
  templateUrl: './valuationtype.component.html',
  styleUrls: ['./valuationtype.component.scss']
})
export class ValuationtypeComponent implements OnInit {
  globalGridData: ValuationTypeDto[] = [];
  searchText: string = SharedConst.empty;
  gridData: ValuationTypeDto[] = [];
  isActive = false;

  constructor(private valuationTypeService: ValuationTypeService, public router: Router,
    private route: ActivatedRoute, public commonService: CommonService, private dialogService: DialogService) {
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getValuationTypeList();
  }

  getValuationTypeList() {
    this.valuationTypeService.getValuationTypeAll().subscribe((outputDtata: ValuationTypeDto[]) => {
      this.gridData = outputDtata;
      this.globalGridData = Object.assign([], outputDtata);
      this.search();
    }
    );
  }

  search() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData,
      this.isActive, ValuationTypeConst.searchFieldName, this.searchText)
  }

  clear() {
    this.searchText = SharedConst.empty;
    this.gridData = Object.assign([], this.globalGridData);
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  manage(isCreate: boolean, isActive: boolean, name: string, id: number) {
    this.router.navigate(["../" + ValuationTypeConst.manageVTPath], {
      state: { isCreate: isCreate, id: id, name: name, isActive: isActive },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let vt: ValuationTypeDto[] = arr.map((item: any) => {
          return {
            id: 0,
            name: item['Name'].toString(), isActive: item['Is Active'],
            createdBy: item['Created By'], modifiedBy: item['Modified By'],
            createdDateTime: undefined, modifiedDateTime: undefined
          };
        });
        this.valuationTypeService.importExcelData(vt).subscribe((response: ValuationTypeDto) => {
          this.commonService.showNotificationMessage(ValuationTypeConst.bulkInsertMessage, 'success', 'right')
          this.getValuationTypeList();
        });
      }
    });
  }
}
