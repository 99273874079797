import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BussinesUnitConst } from 'src/app/core/constants/bussinessunit.const';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { BusinessUnitDto } from '../../../models/bussinessunits/bussinessunits.model';
import { BussinessUnitService } from '../../../services/bussinessunit.service';

@Component({
  selector: 'app-manage-bussinessunits',
  templateUrl: './manage-bussinessunits.component.html',
  styleUrls: ['./manage-bussinessunits.component.scss']
})
export class ManageBusinessUnitComponent implements OnInit {
  public id: number = 0;
  public isCreate: boolean = false;
  public BUname: string = '';
  public isActive: boolean = false;
  public headerText: string = BussinesUnitConst.createText;
  public shortName: string = '';
  hasNameError = false;
  hasShortNameError = false;
  hasError = false;

  @ViewChild('buForm') myForm!: NgForm;
  constructor(public router: Router,
    private route: ActivatedRoute,
    private bussinessUnitService: BussinessUnitService, public commonService: CommonService
  ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['buId'];
    this.isCreate = stateData['isCreate']
    this.BUname = stateData['name'];
    this.isActive = stateData['isActive']
    this.shortName = stateData['shortName']
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    if (!this.isCreate) {
      this.headerText = this.BUname;
    }
  }

  activeChanged(value: boolean) {
    this.isActive = value;
  }
  cleareError() {
    this.hasNameError = false;
    this.hasShortNameError = false;
    this.hasError = false;
  }

  save() {
    this.cleareError();
    const bu = new BusinessUnitDto();
    bu.id = this.id;
    bu.name = this.BUname;
    bu.isActive = this.isActive;
    bu.shortName = this.shortName;
    bu.createdBy = this.commonService.getUserEmailId();
    bu.modifiedBy = this.commonService.getUserEmailId();
    if (!this.BUname) {
      this.myForm.form.get('fBuName')?.markAllAsTouched();
      this.hasNameError = true;
      this.hasError = true;
    }
    if (!this.shortName) {
      this.myForm.form.get('fShortName')?.markAllAsTouched();
      this.hasShortNameError = true;
      this.hasError = true;
    }
    if (this.hasError) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'right');
      return false;
    }
    if (!this.isCreate) {
      this.bussinessUnitService.UpdateBU(bu).subscribe((response: BusinessUnitDto) => {
        this.router.navigate([BussinesUnitConst.BUPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(BussinesUnitConst.updateMessage, 'success', 'right');
      });
    }
    else {
      this.bussinessUnitService.saveBU(bu).subscribe((response: BusinessUnitDto) => {
        this.router.navigate([BussinesUnitConst.BUPath], { relativeTo: this.route.parent });
        this.commonService.showNotificationMessage(BussinesUnitConst.insertMessage, 'success', 'right')
      });
    }
  }

  cancel() {
    this.router.navigate([BussinesUnitConst.BUPath], { relativeTo: this.route.parent });
  }

}
