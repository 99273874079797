import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BussinesUnitConst } from 'src/app/core/constants/bussinessunit.const';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { QACodeDto } from '../models/qacode/qacode.model';
import { QACodeConst } from 'src/app/core/constants/qacode.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
    providedIn: 'root',
})
export class QACodeService extends HttpAbstractService<QACodeDto> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getQAAll() {
        return this.getAll(QACodeConst.QAApiName);
    }
    saveQA(data: QACodeDto) {
        return this.post(QACodeConst.QAApiName, data);
    }
    UpdateQA(data: QACodeDto) {
        return this.put(QACodeConst.QAApiName, data.id, data);
    }
    importExcelData(data: QACodeDto[]) {
        return this.postAll(QACodeConst.QAApiName + SharedConst.importApiName, data);
    }
}