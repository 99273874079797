<!-- Create / Update pages -->
<div  class="contentBox">
    <h2 class="heading content-top">{{headerText}}</h2>
    <section class="w-100">        
        <form [formGroup]="stockingLocationForm" (ngSubmit)="save()">
            <div class="mt-10">
                <kendo-label text="Branch"></kendo-label>
                <br>
                <kendo-textbox 
                    class="mt-5 w-25" 
                    id="branch"  
                    formControlName="branch" 
                    [clearButton]="true">
                </kendo-textbox>                
            </div>
            <div class="mt-10">
                <kendo-label text="Branch Description"></kendo-label>
                <br>
                <kendo-textbox 
                    class="mt-5 w-25" 
                    id="branchDescription" 
                    formControlName="branchDescription"                   
                    [clearButton]="true">
                </kendo-textbox>
            </div>
            <div class="mt-10">
                <kendo-label text="Company"></kendo-label>
                <br>
                <kendo-textbox
                    format="##"
                    class="mt-5 w-25" 
                    type="number"                   
                    id="company" 
                    formControlName="company">
                </kendo-textbox>                
            </div>
            <div class="mt-10">
                <kendo-label text="Location"></kendo-label>
                <br>
                <kendo-textbox 
                    class="mt-5 w-25"     
                    id="location" 
                    formControlName="location"             
                    [clearButton]="true">
                </kendo-textbox>
            </div>
            <div class="mt-10">
                <kendo-label text="Asset"></kendo-label>
                <br>
                <kendo-textbox 
                    class="mt-5 w-25"      
                    id="asset" 
                    formControlName="asset" 
                    [clearButton]="true">
                </kendo-textbox>
            </div>
            <div class="mt-10">
                <kendo-label text="Asset Description"></kendo-label>
                <br>
                <kendo-textbox 
                    class="mt-5 w-25"
                    id="assetDescription" 
                    formControlName="assetDescription"
                    [clearButton]="true">
                </kendo-textbox>
            </div>
            <div class="mt-10">
                <kendo-label text="Asset Function"></kendo-label>
                <br>
                <kendo-textbox 
                    class="mt-5 w-25"
                    id="assetFunction" 
                    formControlName="assetFunction"
                    [clearButton]="true">
                </kendo-textbox>
            </div>           

            <div class="mt-10">
                <div class="mt-5 w-25">
                    <kendo-label text="Asset Group"></kendo-label>

                    <kendo-multiselect 
                        name="fRole" 
                        [filterable]="true"
                        formControlName="assetGroup"
                        [data]="assets"
                        textField="name"
                        valueField="id">
                    </kendo-multiselect>
                </div>
            </div>

            <div class="mt-10">
                <kendo-label text="Is PQ"></kendo-label>
                <br>
                <input 
                    class="checkbox" 
                    type="checkbox" 
                    id="isPQ" 
                    formControlName="isPQ" />                
            </div>
            <div class="mt-10">
                <kendo-label text="Is NCR"></kendo-label>
                <br>
                <input 
                    class="checkbox" 
                    type="checkbox" 
                    id="isNCR" 
                    formControlName="isNCR"/>
            </div>
            <div class="mt-10">
                <div class="d-flex flex-row">
                    <div style="width: 100px;">
                        <kendo-label text="Is SAR"></kendo-label>
                        <br>
                        <input 
                            class="checkbox" 
                            type="checkbox"                         
                            id="isSAR"
                            formControlName="isSAR"
                            (change)="onCheckboxChange($event)"/>
                    </div>                
                    <div class="ml-5" *ngIf="isSAR">
                        <kendo-label text="Is Stocking Location"></kendo-label>
                        <br>
                        <input 
                            class="checkbox" 
                            type="checkbox"                        
                            id="isStockingLocation" 
                            formControlName="isStockingLocation"/>
                    </div>
                </div>            
                
            </div>
            
            <div class="text-right">
                <kendo-button 
                    class="border-button mr-20" 
                    (click)="cancel()">Cancel
                </kendo-button>
                <kendo-button 
                    class="primary-button" 
                    type="submit" 
                    (click)="save()">Save
                </kendo-button>
            </div>
        </form>
    </section>
    </div>