import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { RequestIssueDetails } from '../models/sar-request/request-issue/request-issue-details.model';
import { Observable } from 'rxjs';
import { requestIssueConst } from 'src/app/core/constants/request-Issue.const';
import { RequestIssueTypeDto } from '../models/sar-request/request-issue/request-issue-types.model';

@Injectable({
  providedIn: 'root'
})

export class RequestIssueService {

  constructor(private httpUtilityService: HttpUtilityService) {
  }

  getRequestIssueTypes(isChangeSar: boolean): Observable<RequestIssueTypeDto[]> {
    return this.httpUtilityService.getAll<RequestIssueTypeDto[]>(requestIssueConst.apiName + requestIssueConst.getRequestIssueTypes + '/' + isChangeSar);

  }
}
