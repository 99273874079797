
export class MaterialType {
    id: number = 0;
    code: string | undefined;
    name: string | undefined;
    isActive: boolean | undefined;
    createdBy: string | undefined;
    createdDateTime: Date | undefined;
    modifiedBy: string | undefined;
    modifiedDateTime: Date | undefined;
    displayName?: string | undefined;
}

