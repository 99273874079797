
export class SARApplicationGroup {
    id: number = 0;
    name: string | undefined;
    description: string | undefined;
    businessunitId?: number | undefined;
    groupTypeId?: number | undefined;
    displayOrder: number | undefined;
    isActive: boolean | undefined;
    createdBy: string | undefined;
    // createdDateTime: Date | undefined;
    modifiedBy: string | undefined;
    // modifiedDateTime: Date | undefined;
    businessunitName?: string | undefined;
    groupTypeName?: string | undefined;
}
