import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupplierConst } from 'src/app/core/constants/Supplier.const';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { SupplierDto } from '../models/supplier/supplier.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
    providedIn: 'root',
})
export class SupplierService extends HttpAbstractService<SupplierDto> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getAllSupplier() {
        return this.getAll(SupplierConst.SupplierApiName);
    }
    saveSupplier(data: SupplierDto) {
        return this.post(SupplierConst.SupplierApiName, data);
    }
    UpdateSupplier(data: SupplierDto) {
        return this.put(SupplierConst.SupplierApiName, data.id, data);
    }
    importExcelData(data: SupplierDto[]) {
        return this.postAll(SupplierConst.SupplierApiName + SharedConst.importApiName, data);
    }
}