
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BussinesUnitConst } from 'src/app/core/constants/bussinessunit.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { BusinessUnitDto } from '../../models/bussinessunits/bussinessunits.model';
import { BussinessUnitService } from '../../services/bussinessunit.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { RoleConstant } from 'src/app/core/constants/role.constants';


@Component({
  selector: 'app-bussinessunits',
  templateUrl: './bussinessunits.component.html',
  styleUrls: ['./bussinessunits.component.scss']
})
export class BussinessunitsComponent implements OnInit {
  globalGridData: BusinessUnitDto[] = [];
  searchText: string = SharedConst.empty;
  gridData: BusinessUnitDto[] = [];
  isActive = false;
  constructor(private bussinessUnitService: BussinessUnitService, public router: Router,
    private route: ActivatedRoute, public commonService: CommonService,
    private dialogService: DialogService) {
  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getBuList();
  }


  getBuList() {
    this.bussinessUnitService.getBUAll().subscribe((outputDtata: BusinessUnitDto[]) => {
      this.gridData = outputDtata;
      this.globalGridData = Object.assign([], outputDtata);
      this.search();
    }
    );
  }

  search() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData,
      this.isActive, BussinesUnitConst.searchFieldName, this.searchText, BussinesUnitConst.shortname)
  }

  clear() {
    this.searchText = SharedConst.empty;
    this.gridData = Object.assign([], this.globalGridData);
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  manage(isCreate: boolean, isActive: boolean, name: string, shortName: string, buId: number) {
    this.router.navigate(["../" + BussinesUnitConst.manageBUPath], {
      state: { isCreate: isCreate, buId: buId, name: name, shortName: shortName, isActive: isActive },
      relativeTo: this.route
    },
    );
  }
  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let buList: BusinessUnitDto[] = arr.map((item: any) => {
          return {
            id: 0,
            name: item['Name'].toString(), shortName: item['Short Name'].toString(), isActive: item['Is Active'],
            createdBy: item['Created By'], modifiedBy: item['Modified By'],
            createdDateTime: undefined, modifiedDateTime: undefined
          };
        });
        this.bussinessUnitService.importExcelData(buList).subscribe((response: BusinessUnitDto) => {
          this.commonService.showNotificationMessage(BussinesUnitConst.bulkInsertMessage, 'success', 'right')
          this.getBuList();
        });
      }
    });
  }
}

