import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupTypeConstant } from 'src/app/core/constants/groupType.constants';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { GroupType } from '../models/groupType/groupType.model';

@Injectable({
  providedIn: 'root'
})
export class GroupTypeService  extends HttpAbstractService<GroupType> {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
      super(http);
  }
  getGroupType() {
      return this.getAll(GroupTypeConstant.GroupType);
  }
  saveGroupType(data: GroupType) {
      return this.post(GroupTypeConstant.GroupType, data);
  }
  updateGroupType(data: GroupType) {
      return this.put(GroupTypeConstant.GroupType, data.id, data);
  }

}
