import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SARUsersConstant } from 'src/app/core/constants/sar-users.constants';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { GraphResponseType } from 'src/app/core/models/graph.models';
import { CommonService } from 'src/app/core/services/common.service';
import { GraphService } from 'src/app/core/services/graph.service';
import { BusinessUnitDto } from '../../../models/bussinessunits/bussinessunits.model';
import { Group } from '../../../models/group/group.model';
import { Role } from '../../../models/role/role.model';
import { SARUsers } from '../../../models/sarusers/sarusers.model';
import { BussinessUnitService } from '../../../services/bussinessunit.service';
import { GroupService } from '../../../services/group.service';
import { RoleService } from '../../../services/role.service';
import { SarUsersService } from '../../../services/sar-users.service';

@Component({
  selector: 'app-manage-sar-users',
  templateUrl: './manage-sar-users.component.html',
  styleUrls: ['./manage-sar-users.component.scss']
})
export class ManageSarUsersComponent implements OnInit {
  public id: number = 0;
  public isCreate: boolean = false;
  public name: string = '';
  public isActive: boolean = true;
  public cai: string = '';
  public username: string = '';
  public businessUnitname: string;
  public businessUnitsList: BusinessUnitDto[] = [];
  public groupList: Group[] = [];
  public roleList: Role[] = [];
  public headerText: string = "Create New SAR User"
  public businessUnitId: number = 0;
  public provisionId: string = '';
  public emailId: string = '';
  public externalId: string = '';
  public InputCAI: string = '';
  public CAIError: string = SARUsersConstant.caiError;
  public mask: string = 'LLLL';

  hasError: boolean = false;
  hasCAIError: boolean = false;
  hasBusinessUnitError: boolean = false;
  hasRoleError: boolean = false;
  hasGroupError: boolean = false;
  hasUsernameError: boolean = false;
  sarUserForm = new FormGroup({
    cai: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4) ]),
    username: new FormControl('', Validators.required),
    businessUnitname: new FormControl('', Validators.required),
    roleDtos: new FormControl(<Role[] | []>([]), Validators.required),
    groupDtos: new FormControl(<Group[] | []>([]), Validators.required),
    isActive: new FormControl(true),
  });
  selectedBusinessUnit: BusinessUnitDto = new BusinessUnitDto();
  selectedGroups: Group[] = [new Group()];
  groupTypeName: string | undefined;
  roleDtos: Role[] = [new Role];
  groupDtos: Group[] = [new Group];
  roleIds: number[] = [];
  groupIds: number[] = [];

  constructor(public router: Router,
              private route: ActivatedRoute,
              private sarUserService: SarUsersService,
              private bussinessUnitService: BussinessUnitService,
              private roleService: RoleService,
              private groupService: GroupService,
              private graphService: GraphService,
              public commonService: CommonService
            ) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }
    this.id = stateData['id'];
    this.isCreate = stateData['isCreate'];
    this.name = stateData['name'];
    this.cai = stateData['cai'];
    this.username = stateData['username']
    this.isActive = stateData['isActive'];
    this.roleDtos = stateData['roleDtos'];
    this.groupDtos = stateData['groupDtos'];
    this.businessUnitname = stateData['businessUnitname']
    this.businessUnitId = stateData['businessUnitId']
    this.groupTypeName = stateData['groupTypeName']
    this.emailId = stateData['email']
    this.provisionId = stateData['provisionId']
    this.externalId = stateData['externalId']
    this.InputCAI = this.cai;
    if(!this.isCreate) {
      this.headerText = stateData['name']
    }

  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getBusinessUnitList();
    this.getGroupList();
    this.getRoleList();

    if (!this.isCreate) {
      this.groupIds = [];
      this.groupDtos.forEach( el => this.groupIds.push(el.id))
      this.roleIds = [];
      this.roleDtos.forEach( el => this.roleIds.push(el.id))
      this.InputCAI = this.cai;
      this.sarUserForm.setValue({
        cai: this.cai,
        username: this.username,
        businessUnitname: this.businessUnitname,
        roleDtos: this.roleDtos,
        groupDtos: this.groupDtos,
        isActive: this.isActive
      })
      // Call graph api to fetch username if blank
      if (!this.username || this.username == null) {
        this.handleBlur();
      }
    }
  }

  getBusinessUnitList() {
    this.bussinessUnitService.getBUAll().subscribe((outputDtata: BusinessUnitDto[]) => {
      this.businessUnitsList = outputDtata;
    });
  }

  getGroupList() {
    this.groupService.getGroup().subscribe((outputDtata: Group[]) => {
      this.groupList = outputDtata;
    });
  }

  getRoleList() {
    this.roleService.getRole().subscribe((outputDtata: Role[]) => {
      this.roleList = outputDtata;
    });
  }

  clearError() {
    this.hasCAIError = false;
    this.hasBusinessUnitError = false;
    this.hasRoleError = false;
    this.hasGroupError = false;
    this.hasError = false;
  }

  selectBusinessUnit(val: BusinessUnitDto) {
    this.selectedBusinessUnit = val;
    const buName = this.selectedBusinessUnit.name;
    const buId = this.selectedBusinessUnit.id;
    this.businessUnitname = <string>buName;
    this.businessUnitId = <number>buId;
  }

  selectRole(val: Role[]) {
    this.roleDtos = val;
    this.roleIds = [];
    this.roleDtos.forEach( el => this.roleIds.push(el.id))
  }

  selectGroup(val: Group[]) {
    this.groupDtos = val;
    this.groupIds = [];
    this.groupDtos.forEach( el => this.groupIds.push(el.id))
  }

  save() {
    this.clearError();

    const user = new SARUsers();
    user.isActive = <boolean>this.sarUserForm.get('isActive')?.value;
    user.createdBy = 'rrou'
    user.modifiedBy = 'rrou'
    user.name = this.username;
    user.userName = this.username;
    user.id = <number>this.id;
    user.cai = this.sarUserForm.get('cai')?.value?.toString();
    user.businessUnitname = "";
    user.email = this.emailId;
    user.provisionId = this.provisionId;
    user.externalId = this.externalId;
    user.businessUnitId = this.businessUnitId;
    user.groupDtos = [];
    user.roleDtos = [];
    user.roleIds = this.roleIds;
    user.groupIds = this.groupIds;

    
    if(this.isCreate == false) {
      this.sarUserForm.get('cai')?.setErrors(null);
    }

    if (this.sarUserForm.status == 'INVALID') {
       if (this.isCreate == true && this.sarUserForm.get('cai')?.invalid) {
        this.sarUserForm.get('cai')?.markAsTouched();
        this.hasCAIError = true;
        this.hasError = true;
      }
      if (this.sarUserForm.get('businessUnitname')?.invalid) {
        this.sarUserForm.get('businessUnitname')?.markAsTouched();
        this.hasBusinessUnitError = true;
        this.hasError = true;
      }
      if (this.isCreate == false && this.sarUserForm.get('username')?.invalid) {
        this.sarUserForm.get('username')?.markAsTouched();
        this.hasUsernameError = true;
        this.hasError = true;
      }
      if (this.sarUserForm.get('roleDtos')?.invalid) {
        this.sarUserForm.get('roleDtos')?.markAsTouched();
        this.hasRoleError = true;
        this.hasError = true;
      }
      if (this.sarUserForm.get('groupDtos')?.invalid) {
        this.sarUserForm.get('groupDtos')?.markAsTouched()
        this.hasGroupError = true;
        this.hasError = true;
      }
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');

    }
    else {
      if (!this.isCreate) {
        this.sarUserService.updateSARUsers(user).subscribe((response: SARUsers) => {
          this.router.navigate([SARUsersConstant.SARUserRoute], { relativeTo: this.route.parent });
          this.commonService.showNotificationMessage('User has been updated successfully', 'success', 'right');
        });
      }
      else {
        this.sarUserService.saveSARUsers(user).subscribe({
          next: (response: SARUsers )=> {
          this.router.navigate([SARUsersConstant.SARUserRoute], { relativeTo: this.route.parent });
          this.commonService.showNotificationMessage('User has been added successfully', 'success', 'right')
          },
          error: err => {
           this.commonService.showNotificationMessage(err.message, 'error', 'center')
          }})
      }
    }

  }

  cancel() {
    this.router.navigate(['./SARUsers'], { relativeTo: this.route.parent });
  }

  handleBlur(): void {
      const cai = <string>this.sarUserForm.get('cai')?.value;
      if(cai.length == 4) {
        this.graphService.getAdUserNameByCAI(cai).subscribe((response: GraphResponseType) => {
          if (response && response.value && response.value.length === 0) {
            this.setCAIErrors(true, SARUsersConstant.invalidCAIError);
            return;
          }
            this.username = response.value[0].givenName + ' ' +  response.value[0].surname;
            this.provisionId = <string>response.value[0].extension_39c7d3e68666465dab296ee0fc538118_cvx_ProvisioningID;
            this.emailId = <string>response.value[0].userPrincipalName;
            this.externalId = <string>response.value[0].id;
            this.sarUserForm.patchValue({
              username: this.username
            })
          this.sarUserForm.get('cai')?.setErrors(null);
          this.setCAIErrors(false);
        })
    }
    else {
      this.InputCAI = this.InputCAI.toUpperCase();
      this.handleValidation();
    }
  }

  caiValueChange(val: string)  {
    if(val.length == 4 && (this.InputCAI.toUpperCase() === val.toUpperCase())) {
      this.InputCAI = val.toUpperCase();
      this.handleValidation();
    }

  }

  handleValidation() {

    const cai = <string>this.sarUserForm.get('cai')?.value;

    if(this.sarUserForm.get('cai')?.touched && cai.length == 0) {
      this.setCAIErrors(true, SARUsersConstant.caiError);
      return;
    }
    if(this.sarUserForm.get('cai')?.touched && cai.length !== 4) {
      this.setCAIErrors(true, SARUsersConstant.caiLengthError);
      return;
    }
    this.setCAIErrors(false);
    this.sarUserForm.get('cai')?.setErrors(null);
  }

  setCAIErrors(val: boolean, error?: string) {
    this.hasCAIError = val;
    this.hasError = val;
    if(error) {
      this.CAIError = error;
      this.sarUserForm.controls.cai.setErrors({'incorrect': val});
    }
  }

}
