
import { Group } from "../group/group.model";
import { Role } from "../role/role.model";

export class SARUsers {

    isActive: boolean | undefined;
    createdBy: string | undefined;
    modifiedBy: string | undefined;
    id: number = 0;
    name: string | undefined;
    provisionId: string | undefined;
    email: string | undefined;
    cai: string | undefined;
    userName: string | undefined;
    externalId: string | undefined;
    businessUnitId: number | undefined;
    businessUnitname: string | undefined;
    groupIds: Number[] | undefined;
    roleIds:Number[] | undefined;
    roleDtos: Role[] | undefined;
    groupDtos: Group[] | undefined;
    createdDateTime: Date | undefined;
    modifiedDateTime: Date | undefined;
    groupsArr?: string | undefined;
    rolesArr?: string | undefined;

}




