import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpUtilityService } from "src/app/core/services/http.utility.service";
import { Observable } from 'rxjs';
import { AllSarConst } from "src/app/core/constants/all-sar.constant";
import { DataBindingDirective } from "@progress/kendo-angular-grid";

@Injectable({
    providedIn: 'root'
})
export class AllSarService{
    constructor(private http: HttpClient, public httpUtilityService: HttpUtilityService) {
    }

    getAllRequestsData(data : any): Observable<DataBindingDirective> {
        return this.httpUtilityService.post<DataBindingDirective>(AllSarConst.apiName + AllSarConst.getAllRequests, data,);
    }

    // getAllRequests(data: any) {
    //     return this.httpUtilityService.post(AllSarConst.apiName + AllSarConst.getAllRequests, data);
    //   }
}
