<div class="contentBox">
    <h1 class="content-top"><a class="c-left" routerLink="/setup"><span class="fa fa-fw fa-angle-left"></span></a>SAR Group
    </h1>
    <div class="text-align-right mt-20">
        <button kendoButton class="border-button ml-20" (click)="import()">Import</button>
        <button kendoButton class="border-button ml-20" (click)="exportToExcel(grid)"> Export </button>
        <button kendoButton class="border-button ml-20" (click)="manage(true,false,'',0, '', '', '', '', '', '')"> Create New Group
        </button>
    </div>
    <div class="search mt-20">

        <kendo-textbox [style.width.px]="400" (keyup.enter)="updateGrid()" [(ngModel)]="searchText"
            placeholder="Search by group name">
        </kendo-textbox>

        <button kendoButton class="primary-button ml-20" (click)="updateGrid()"> Search </button>
        <button kendoButton class="border-button ml-20" (click)="cancelSearch()"> Reset </button>
    </div>
    <div class="d-flex a-center mt-20">
        <label class="lable" for="">Show Inactive Group</label>
        <label class="switch ml-20">
            <input type="checkbox" #showInactiveCheck id="showInactiveCheck" [checked]="showInactive"
                (change)="activeChanged(showInactiveCheck.checked)">
            <span class="slider round"></span>
        </label>
    </div>

    <kendo-grid class="mt-20" [kendoGridBinding]="gridData"  #grid="kendoGrid" [pageSize]="10" [resizable]="true" [pageable]="true">
         <kendo-grid-excel [fetchData]="commonService.exportGridData(this.gridData, '', 'asc')"  fileName="SARGroup.xlsx">
            <kendo-excelexport-column field="name" title="Group Name">
            </kendo-excelexport-column>
            <kendo-excelexport-column field="groupTypeName" title="Group Type">
            </kendo-excelexport-column>
            <kendo-excelexport-column field="businessunitName" title="Business Unit">
            </kendo-excelexport-column>
            <kendo-excelexport-column field="displayOrder" title="Display Order">
            </kendo-excelexport-column>
            <kendo-excelexport-column field="isActive" title="Is Active">
            </kendo-excelexport-column>
            <kendo-excelexport-column field="createdBy" title="Created By">
            </kendo-excelexport-column>
            <kendo-excelexport-column field="modifiedBy" title="Modified By">
            </kendo-excelexport-column>
        </kendo-grid-excel>
        <kendo-grid-column field="name" title="Group Name" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="javascriipt:void();"
                    (click)="manage(false,dataItem.isActive,dataItem.name,dataItem.id, dataItem.description, dataItem.businessunitId, dataItem.groupTypeId,dataItem.businessunitName, dataItem.groupTypeName, dataItem.displayOrder )">{{dataItem.name}}
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="groupTypeName" title="Group Type" [width]="40"></kendo-grid-column>
        <kendo-grid-column field="businessunitName" title="Business Unit" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="displayOrder" title="Display Order" [width]="40"></kendo-grid-column>
        <kendo-grid-column field="isActive" title="Is Active" [width]="40">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.isActive">
                    <span class="yesValue"> Yes</span>
                </span>
                <span *ngIf=" !dataItem.isActive">
                    <span class="noValue"> No</span>
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>