import { ItemStockingLocationDto } from "src/app/features/setup/models/stocking-location.model.ts/stocking-location.model";
import { RequestLineQACode } from "./request-line-qa-code.model";
import { StorageLocationDto } from "./storage-location.model";

export class AddNewItem {
        id!: number | undefined;
        requestId?: number | undefined;
        requestIssueTypeId?: number | undefined;
        requestIssueId?: number | undefined;
        lineNumber!: number | undefined;
        manufacturerPartNumber?: string | undefined;
        manufacturer?: string | undefined;
        description!: string;
        unitOfMeasureId!: number | undefined;
        materialTypeId!: number | undefined;
        materialGroupCodeId!: number | undefined;
        materialStatusId?: number | undefined;
        sizeOrDimensions: string | undefined;
        grossWeight?: number | undefined;
        storageConditionId?: number | undefined;
        isHazardous?: boolean | undefined;
        isCritical?: boolean | undefined;
        isMRp!: boolean | undefined;
        unitPrice!: number | undefined;
        leadTime!: number | undefined;
        supplierPurchasing?: string | undefined;
        supplierPartNumber?: string | undefined;
        requestorNotes?: string | undefined;
        segmentId!: number | undefined;
        supplierPurchasingId?: number | undefined;
        createdBy!: string | undefined;
        modifiedBy!: string | undefined;
        addNewItemActionQACodes?: RequestLineQACode[];
        addNewActionItemStorageLocation?: StorageLocationDto[];
        equipmentCriticality?: string | undefined;
        itemPartRanking?: string | undefined;
        equipmentTags?: string[];
        stockingLocations: ItemStockingLocationDto[];
}

export class AddNewItemDto {
        id!: number | undefined; //1524
        requestId?: number | undefined;
        requestIssueTypeId?: number | undefined;
        requestIssueId?: number | undefined;
        operation?: number | undefined;
        lineNumber!: number | undefined;
        manufacturerPartNumber?: string | undefined;
        manufacturer?: string | undefined;
        description!: string;
        unitOfMeasureId!: number | undefined;
        materialTypeId!: number | undefined;
        materialGroupCodeId!: number | undefined;
        materialStatusId?: number | undefined;
        sizeOrDimensions: string | undefined;
        grossWeight?: number | undefined;
        storageConditionId?: number | undefined;
        isHazardous?: boolean | undefined;
        isCritical?: boolean | undefined;
        isMRp!: boolean | undefined;
        unitPrice!: number | undefined;
        leadTime!: number | undefined;
        supplierPurchasing?: string | undefined;
        supplierPartNumber?: string | undefined;
        requestorNotes?: string | undefined;
        segmentId!: number | undefined;
        supplierPurchasingId?: number | undefined;
        createdBy!: string | undefined;
        modifiedBy!: string | undefined;
        addNewItemActionQACodes?: RequestLineQACode[];
        addNewActionItemStorageLocation?: StorageLocationDto[];
        equipmentCriticality?: string | undefined;
        itemPartRanking?: string | undefined;
        equipmentTags?: string[];
}
