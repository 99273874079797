import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-permissions',
  templateUrl: './invalid-permissions.component.html',
  styleUrls: ['./invalid-permissions.component.scss']
})
export class InvalidPermissionsComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}

}
