<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1> <a class="c-left" (click)="navBack()"> <span class="fa fa-fw fa-angle-left"></span></a>
            <span class="in-active"> Stock Authorization Request
                <span>{{requestNumber}}</span>
            </span>
            <span class="active"><i class="fa fa-fw fa-angle-right"></i>
                <span> Generate Excel</span></span>
        </h1>
        <div>
            <button kendoButton class="border-button ml-5 f-size-150" (click)="navBack()"> Cancel </button>
            <button kendoButton class="border-button ml-5 f-size-210" (click)="downloadExcelTemplate()"> Download Excel Template </button>
        </div>
    </div>
    <section class="w-75 d-flex flex">
        <div class="w-75 mx-5">
            <div><kendo-label text="Material Number"></kendo-label></div>
            <kendo-textarea resizable="vertical" rounded="small" [style.width.px]="500" [rows]="10"
                [(ngModel)]="enteredmaterialNumbers">
            </kendo-textarea>
        </div>
    </section>
    <br />
    <br />
    <div>
        <button kendoButton class="border-button f-size-150" [disabled]="!enteredmaterialNumbers"
            (click)="onValidate()"> Validate </button>
    </div>
    <br />
    <br />
    <section class="w-75 d-flex flex">
        <div class="w-75 mx-5">
            <div>
                <kendo-label text="Valid Material Numbers"></kendo-label>
                <kendo-label style="color:#009dde" class="ml-300" (click)="clearValidMatNumbers()">Clear
                    All</kendo-label>
            </div>
            <kendo-grid #myGrid [data]="validMaterialNumbers" [hideHeader]="true" [style.width.px]="500"
                [style.height.px]="200">
                <kendo-grid-column [width]="450">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        {{dataItem}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column>
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div (click)="removeRow(rowIndex)" class="icons k-text-right k-icon k-i-delete"></div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </section>
</div>