export class ChangeDataGenerateExcelItem {
  materialNumber?: string;
  changeTypeId?: number;
  changeType?: string;
  plantOtherChangeType: string = '';
  oldData?: string;
}
export class BranchPlantDetails {
  branchPlantCode?: string;
  branchPlantCurrentValue?: string;
  status?: boolean;
}